export enum TaskManagementTab {
  ACTIVE = "Active",
  SNOOZED = "Snoozed",
  DELAYED = "Delayed",
}

export enum TaskInfoTab {
  EPISODE = "Episode",
  TASKS = "Tasks",
  ATTACHMENTS = "Attachments",
  ACTIVITY_HISTORY = "Activity",
  CARE_UTILIZATION = "Care Util",
}
