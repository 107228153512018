import CustomDropDown from "components/CustomDropdownV2/custom-dropdown-v2.component";
import Icon from "components/Icon/icon.component";
import UserManagementModal from "components/Modal/UserManagementModal/user-management-modal.component";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import TextField from "components/TextField/text-field.component";
import {
  AccountType,
  AllowPCPStatusType,
  NavigatorType,
  StatusType,
} from "pages/user-management/common/user-management.enum";
import { ISavePhysicianPayload } from "pages/user-management/common/user-management.types";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { batch, useSelector } from "react-redux";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  checkPhoneIsCorrectFormat,
  getNumberFromString,
  getPatientFullName,
  isEmptyString,
  validateEmail,
  validateNumber,
} from "shared/methods/utilityFunctions";
import { getAuth } from "state/feature/auth/auth.slice";
import { getCommonState } from "state/feature/common/common.slice";
import {
  getAssociatedPhysiciansAsync,
  getAssociatedPhysiciansByProviderIdsAsync,
  getClientsAsync,
  getPhysicianDetailsByIdAsync,
  getUsersTypeAsync,
  savePhysicianAsync,
} from "state/feature/user-management/user-management.action";
import {
  getUserManagementState,
  setPhysicians,
  setUserValueChanged,
} from "state/feature/user-management/user-management.slice";
import { useAppDispatch } from "state/store";
import "./physician-form-details.styles.scss";
import {
  BasicDetailsType,
  DropdownType,
  FormFeildsValidationType,
  PhysicianFormDetailsPropsType,
} from "./physician-form-details.types";
import Loading from "shared/assets/images/loader.gif";
import { useHistory } from "react-router";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import {
  EMAIL_ERROR,
  FIRSTNAME_ERROR,
  LASTNAME_ERROR,
  PHONE_ERROR,
} from "pages/user-management/common/user-management.constants";
import { getBackUpNavigatorListAsync } from "state/feature/navigator/navigator.action";
import { INavigator } from "state/types/navigator.type";
import SingleSelectDropdownWithClearOption from "components/single-select-dropdown-with-clear-option/single-select-dropdown-with-clear-option.component";
import PhoneInputWithDash from "components/phone-input-with-dashes/phone-input-with-dashes";

export const PhysicianFormDetails = forwardRef(({ userId }: PhysicianFormDetailsPropsType, ref: any) => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const { user } = useSelector(getAuth);
  const { featureFlags } = useSelector(getCommonState);
  const { clients, physicians, usertypes, currentUserDetails, isLoadingAssociatedPhysicians } =
    useSelector(getUserManagementState);
  const [basicDetails, setBasicDetails] = useState<BasicDetailsType>({
    accountType: AccountType.PHYSICIAN,
    status: StatusType.ACTIVE,
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    createdAt: "",
    updatedAt: "",
    allowPcp: AllowPCPStatusType.NOT_ALLOWED,
  });
  const [formFeildsValidation, setFormFeildsValidation] = useState<FormFeildsValidationType>({
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    client: null,
    associatedPhysicians: null,
  });
  const [physicianFormDetails, setPhysicianFormDetails] = useState<{ client: any; associatedPhysicians: Array<any> }>({
    client: null,
    associatedPhysicians: [],
  });
  const [tnavValue, setTnavValue] = useState<DropdownType>({ key: "", name: "" });
  const [tnavDropdownItems, setTnavDropdownItems] = useState<Array<DropdownType>>([]);
  const [associatedPhysiciansDropdownItems, setAssociatedPhysiciansDropdownItems] = useState<
    Array<{ key: string; name: string; value?: any }>
  >([]);
  const [isInActiveModalOpen, setIsInActiveModalOpen] = useState<boolean>(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState<boolean>(false);
  const history = useHistory();
  const [searchTextForPhysician, setSearchTextForPhysician] = useState<string>("");
  const [searchTextForClient, setSearchTextForClient] = useState<string>("");
  const [isPhoneValueChanged, setIsPhoneValueChanged] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);

  const getLowerCaseFullName = (physician: any) => {
    return [
      physician.firstName?.toLowerCase(),
      physician.middleName?.toLowerCase(),
      physician.lastName?.toLowerCase(),
    ].join("");
  };

  const getFullName = (physician: any) => {
    const { firstName, middleName, lastName } = physician;
    return [firstName, middleName, lastName].join(" ");
  };

  useEffect(() => {
    if (featureFlags.userManagement) {
      appDispatch(getUsersTypeAsync());
    }
  }, [featureFlags.userManagement, appDispatch]);

  const handleStatusToggle = () => {
    if (basicDetails.status === StatusType.ACTIVE) {
      setIsInActiveModalOpen(true);
    } else {
      setBasicDetails({ ...basicDetails, status: StatusType.ACTIVE });
    }
    appDispatch(setUserValueChanged(true));
  };

  const handleAllowPcpToggle = () => {
    const status =
      basicDetails.allowPcp === AllowPCPStatusType.ALLOWED
        ? AllowPCPStatusType.NOT_ALLOWED
        : AllowPCPStatusType.ALLOWED;
    setBasicDetails({
      ...basicDetails,
      allowPcp: status,
    });

    appDispatch(setUserValueChanged(true));
  };

  const fetchTNavList = () => {
    appDispatch(getBackUpNavigatorListAsync({ types: ["TNAV"] })).then((res) => {
      if (res.payload) {
        const tnavDropdown = res.payload.map((tnav: INavigator) => {
          const { firstName, lastName, careManagerId } = tnav;
          const fullName = getPatientFullName({ firstName: firstName, lastName: lastName });
          if (careManagerId === currentUserDetails?.transitionNavigatorId)
            setTnavValue({ key: careManagerId, name: fullName });
          return { key: careManagerId, name: fullName };
        });
        setTnavDropdownItems(tnavDropdown);
      }
    });
  };

  useEffect(() => {
    appDispatch(getClientsAsync());
  }, [appDispatch]);

  useEffect(() => {
    if (userId && !currentUserDetails) {
      setIsDetailsLoading(true);
      appDispatch(getPhysicianDetailsByIdAsync(userId));
    }
  }, [userId, currentUserDetails, appDispatch]);

  useEffect(() => {
    if (currentUserDetails && clients.length > 0) {
      let client: { id: any; longName: any };
      const { providerIds, clientId } = currentUserDetails;

      if (!physicianFormDetails.client) {
        client = clients?.find((el) => el.id === clientId);
      }
      if (currentUserDetails?.isPhysicianExistInTprovider) fetchTNavList();
      if (!physicianFormDetails.associatedPhysicians.length) {
        appDispatch(getAssociatedPhysiciansByProviderIdsAsync(providerIds))
          .unwrap()
          .then((result) => {
            const associatedPhysicians = result.length
              ? result.map((el) => ({
                  key: el.id,
                  name: getFullName(el),
                  value: el,
                }))
              : [];
            setIsDetailsLoading(false);
            if (client) {
              setPhysicianFormDetails({ associatedPhysicians, client: { id: client!.id, name: client!.longName } });
            }
          });
      }
      const { value: number } = checkPhoneIsCorrectFormat(currentUserDetails?.phone ?? "");
      setBasicDetails({
        ...basicDetails,
        ...currentUserDetails,
        phone: !isEmptyString(number) ? number : basicDetails?.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserDetails, appDispatch, clients]);

  useEffect(() => {
    if (physicianFormDetails.client) {
      appDispatch(getAssociatedPhysiciansAsync(physicianFormDetails.client.id));
    }
  }, [physicianFormDetails.client, appDispatch]);

  useEffect(() => {
    if (physicians.length && searchTextForPhysician) {
      const selectedPhysicianIds = physicianFormDetails.associatedPhysicians.map((el) => el.key);
      let filteredResults = physicians;
      if (!!searchTextForPhysician) {
        const searchTextLowerCase = searchTextForPhysician.replace(/\s/g, "").toLowerCase();
        if (selectedPhysicianIds.length) {
          filteredResults = physicians.filter(
            (physician) =>
              getLowerCaseFullName(physician).includes(searchTextLowerCase) &&
              !selectedPhysicianIds.includes(physician.id)
          );
        } else {
          filteredResults = physicians.filter((physician) =>
            getLowerCaseFullName(physician).includes(searchTextLowerCase)
          );
        }
      }
      setAssociatedPhysiciansDropdownItems(
        filteredResults.map((physician) => ({
          key: physician.id,
          name: getFullName(physician),
          value: physician,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [physicians, searchTextForPhysician]);

  const resetValidationForField = (field: string) => {
    setFormFeildsValidation({ ...formFeildsValidation, [field]: null });
  };

  const onClientSelection = (option: any) => {
    resetValidationForField("client");
    setSearchTextForClient("");
    setPhysicianFormDetails({ ...physicianFormDetails, ...{ client: option, associatedPhysicians: [] } });

    batch(() => {
      appDispatch(setPhysicians([]));
      appDispatch(setUserValueChanged(true));
    });
  };

  const onPhysicianSelection = (options: any) => {
    const selectedPhysicianIds = options.map((el: any) => el.key);
    let filteredResults = physicians;
    resetValidationForField("associatedPhysicians");
    if (!!searchTextForPhysician) {
      if (selectedPhysicianIds.length) {
        filteredResults = physicians.filter(
          (physician) =>
            getLowerCaseFullName(physician).includes(searchTextForPhysician) &&
            !selectedPhysicianIds.includes(physician.id)
        );
      } else {
        filteredResults = physicians.filter((physician) =>
          getLowerCaseFullName(physician).includes(searchTextForPhysician)
        );
      }
    }
    setAssociatedPhysiciansDropdownItems(
      filteredResults.map((physician) => ({
        key: physician.id,
        name: getFullName(physician),
        value: physician,
      }))
    );
    setPhysicianFormDetails({ ...physicianFormDetails, associatedPhysicians: options });
    appDispatch(setUserValueChanged(true));
  };

  const handleBasicDetails = (key: string, value: any) => {
    setBasicDetails({ ...basicDetails, [key]: value });
    resetValidationForField(key);
    appDispatch(setUserValueChanged(true));
  };

  const validateFormFields = (key: string, value: any) => {
    const validations = formFeildsValidation;

    if (!key) {
      const { email, firstName, lastName, phone } = basicDetails;
      const { client, associatedPhysicians } = physicianFormDetails;
      if (!email || email.length === 0 || !validateEmail(email)) {
        validations.email = { error: EMAIL_ERROR };
      } else {
        validations.email = null;
      }
      if (!firstName || firstName.length === 0) {
        validations.firstName = { error: FIRSTNAME_ERROR };
      } else {
        validations.firstName = null;
      }
      if (!lastName || lastName.length === 0) {
        validations.lastName = { error: LASTNAME_ERROR };
      } else {
        validations.lastName = null;
      }
      const convertedPhoneVal = getNumberFromString(phone);
      if (
        !convertedPhoneVal ||
        convertedPhoneVal.length === 0 ||
        convertedPhoneVal.length < 10 ||
        !validateNumber(convertedPhoneVal)
      ) {
        validations.phone = { error: PHONE_ERROR };
      } else {
        validations.phone = null;
      }
      if (!client || client.length === 0) {
        validations.client = { error: "Select a client" };
      } else {
        validations.client = null;
      }
      if (!associatedPhysicians || associatedPhysicians.length === 0) {
        validations.associatedPhysicians = { error: "Select associated physician(s)" };
      } else {
        validations.associatedPhysicians = null;
      }

      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "email") {
      if (!value || value.length === 0 || !validateEmail(value)) {
        validations.email = { error: EMAIL_ERROR };
      } else {
        validations.email = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "firstName") {
      if (!value || value.length === 0) {
        validations.firstName = { error: FIRSTNAME_ERROR };
      } else {
        validations.firstName = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "lastName") {
      if (!value || value.length === 0) {
        validations.lastName = { error: LASTNAME_ERROR };
      } else {
        validations.lastName = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "phone") {
      const phoneVal = getNumberFromString(value);
      if (!phoneVal || phoneVal.length === 0 || phoneVal.length < 10 || !validateNumber(phoneVal)) {
        validations.phone = { error: PHONE_ERROR };
      } else {
        validations.phone = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }

    if (key === "client") {
      if (!value || value.length === 0) {
        validations.client = { error: "Select a client" };
      } else {
        validations.client = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }
    if (key === "associatedphysician") {
      if (!value || value.length === 0) {
        validations.associatedPhysicians = { error: "Select associated physician(s)" };
      } else {
        validations.associatedPhysicians = null;
      }
      setFormFeildsValidation({ ...validations });
      return;
    }
  };

  const setTnavDropdownValue = (value: any) => {
    appDispatch(setUserValueChanged(true));
    setTnavValue({ name: value.name, key: value.key ?? "" });
  };

  const clearSelection = () => {
    appDispatch(setUserValueChanged(true));
    setTnavValue({ name: "", key: "" });
  };

  const renderTnavField = () => {
    return (
      <div className="tnav-container form-col">
        <label className="tnav-label">{NavigatorType.TRANSITION_NAVIGATOR}</label>
        <SingleSelectDropdownWithClearOption
          placeholder="Select Navigator"
          searchPlaceholder="Search by Navigator Name"
          onItemSelected={setTnavDropdownValue}
          dropDownMenuItems={tnavDropdownItems}
          value={tnavValue}
          clearSelection={clearSelection}
          dropDownBoxClass="navigator-dropdown-container"
        />
      </div>
    );
  };

  const isPhoneInvalid = useMemo(() => {
    const { isNumberNan } = checkPhoneIsCorrectFormat(currentUserDetails?.phone ?? "");
    return isNumberNan;
  }, [currentUserDetails?.phone]);

  useImperativeHandle(ref, () => ({
    saveData() {
      setIsDirty(true);
      const { email, firstName, middleName, lastName, phone, status, allowPcp } = basicDetails;
      const { client, associatedPhysicians } = physicianFormDetails;
      const hasValues =
        email && firstName && lastName && phone && client && associatedPhysicians && associatedPhysicians.length > 0;
      const hasErrors =
        formFeildsValidation.email ||
        formFeildsValidation.firstName ||
        formFeildsValidation.lastName ||
        formFeildsValidation.phone ||
        formFeildsValidation.client ||
        formFeildsValidation.associatedPhysicians;
      const isValid = hasValues && !hasErrors;
      if (isValid) {
        const providerIds = associatedPhysicians.map((el) => el.value.id);
        const payload: ISavePhysicianPayload = {
          id: userId ?? "",
          firstName: firstName,
          email: email,
          isActive: status === StatusType.ACTIVE,
          middleName: middleName,
          phoneNumber: `+1${getNumberFromString(phone)}`,
          lastName: lastName,
          clientId: client?.id,
          recordCreatedBy: user.id,
          recordUpdatedBy: userId ? user.id : "",
          userTypeId: usertypes?.find((type) => type.description === AccountType.PHYSICIAN)?.id,
          providerIds,
          providerId: providerIds.length ? providerIds[0] : 0,
          transitionNavigatorId: tnavValue.key ? tnavValue.key : null,
          allowPcp: AllowPCPStatusType.ALLOWED === allowPcp,
        };
        appDispatch(savePhysicianAsync(payload))
          .unwrap()
          .then(() => {
            setTimeout(() => {
              history.goBack();
            }, 10);
          })
          .finally(() => {
            setIsDirty(false);
            setIsPhoneValueChanged(false);
          });
      } else {
        validateFormFields("", null);
      }
    },
    isRequestPayloadValid: () => {
      const { email, firstName, lastName, phone } = basicDetails;
      const { client } = physicianFormDetails;
      const hasValues = email && firstName && lastName && phone && client;
      const hasErrors =
        formFeildsValidation.email ||
        formFeildsValidation.firstName ||
        formFeildsValidation.lastName ||
        formFeildsValidation.phone ||
        formFeildsValidation.client;
      return hasValues && !hasErrors;
    },
  }));

  return (
    <>
      {isDetailsLoading ? (
        <div className="empty-state-container loader">
          <img src={Loading} alt="loading" />
        </div>
      ) : (
        <>
          {isInActiveModalOpen && (
            <UserManagementModal
              heading="Change Status"
              submitButtonTitle={"Yes"}
              cancelButtonTitle={"No"}
              content="Are you sure, you want to set this user’s status to ‘Inactive’?"
              isOpen={isInActiveModalOpen}
              handleCancelClick={() => setIsInActiveModalOpen(false)}
              handleSubmitClick={() => {
                setIsInActiveModalOpen(false);
                setBasicDetails({ ...basicDetails, status: StatusType.INACTIVE });
              }}
            />
          )}
          <div className=" task-container-tab physician-form-container">
            <div className="form-row">
              <div className="form-col account-type-disabled">
                <TextField
                  label="Account Type"
                  type="text"
                  value={AccountType.PHYSICIAN}
                  setValue={() => {}}
                  inputClassName="account-type-disabled"
                  disabled={true}
                  showErrorIcon={true}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Email Address"
                  type="email"
                  value={basicDetails.email}
                  setValue={(el, value) => handleBasicDetails("email", value)}
                  onBlur={() => validateFormFields("email", basicDetails.email)}
                  showError={!!formFeildsValidation?.email?.error}
                  errorMessage={formFeildsValidation?.email?.error}
                  maxLength={100}
                  inputClassName="email-input"
                  placeholder="Email Address"
                  disabled={false}
                  showErrorIcon={true}
                />
              </div>
              <div className="form-col">
                <label className="status-switch-label">Active</label>
                <div className="toggleContainer">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={basicDetails.status === StatusType.ACTIVE}
                      onChange={handleStatusToggle}
                    />
                    <span
                      className={`slider round ${basicDetails.status === StatusType.ACTIVE ? "active" : ""}`}
                    ></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="client-input">
                <label className="status-switch-label">Client</label>
                <CustomDropDown
                  placeholder="Select a Client"
                  dropDownMenuItems={clients.map((el) => ({ id: el.id, name: el.longName }))}
                  handleValueChanges={onClientSelection}
                  value={physicianFormDetails.client}
                  onBlur={() => validateFormFields("client", physicianFormDetails.client)}
                  idFieldName="id"
                  dropDownBoxClass="navigator-dropdown"
                  selectionClass="navigator-selection"
                  dropDownContainerClass="navigator-dropdown-container"
                  isError={!!formFeildsValidation.client}
                  searchText={searchTextForClient}
                  handleSearchText={(value: string) => setSearchTextForClient(value)}
                />
                {!!formFeildsValidation.client && (
                  <div className="error-wrap">
                    <Icon icon="error-message-icon" size={15} className="error-message-icon" />
                    {formFeildsValidation.client.error}
                  </div>
                )}
              </div>
              <div className="assoc-phy-input">
                <label className="status-switch-label">Associated Physician</label>
                <TagsSearchDropdown
                  dropDownMenuItems={associatedPhysiciansDropdownItems}
                  value={physicianFormDetails.associatedPhysicians}
                  onBlur={() => validateFormFields("associatedphysician", physicianFormDetails.associatedPhysicians)}
                  handleValueChanges={onPhysicianSelection}
                  dropDownBoxClass={""}
                  selectionClass={""}
                  onSearchTextChange={(searchText) => setSearchTextForPhysician(searchText)}
                  isDisabled={!physicianFormDetails.client}
                  isLoading={isLoadingAssociatedPhysicians}
                  useCaseType={TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN}
                  renderFrom="physician-form-detail"
                />
                {!!formFeildsValidation.associatedPhysicians && (
                  <div className="error-wrap">
                    <Icon icon="error-message-icon" size={15} className="error-message-icon" />
                    {formFeildsValidation.associatedPhysicians.error}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <TextField
                  label="First Name"
                  type="text"
                  value={basicDetails.firstName}
                  setValue={(el, value) => handleBasicDetails("firstName", value)}
                  onBlur={() => validateFormFields("firstName", basicDetails.firstName)}
                  showError={!!formFeildsValidation?.firstName?.error}
                  errorMessage={formFeildsValidation?.firstName?.error}
                  inputClassName="first-name"
                  placeholder="First Name"
                  disabled={false}
                  showErrorIcon={true}
                  maxLength={30}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Middle Name (Optional)"
                  type="text"
                  value={basicDetails.middleName ?? ""}
                  setValue={(el, value) => handleBasicDetails("middleName", value)}
                  inputClassName="middle-name"
                  placeholder="Middle Name"
                  disabled={false}
                  maxLength={30}
                />
              </div>
              <div className="form-col">
                <TextField
                  label="Last Name"
                  type="text"
                  value={basicDetails.lastName}
                  setValue={(el, value) => handleBasicDetails("lastName", value)}
                  onBlur={() => validateFormFields("lastName", basicDetails.lastName)}
                  showError={!!formFeildsValidation?.lastName?.error}
                  errorMessage={formFeildsValidation?.lastName?.error}
                  inputClassName="last-name"
                  placeholder="Last Name"
                  disabled={false}
                  showErrorIcon={true}
                  maxLength={30}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col phone-col">
                <div className="phone-container">
                  <PhoneInputWithDash
                    label="Phone"
                    onBlur={() => validateFormFields("phone", basicDetails.phone)}
                    value={basicDetails.phone}
                    setValue={(el, value) => {
                      setIsPhoneValueChanged(true);
                      handleBasicDetails("phone", value);
                    }}
                    inputClassName="phone-input"
                    className="phone-container-content"
                    showError={!!formFeildsValidation.phone || (!isDirty && isPhoneInvalid)}
                    placeholder={"Phone"}
                    showErrorIcon={true}
                    errorMessage={
                      !isDirty && isPhoneInvalid
                        ? `Invalid number in field: ${currentUserDetails?.phone ?? ""}`
                        : formFeildsValidation.phone?.error
                    }
                    isNumberIncorrect={!isPhoneValueChanged && isPhoneInvalid}
                  />
                </div>
              </div>
              {userId ? (
                <>
                  {basicDetails.createdAt && (
                    <div className="form-col">
                      <TextField
                        label="Created"
                        type="text"
                        value={basicDetails.createdAt}
                        setValue={() => {}}
                        inputClassName="created-disabled"
                        onBlur={() => {}}
                        disabled={true}
                      />
                    </div>
                  )}
                  {basicDetails.updatedAt && (
                    <div className="form-col">
                      <TextField
                        label="Modified"
                        type="text"
                        value={basicDetails.updatedAt}
                        setValue={() => {}}
                        inputClassName="updated-disabled"
                        onBlur={() => {}}
                        disabled={true}
                      />
                    </div>
                  )}
                </>
              ) : null}
              {(!basicDetails.updatedAt || !basicDetails.createdAt) &&
                currentUserDetails?.isPhysicianExistInTprovider &&
                renderTnavField()}
            </div>
            {basicDetails.updatedAt && basicDetails.createdAt && currentUserDetails?.isPhysicianExistInTprovider && (
              <div className="form-row">{renderTnavField()}</div>
            )}
            {currentUserDetails?.isPhysicianExistInTprovider && (
              <section className="pcp-container">
                <div className="pcp-title">PCP Settings</div>
                <div className="form-row">
                  <div className="form-col">
                    <label className="status-switch-label">Allow PCP</label>
                    <div className="toggleContainer">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={basicDetails.allowPcp === AllowPCPStatusType.ALLOWED}
                          onChange={handleAllowPcpToggle}
                        />
                        <span
                          className={`slider round ${
                            basicDetails.allowPcp === AllowPCPStatusType.ALLOWED ? "active" : ""
                          }`}
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </>
      )}
    </>
  );
});
