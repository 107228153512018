import { preferedNumberRadioList } from "shared/constant/commonConstants";
import { DropdownControlType, PatientDemographicsFormType, StringControlType } from "./patient-demographics.props";

export const SavedSuccessfully = "Patient Demographics Updated Successfully.";

export const getPatientDemographicsFormFields = (): PatientDemographicsFormType => {
  return {
    id: 0,
    isActive: false,
    mbi: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    mbiEffectiveDate: { value: null, isValid: true },
    mrn: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    pFirstName: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    pMiddlename: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    pLastName: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    suffixName: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    preferredName: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    externalID: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    gender: { value: { key: -1, name: "" }, isValid: true },
    dateOfBirth: { value: null, isValid: true },
    maritalStatus: { value: { key: -1, name: "" }, isValid: true },
    primaryCarePhysician: { value: { key: -1, name: "" }, isValid: true },
    pcpPractice: { value: { key: -1, name: "" }, isValid: true },
    mobilePhone: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    homePhone: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    preferredPhone: { value: preferedNumberRadioList, isValid: true },
    address: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    city: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    state: { value: { key: "", name: "" }, isValid: true },
    zipCode: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    email: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    emergencyContactName: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    emergencyContactNumber: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    coachName: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    coachRelationship: { value: { key: -1, name: "" }, isValid: true },
    coachEmailId: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    coachNumber: {
      value: "",
      isValid: true,
      isValueChange: false,
    },
    consentCoachContact: { value: false, isValid: true },
  };
};

export const ErrorMessages = {
  SPECIAL_CHAR_NOT_ALLOWED: "Special characters are not allowed",
  ENTER_MBI_SAME_AS_MEDICARE: "Enter MBI exactly as it appears on the Medicare Card",
  MRN: "Length should be less than 50 characters.",
  ENTER_VALID_EMAIL: "Please enter valid email id.",
  FIRST_NAME_REQUIRED: "Patient First Name is required",
  LAST_NAME_REQUIRED: "Patient Last Name is required",
  LENGTH_SHOULD_BE_30: "Length should be less than 30 characters.",
  PLEASE_SELECT_DROPDOWN: "Please select one from dropdown.",
  PLEASE_ENTER_VALID_PHONE: "Please enter a valid phone number.",
  PLEASE_ENTER_VALID_PREFER: "Please select preferred number",
  LENGHT_SHOULD_BE_3_95: "Length should be minimum 3 and maximum 95 characters.",
  LENGHT_SHOULD_BE_3_85: "Length should be minimum 3 and maximum 85 characters.",
  LENGHT_SHOULD_BE_5: "Length should be equal to 5 digits.",
  DOBMUSTBE1900: "DOB year must be 1900 or greater",
  DOBMUSTBE18: "Patient's age must be 18 or greater",
  DOBREQUIRED: "DOB is required",
};

export const ErrorFieldNames = {
  MBI: "MBI",
  EMAIL: "Email",
};
