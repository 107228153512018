import { useAppDispatch } from "state/store";
import { useEffect, useMemo, useRef, useState } from "react";

import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import TextField from "components/TextField/text-field.component";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import "./patient-demographics.style.scss";
import RadioInputGroup from "components/RadioInputGroup/radio-input-group.component";
import { countryList, genderDropdownItems, prefferedPhoneType } from "shared/constant/commonConstants";
import Checkbox from "components/Checkbox/checkbox.component";
import FooterBar from "./components/footer-bar/footer-bar.component";
import { ErrorFieldNames, ErrorMessages, getPatientDemographicsFormFields } from "./constants";
import {
  DropdownItemType,
  PatientDemographicsFormType,
  DropdownItemTypeStringkey,
  ComponentProps,
  StringControlType,
} from "./patient-demographics.props";
import {
  areStringsEqualIgnoreCase,
  checkPhoneIsCorrectFormat,
  getNumberFromString,
  hasSpecialCharacter,
  isDobValid,
  iscontainOnlyNum,
  validateEmail,
} from "shared/methods/utilityFunctions";
import {
  isAddressValid as validateAddress,
  isCityValid as validateCity,
  isContainWhiteSpace,
  isEmptyString,
  isMbiValid as validateMbi,
  isPhoneNumberValid,
  isZipcodeValid as validateZipcode,
  getDateFormat,
} from "shared/methods/taskSpecificMethods";

import { DemoGraphicDetailsType } from "state/types/episodes-slice.type";
import { clearDuplicateErrors } from "state/feature/episodes/episodes.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import PhoneInputWithDash from "components/phone-input-with-dashes/phone-input-with-dashes";

const PatientDemographicsPresentation = (props: ComponentProps) => {
  const { isBtnLoading, demographicDetails, duplicateDataErrors, saveChanges, dropdownData, isLoading } = props;

  const appDispatch = useAppDispatch();
  const prefPhoneRef = useRef("");
  const patientEpisodeStatusRef = useRef<HTMLDivElement | null>(null);
  const isDuplicateErrorExist = Array.isArray(duplicateDataErrors) && duplicateDataErrors.length;
  const {
    coachRelationshipList = [],
    pcpPracticeList = [],
    primaryCarePhysicianList = [],
    maritalStatusList = [],
  } = dropdownData || {};
  const [isDirty, setIsDirty] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [patientDemographicsForm, setPatientDemographicsForm] = useState<PatientDemographicsFormType>(
    getPatientDemographicsFormFields()
  );

  const intializeForm = () => {
    if (demographicDetails) {
      const formData = getPatientDemographicsFormFields();
      formData.id = demographicDetails.id;
      formData.isActive = demographicDetails.isActive;
      formData.mbi.value = demographicDetails.mbi;
      formData.mbiEffectiveDate.value = demographicDetails.mbiEffectiveDate
        ? new Date(demographicDetails.mbiEffectiveDate)
        : null;
      formData.mrn.value = demographicDetails.mrn ?? "";
      formData.pFirstName.value = demographicDetails.firstName ?? "";
      formData.pMiddlename.value = demographicDetails.middleName ?? "";
      formData.pLastName.value = demographicDetails.lastName ?? "";
      formData.suffixName.value = demographicDetails.suffix ?? "";
      formData.preferredName.value = demographicDetails.preferredName ?? "";
      formData.externalID.value = demographicDetails.externalId ?? "";
      formData.dateOfBirth.value = demographicDetails.dob ? new Date(demographicDetails.dob) : null;
      formData.mobilePhone.value = checkPhoneIsCorrectFormat(demographicDetails?.phoneCell ?? "").value;
      formData.homePhone.value = checkPhoneIsCorrectFormat(demographicDetails?.phoneHome ?? "").value;
      formData.address.value = demographicDetails.address ?? "";
      formData.city.value = demographicDetails.city ?? "";
      formData.zipCode.value = demographicDetails.zip ?? "";
      formData.email.value = demographicDetails.email ?? "";
      formData.emergencyContactName.value = demographicDetails.emergencyContactName ?? "";
      formData.emergencyContactNumber.value = checkPhoneIsCorrectFormat(demographicDetails.phoneEmergency ?? "").value;
      formData.coachName.value = demographicDetails.coachName ?? "";
      formData.coachEmailId.value = demographicDetails.coachEmail ?? "";
      formData.coachName.value = demographicDetails.coachName ?? "";
      formData.coachNumber.value = checkPhoneIsCorrectFormat(demographicDetails.coachPhoneCell ?? "").value;
      formData.consentCoachContact.value = demographicDetails.consentToContactCoach;
      formData.preferredPhone.value.forEach((item) => {
        const flag = item.id === demographicDetails.preferredPhone + "";
        if (flag) prefPhoneRef.current = item.text;
        item.checked = flag;
      });

      const gender = genderDropdownItems.find((item) => item.key === demographicDetails.genderId);
      if (gender) {
        formData.gender.value = gender as DropdownItemType;
      }

      const maritalStatus = maritalStatusList.find((item) => item.key === demographicDetails.maritalStatus);
      if (maritalStatus) {
        formData.maritalStatus.value = maritalStatus as DropdownItemType;
      }

      const state = countryList.find(
        (item) => item.key === demographicDetails.state || item.name === demographicDetails.state
      );
      if (state) {
        formData.state.value = state as DropdownItemTypeStringkey;
      }

      const coachRelationship = coachRelationshipList.find(
        (item) => item.key === demographicDetails.coachReleationshipId
      );
      if (coachRelationship) {
        formData.coachRelationship.value = coachRelationship as DropdownItemType;
      }

      const pcpPractice = pcpPracticeList.find((item: DropdownItemType) => item.key === demographicDetails.practiceId);
      if (pcpPractice) {
        formData.pcpPractice.value = pcpPractice as DropdownItemType;
      }

      const primaryCarePhysician = primaryCarePhysicianList.find(
        (item) => item.key === demographicDetails.primaryCarePhysicianId
      );
      if (primaryCarePhysician) {
        formData.primaryCarePhysician.value = primaryCarePhysician;
      }

      setPatientDemographicsForm({ ...formData });
    }
  };

  const isMobilePhoneValid = () => {
    let isValid = true;
    const { mobilePhone } = patientDemographicsForm;
    const isMobilePrefered = prefPhoneRef.current === prefferedPhoneType.MOBILE.text;
    const isPhoneValid = isPhoneNumberValid(mobilePhone.value);

    if (isMobilePrefered && !isPhoneValid) {
      isValid = false;
    } else if (!isEmptyString(mobilePhone.value) && !isPhoneValid) {
      isValid = false;
    }

    setPatientDemographicsForm((prev) => ({
      ...prev,
      mobilePhone: { ...prev.mobilePhone, isValid },
    }));

    return isValid;
  };

  const isHomePhoneValid = () => {
    let isValid = true;
    const { homePhone } = patientDemographicsForm;
    const isHomePrefered = prefPhoneRef.current === prefferedPhoneType.HOME.text;
    const isPhoneValid = isPhoneNumberValid(homePhone.value);

    if (isHomePrefered && !isPhoneValid) {
      isValid = false;
    } else if (!isEmptyString(homePhone.value) && !isPhoneValid) {
      isValid = false;
    }

    setPatientDemographicsForm((prev) => ({
      ...prev,
      homePhone: { ...prev.homePhone, isValid },
    }));

    return isValid;
  };

  const isMbiValid = () => {
    let isValid = true;
    const { mbi } = patientDemographicsForm;

    if (!validateMbi(mbi.value)) {
      isValid = false;
    }

    setPatientDemographicsForm((prev) => ({
      ...prev,
      mbi: { ...prev.mbi, isValid },
    }));

    return isValid;
  };

  const isFirstNameValid = () => {
    let isValid = true;
    const { pFirstName } = patientDemographicsForm;
    if (isEmptyString(pFirstName.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      pFirstName: { ...prev.pFirstName, isValid },
    }));
    return isValid;
  };

  const isLastNameValid = () => {
    let isValid = true;
    const { pLastName } = patientDemographicsForm;
    if (isEmptyString(pLastName.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      pLastName: { ...prev.pLastName, isValid },
    }));
    return isValid;
  };

  const isGenderValid = () => {
    let isValid = true;
    const { gender } = patientDemographicsForm;
    if (isEmptyString(gender.value.name)) {
      isValid = true;
    }

    setPatientDemographicsForm((prev) => ({
      ...prev,
      gender: { ...prev.gender, isValid },
    }));
    return isValid;
  };

  const isDateOfBirthValid = () => {
    let isValid = true;
    const { dateOfBirth } = patientDemographicsForm;
    if (!isDobValid(dateOfBirth.value)) {
      isValid = false;
    }

    setPatientDemographicsForm((prev) => ({
      ...prev,
      dateOfBirth: { ...prev.dateOfBirth, isValid },
    }));

    return isValid;
  };

  const isPreferredPhoneValid = () => {
    let isValid = true;
    const { preferredPhone } = patientDemographicsForm;
    if (!preferredPhone.value.some((item) => item.checked)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      preferredPhone: { ...prev.preferredPhone, isValid },
    }));
    return isValid;
  };

  const isAddressValid = () => {
    let isValid = true;
    const { address } = patientDemographicsForm;
    if (isEmptyString(address.value) || !validateAddress(address.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      address: { ...prev.address, isValid },
    }));
    return isValid;
  };

  const isCityValid = () => {
    let isValid = true;
    const { city } = patientDemographicsForm;
    if (isEmptyString(city.value) || !validateCity(city.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      city: { ...prev.city, isValid },
    }));
    return isValid;
  };

  const isStateValid = () => {
    let isValid = true;
    const { state } = patientDemographicsForm;
    if (isEmptyString(state.value.name)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      state: { ...prev.state, isValid },
    }));
    return isValid;
  };

  const isZipcodeValid = () => {
    let isValid = true;
    const { zipCode } = patientDemographicsForm;
    if (isEmptyString(zipCode.value) || !validateZipcode(zipCode.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      zipCode: { ...prev.zipCode, isValid },
    }));
    return isValid;
  };

  const isEmergencyContactNumberValid = () => {
    let isValid = true;
    const { emergencyContactNumber } = patientDemographicsForm;
    if (!isEmptyString(emergencyContactNumber.value) && !isPhoneNumberValid(emergencyContactNumber.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      emergencyContactNumber: { ...prev.emergencyContactNumber, isValid },
    }));
    return isValid;
  };

  const isEmailValid = () => {
    let isValid = true;
    const { email } = patientDemographicsForm;
    if (isEmptyString(email.value) || !validateEmail(email.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      email: { ...prev.email, isValid },
    }));
    return isValid;
  };

  const isCoachNumberValid = () => {
    let isValid = true;
    const { coachNumber } = patientDemographicsForm;
    if (!isEmptyString(coachNumber.value) && !isPhoneNumberValid(coachNumber.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      coachNumber: { ...prev.coachNumber, isValid },
    }));
    return isValid;
  };

  const isCoachEmailIdValid = () => {
    let isValid = true;
    const { coachEmailId } = patientDemographicsForm;
    if (!isEmptyString(coachEmailId.value) && !validateEmail(coachEmailId.value)) {
      isValid = false;
    }
    setPatientDemographicsForm((prev) => ({
      ...prev,
      coachEmailId: { ...prev.coachEmailId, isValid },
    }));
    return isValid;
  };

  const validateForm = () => {
    const flags = [
      isMobilePhoneValid(),
      isHomePhoneValid(),
      isMbiValid(),
      isFirstNameValid(),
      isLastNameValid(),
      isGenderValid(),
      isDateOfBirthValid(),
      isPreferredPhoneValid(),
      isAddressValid(),
      isCityValid(),
      isStateValid(),
      isZipcodeValid(),
      isEmergencyContactNumberValid(),
      isEmailValid(),
      isCoachNumberValid(),
      isCoachEmailIdValid(),
    ];

    return flags.reduce((acc, el) => acc && el, true);
  };

  const prepareRequestPayload = () => {
    const {
      mbiEffectiveDate,
      dateOfBirth,
      preferredPhone,
      mobilePhone,
      homePhone,
      emergencyContactNumber,
      coachNumber,
    } = patientDemographicsForm;
    const payload: DemoGraphicDetailsType = {
      id: patientDemographicsForm.id!,
      isActive: patientDemographicsForm.isActive,
      mbi: patientDemographicsForm.mbi.value,
      mbiEffectiveDate: mbiEffectiveDate.value ? getDateFormat(mbiEffectiveDate.value!.toString()) : "",
      mrn: patientDemographicsForm.mrn.value,
      firstName: patientDemographicsForm.pFirstName.value,
      middleName: patientDemographicsForm.pMiddlename.value,
      lastName: patientDemographicsForm.pLastName.value,
      suffix: patientDemographicsForm.suffixName.value,
      preferredName: patientDemographicsForm.preferredName.value,
      externalId: patientDemographicsForm.externalID.value,
      genderId: patientDemographicsForm.gender.value.key,
      dob: dateOfBirth.value ? getDateFormat(dateOfBirth.value!.toString()) : "",
      maritalStatus: patientDemographicsForm.maritalStatus.value.key,
      primaryCarePhysicianId: patientDemographicsForm.primaryCarePhysician.value.key,
      practiceId: patientDemographicsForm.pcpPractice.value.key,
      phoneCell: !isEmptyString(mobilePhone.value) ? `+1${getNumberFromString(mobilePhone.value)}` : "",
      phoneHome: !isEmptyString(homePhone.value) ? `+1${getNumberFromString(homePhone.value)}` : "",
      preferredPhone: parseInt(preferredPhone.value.find((i) => i.checked)?.id ?? "0", 10),
      address: patientDemographicsForm.address.value,
      city: patientDemographicsForm.city.value,
      state: patientDemographicsForm.state.value.key,
      zip: patientDemographicsForm.zipCode.value,
      email: patientDemographicsForm.email.value,
      emergencyContactName: patientDemographicsForm.emergencyContactName.value,
      phoneEmergency: !isEmptyString(emergencyContactNumber.value)
        ? `+1${getNumberFromString(emergencyContactNumber.value)}`
        : "",
      coachName: patientDemographicsForm.coachName.value,
      coachReleationshipId: patientDemographicsForm.coachRelationship.value.key,
      coachEmail: patientDemographicsForm.coachEmailId.value,
      coachPhoneCell: !isEmptyString(coachNumber.value) ? `+1${getNumberFromString(coachNumber.value)}` : "",
      consentToContactCoach: patientDemographicsForm.consentCoachContact.value,
    };

    return payload;
  };

  const submitForm = () => {
    setIsDirty(true);
    if (validateForm()) {
      const payload = prepareRequestPayload();
      setIsDirty(false);
      saveChanges(payload);
    }
  };

  const getMBIErrorMessage = () => {
    const { mbi } = patientDemographicsForm;
    if (isDuplicateErrorExist)
      return duplicateDataErrors.find((item) => areStringsEqualIgnoreCase(item.field, ErrorFieldNames.MBI))?.message;
    if (hasSpecialCharacter(mbi.value)) {
      return ErrorMessages.SPECIAL_CHAR_NOT_ALLOWED;
    }
    if (!mbi.isValid) {
      return ErrorMessages.ENTER_MBI_SAME_AS_MEDICARE;
    }
  };

  const getDobErrorMessage = () => {
    const { dateOfBirth } = patientDemographicsForm;
    if (!!dateOfBirth.value) {
      const year = new Date(dateOfBirth.value).getFullYear();
      return year < 1900 ? ErrorMessages.DOBMUSTBE1900 : ErrorMessages.DOBMUSTBE18;
    }
    return ErrorMessages.DOBREQUIRED;
  };

  const getEmailError = () =>
    isDuplicateErrorExist
      ? duplicateDataErrors.find((item) => areStringsEqualIgnoreCase(item.field, ErrorFieldNames.EMAIL))?.message
      : ErrorMessages.ENTER_VALID_EMAIL;

  const setMBIValue = (value: string) => {
    if (duplicateDataErrors?.length) appDispatch(clearDuplicateErrors([]));
    patientDemographicsForm.mbi = { ...patientDemographicsForm.mbi, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setEffectiveMBIDate = (value: Date | null) => {
    patientDemographicsForm.mbiEffectiveDate = {
      ...patientDemographicsForm.mbiEffectiveDate,
      value,
      isValid: true,
    };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };
  const setMRN = (value: string) => {
    patientDemographicsForm.mrn = { ...patientDemographicsForm.mrn, value };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setFirstName = (value: string) => {
    patientDemographicsForm.pFirstName = {
      ...patientDemographicsForm.pFirstName,
      value,
      isValid: true,
    } as StringControlType;
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setMiddleName = (value: string) => {
    patientDemographicsForm.pMiddlename = {
      ...patientDemographicsForm.pMiddlename,
      value,
    } as StringControlType;
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setLastName = (value: string) => {
    patientDemographicsForm.pLastName = {
      ...patientDemographicsForm.pLastName,
      value,
      isValid: true,
    } as StringControlType;
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };
  const setSuffixName = (value: string) => {
    patientDemographicsForm.suffixName = { ...patientDemographicsForm.suffixName, value };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setPreferredName = (value: string) => {
    patientDemographicsForm.preferredName = {
      ...patientDemographicsForm.preferredName,
      value,
    };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };
  const setExternalID = (value: string) => {
    patientDemographicsForm.externalID = {
      ...patientDemographicsForm.externalID,
      value,
    };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setGenderItem = (value: any) => {
    patientDemographicsForm.gender = { ...patientDemographicsForm.gender, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setDOBDate = (value: Date) => {
    patientDemographicsForm.dateOfBirth = { ...patientDemographicsForm.dateOfBirth, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setMaritalStatus = (value: any) => {
    patientDemographicsForm.maritalStatus = { ...patientDemographicsForm.maritalStatus, value };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setPrimaryCarePhysician = (value: any) => {
    patientDemographicsForm.primaryCarePhysician = {
      ...patientDemographicsForm.primaryCarePhysician,
      value,
      isValid: true,
    };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setPCPPractice = (value: any) => {
    patientDemographicsForm.pcpPractice = { ...patientDemographicsForm.pcpPractice, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setMobilePhone = (value: string) => {
    patientDemographicsForm.mobilePhone = { ...patientDemographicsForm.mobilePhone, value, isValueChange: true };

    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setHomePhone = (value: string) => {
    patientDemographicsForm.homePhone = { ...patientDemographicsForm.homePhone, value, isValueChange: true };

    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setPreferedNumberStatus = (id: string | number) => {
    const { preferredPhone } = patientDemographicsForm;
    const prefPhone = [...preferredPhone.value];
    prefPhone.forEach((item) => {
      const flag = item.id === id;
      if (flag) prefPhoneRef.current = item.text;
      item.checked = flag;
    });
    patientDemographicsForm.preferredPhone = {
      ...patientDemographicsForm.preferredPhone,
      value: prefPhone,
      isValid: true,
    };

    patientDemographicsForm.homePhone = { ...patientDemographicsForm.homePhone, isValid: true };
    patientDemographicsForm.mobilePhone = { ...patientDemographicsForm.mobilePhone, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setAddress = (value: string) => {
    patientDemographicsForm.address = { ...patientDemographicsForm.address, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setCity = (value: string) => {
    patientDemographicsForm.city = { ...patientDemographicsForm.city, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setCountry = (value: any) => {
    patientDemographicsForm.state = { ...patientDemographicsForm.state, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setZipcode = (value: string) => {
    if (!isContainWhiteSpace(value) && iscontainOnlyNum(value)) {
      patientDemographicsForm.zipCode = { ...patientDemographicsForm.zipCode, value, isValid: true };
    } else {
      patientDemographicsForm.zipCode = { ...patientDemographicsForm.zipCode, value: "" };
    }
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setEmail = (value: string) => {
    if (duplicateDataErrors?.length) appDispatch(clearDuplicateErrors([]));
    patientDemographicsForm.email = { ...patientDemographicsForm.email, value, isValid: true };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setEmergencyContactName = (value: string) => {
    patientDemographicsForm.emergencyContactName = { ...patientDemographicsForm.emergencyContactName, value };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setEmergencyContactNumber = (value: string) => {
    patientDemographicsForm.emergencyContactNumber = {
      ...patientDemographicsForm.emergencyContactNumber,
      value,
      isValueChange: true,
    };

    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setCoachName = (value: string) => {
    patientDemographicsForm.coachName = { ...patientDemographicsForm.coachName, value };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setCoachRelationship = (value: any) => {
    patientDemographicsForm.coachRelationship = { ...patientDemographicsForm.coachRelationship, value };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setCoachEmail = (value: string) => {
    patientDemographicsForm.coachEmailId = {
      ...patientDemographicsForm.coachEmailId,
      value,
      isValid: true,
    };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const setCoachNumber = (value: string) => {
    patientDemographicsForm.coachNumber = {
      ...patientDemographicsForm.coachNumber,
      value,
      isValueChange: true,
    };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };
  const setConsentCoachcontact = () => {
    const { consentCoachContact } = patientDemographicsForm;
    const value = !consentCoachContact.value;
    patientDemographicsForm.consentCoachContact = { ...patientDemographicsForm.consentCoachContact, value };
    setPatientDemographicsForm({ ...patientDemographicsForm });
    setIsValueChanged(true);
  };

  const getErrorMessageForIncorrectNumber = (number: string) => `Invalid number in field: ${number}`;

  const isNumberIncorrect = (value: string) => {
    const { isNumberNan } = checkPhoneIsCorrectFormat(value);
    return isNumberNan;
  };

  const isMobileNumberInvalid = useMemo(() => {
    return isNumberIncorrect(demographicDetails?.phoneCell ?? "");
  }, [demographicDetails?.phoneCell]);

  const isHomePhoneInvalid = useMemo(() => {
    return isNumberIncorrect(demographicDetails?.phoneHome ?? "");
  }, [demographicDetails?.phoneHome]);

  const isEmergencyPhoneInvalid = useMemo(() => {
    return isNumberIncorrect(demographicDetails?.phoneEmergency ?? "");
  }, [demographicDetails?.phoneEmergency]);

  const isCoachPhoneInvalid = useMemo(() => {
    return isNumberIncorrect(demographicDetails?.coachPhoneCell ?? "");
  }, [demographicDetails?.coachPhoneCell]);

  useEffect(() => {
    if (isDuplicateErrorExist) {
      for (const item of duplicateDataErrors) {
        if (areStringsEqualIgnoreCase(item.field, ErrorFieldNames.EMAIL)) {
          patientDemographicsForm.email = { ...patientDemographicsForm.email, isValid: false };
        } else if (areStringsEqualIgnoreCase(item.field, ErrorFieldNames.MBI)) {
          patientDemographicsForm.mbi = { ...patientDemographicsForm.mbi, isValid: false };
        }
      }
      setPatientDemographicsForm({ ...patientDemographicsForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateDataErrors]);

  useEffect(() => {
    const isDemographicDetailsExist = !!demographicDetails?.id;

    if (isDemographicDetailsExist) {
      intializeForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographicDetails]);

  return (
    <div id="episode-details-patient-demographic">
      {isLoading ? (
        <CommonState type={CommonStateType.LOADING} className="demographic-loader" />
      ) : (
        <div className="task-container-tab patient-demographic-container">
          <p className="title title-margin">Personal Details</p>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="MBI"
                type="text"
                value={patientDemographicsForm.mbi?.value}
                placeholder="XXXXXXXXX"
                isRequired
                setValue={(_, v) => setMBIValue(v)}
                showError={!patientDemographicsForm.mbi?.isValid}
                errorMessage={getMBIErrorMessage()}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">MBI Effective Date</div>
              <SingleDatePicker
                defaultDate={patientDemographicsForm.mbiEffectiveDate?.value}
                minDate={null}
                maxDate={new Date()}
                isError={!patientDemographicsForm.mbiEffectiveDate.isValid}
                handleDateChange={setEffectiveMBIDate}
                isDisabled={false}
                popperPlacement="bottom"
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Medical Record Number (MRN)"
                type="text"
                value={patientDemographicsForm.mrn.value}
                setValue={(_, v) => setMRN(v)}
                maxLength={50}
                placeholder="XXXXXXXX"
                errorMessage={ErrorMessages.MRN}
                showError={!patientDemographicsForm.mrn.isValid}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Patient First Name"
                type="text"
                value={patientDemographicsForm.pFirstName.value}
                placeholder="First Name"
                maxLength={20}
                isRequired={true}
                setValue={(_, v) => setFirstName(v)}
                showError={!patientDemographicsForm.pFirstName.isValid}
                errorMessage={ErrorMessages.FIRST_NAME_REQUIRED}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Patient Middle Name"
                type="text"
                value={patientDemographicsForm.pMiddlename.value}
                placeholder="Middle Name"
                maxLength={20}
                setValue={(_, v) => setMiddleName(v)}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Patient Last Name"
                type="text"
                value={patientDemographicsForm.pLastName.value}
                setValue={(_, v) => setLastName(v)}
                placeholder="Last Name"
                errorMessage={ErrorMessages.LAST_NAME_REQUIRED}
                showError={!patientDemographicsForm.pLastName.isValid}
                isRequired={true}
                maxLength={30}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Suffix Name"
                type="text"
                value={patientDemographicsForm.suffixName.value}
                placeholder="Suffix Name"
                maxLength={50}
                setValue={(_, v) => setSuffixName(v)}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Preferred Name"
                type="text"
                value={patientDemographicsForm.preferredName.value}
                placeholder="Preferred Name"
                maxLength={30}
                setValue={(_, v) => setPreferredName(v)}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="External ID"
                type="text"
                value={patientDemographicsForm.externalID.value}
                setValue={(_, v) => setExternalID(v)}
                placeholder="External ID"
                errorMessage={ErrorMessages.LENGTH_SHOULD_BE_30}
                showError={false}
                maxLength={30}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <div className="dropdown-container">
                <div className="label">
                  Gender
                  <span className="red-color-asterisk">*</span>
                </div>
                <CustomDropDown
                  placeholder="Male"
                  value={patientDemographicsForm.gender.value}
                  dropDownMenuItems={genderDropdownItems}
                  handleValueChanges={setGenderItem}
                  dropDownContainerClass="dropdown-container"
                  isDisabled={false}
                  isError={!patientDemographicsForm.gender.isValid}
                  message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                  idFieldName="name"
                  activeOptionBasedOnValue={true}
                />
              </div>
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">
                Date of Birth
                <span className="red-color-asterisk">*</span>
              </div>
              <SingleDatePicker
                defaultDate={patientDemographicsForm.dateOfBirth.value}
                minDate={null}
                maxDate={new Date()}
                isError={!patientDemographicsForm.dateOfBirth.isValid}
                errorMessage={getDobErrorMessage()}
                isDisabled={false}
                handleDateChange={setDOBDate}
                popperPlacement="bottom"
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">Marital Status</div>
              <CustomDropDown
                placeholder="Marital Status"
                value={patientDemographicsForm.maritalStatus.value}
                dropDownMenuItems={maritalStatusList}
                handleValueChanges={setMaritalStatus}
                isDisabled={false}
                isError={!patientDemographicsForm.maritalStatus.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container margin-top-row">
            <div className="patient-demographic-wrapper">
              <div className="label">Primary Care Physician</div>
              <CustomDropDown
                placeholder="Primary Care Physician"
                value={patientDemographicsForm.primaryCarePhysician.value}
                dropDownMenuItems={primaryCarePhysicianList}
                handleValueChanges={setPrimaryCarePhysician}
                isDisabled={false}
                isError={!patientDemographicsForm.primaryCarePhysician.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">PCP Practice</div>
              <CustomDropDown
                placeholder="PCP Practice"
                value={patientDemographicsForm.pcpPractice.value}
                dropDownMenuItems={pcpPracticeList}
                handleValueChanges={setPCPPractice}
                isDisabled={false}
                isError={!patientDemographicsForm.pcpPractice.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <p className="title">Contact Details</p>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <PhoneInputWithDash
                label="Mobile Phone"
                value={patientDemographicsForm.mobilePhone.value}
                placeholder="Mobile Phone"
                className="phone-container-content"
                isRequired={prefPhoneRef.current === prefferedPhoneType.MOBILE.text}
                errorMessage={
                  !isDirty && isMobileNumberInvalid
                    ? getErrorMessageForIncorrectNumber(demographicDetails?.phoneCell ?? "")
                    : ErrorMessages.PLEASE_ENTER_VALID_PHONE
                }
                showError={!patientDemographicsForm.mobilePhone.isValid || (!isDirty && isMobileNumberInvalid)}
                setValue={(_, v) => setMobilePhone(v)}
                isNumberIncorrect={!patientDemographicsForm.mobilePhone.isValueChange && isMobileNumberInvalid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <PhoneInputWithDash
                label="Home Phone"
                value={patientDemographicsForm.homePhone.value}
                placeholder="Home Phone"
                setValue={(_, v) => setHomePhone(v)}
                isRequired={prefPhoneRef.current === prefferedPhoneType.HOME.text}
                errorMessage={
                  !isDirty && isHomePhoneInvalid
                    ? getErrorMessageForIncorrectNumber(demographicDetails?.phoneHome ?? "")
                    : ErrorMessages.PLEASE_ENTER_VALID_PHONE
                }
                showError={!patientDemographicsForm.homePhone.isValid || (!isDirty && isHomePhoneInvalid)}
                isNumberIncorrect={!patientDemographicsForm.homePhone.isValueChange && isHomePhoneInvalid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <RadioInputGroup
                labelText="Preferred Calling Number"
                errorMessage={ErrorMessages.PLEASE_ENTER_VALID_PREFER}
                showError={!patientDemographicsForm.preferredPhone.isValid}
                shouldDisplayErroronDisabled
                radioList={patientDemographicsForm.preferredPhone.value}
                handleInputChange={setPreferedNumberStatus}
                className="episode-care"
                required
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Address"
                type="text"
                value={patientDemographicsForm.address.value}
                placeholder="Address"
                maxLength={95}
                isRequired
                setValue={(_, v) => setAddress(v)}
                errorMessage={ErrorMessages.LENGHT_SHOULD_BE_3_95}
                showError={!patientDemographicsForm.address.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="City"
                type="text"
                value={patientDemographicsForm.city.value}
                placeholder="City"
                maxLength={85}
                isRequired
                setValue={(_, v) => setCity(v)}
                errorMessage={ErrorMessages.LENGHT_SHOULD_BE_3_85}
                showError={!patientDemographicsForm.city.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">
                State
                <span className="red-color-asterisk">*</span>
              </div>
              <CustomDropDown
                placeholder="State"
                dropDownMenuItems={countryList}
                handleValueChanges={setCountry}
                value={patientDemographicsForm.state.value}
                isDisabled={false}
                isError={!patientDemographicsForm.state.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Zip Code"
                type="text"
                value={patientDemographicsForm.zipCode.value}
                placeholder="Zip Code"
                maxLength={5}
                isRequired
                setValue={(_, v) => setZipcode(v)}
                errorMessage={ErrorMessages.LENGHT_SHOULD_BE_5}
                showError={!patientDemographicsForm.zipCode.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Email"
                type="text"
                value={patientDemographicsForm.email.value}
                placeholder="Email"
                isRequired
                maxLength={100}
                setValue={(_, v) => setEmail(v)}
                errorMessage={getEmailError()}
                showError={!patientDemographicsForm.email.isValid}
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Emergency Contact Name"
                type="text"
                value={patientDemographicsForm.emergencyContactName.value}
                placeholder="Emergency Contact Name"
                maxLength={30}
                setValue={(_, v) => setEmergencyContactName(v)}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <PhoneInputWithDash
                label="Emergency Contact Number"
                value={patientDemographicsForm.emergencyContactNumber.value}
                placeholder="Emergency Contact Number"
                setValue={(_, v) => setEmergencyContactNumber(v)}
                errorMessage={
                  !isDirty && isEmergencyPhoneInvalid
                    ? getErrorMessageForIncorrectNumber(demographicDetails?.phoneEmergency ?? "")
                    : ErrorMessages.PLEASE_ENTER_VALID_PHONE
                }
                showError={
                  !patientDemographicsForm.emergencyContactNumber.isValid || (!isDirty && isEmergencyPhoneInvalid)
                }
                isNumberIncorrect={
                  isEmergencyPhoneInvalid && !patientDemographicsForm.emergencyContactNumber.isValueChange
                }
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <p className="title coach-details">Coach Details</p>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <TextField
                label="Coach Name"
                type="text"
                value={patientDemographicsForm.coachName.value}
                placeholder="Coach Name"
                maxLength={30}
                setValue={(_, v) => setCoachName(v)}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <div className="label">Coach Relationship</div>
              <CustomDropDown
                placeholder="Coach Relationship"
                value={patientDemographicsForm.coachRelationship.value}
                dropDownMenuItems={coachRelationshipList}
                handleValueChanges={setCoachRelationship}
                isDisabled={false}
                isError={!patientDemographicsForm.coachRelationship.isValid}
                message={ErrorMessages.PLEASE_SELECT_DROPDOWN}
                idFieldName="name"
                activeOptionBasedOnValue={true}
                dropdownRef={patientEpisodeStatusRef}
              />
            </div>
            <div className="patient-demographic-wrapper">
              <TextField
                label="Coach Email"
                type="text"
                value={patientDemographicsForm.coachEmailId.value}
                placeholder="Coach Email"
                maxLength={30}
                setValue={(_, v) => setCoachEmail(v)}
                errorMessage={ErrorMessages.ENTER_VALID_EMAIL}
                showError={!patientDemographicsForm.coachEmailId.isValid}
              />
            </div>
          </div>
          <div className="patient-demographic-row-container">
            <div className="patient-demographic-wrapper">
              <PhoneInputWithDash
                label="Coach Number"
                value={patientDemographicsForm.coachNumber.value}
                placeholder="Coach Number"
                setValue={(_, v) => setCoachNumber(v)}
                errorMessage={
                  !isDirty && isCoachPhoneInvalid
                    ? getErrorMessageForIncorrectNumber(demographicDetails?.coachPhoneCell ?? "")
                    : ErrorMessages.PLEASE_ENTER_VALID_PHONE
                }
                showError={!patientDemographicsForm.coachNumber.isValid || (!isDirty && isCoachPhoneInvalid)}
                isNumberIncorrect={isCoachPhoneInvalid && !patientDemographicsForm.coachNumber.isValueChange}
              />
            </div>
            <div className="patient-demographic-wrapper consent-coach">
              <Checkbox
                id="patient"
                value="Consent to contact coach"
                name="patient"
                isChecked={patientDemographicsForm.consentCoachContact.value}
                onChange={setConsentCoachcontact}
              />
            </div>
            <div className="patient-demographic-wrapper" />
          </div>
          <FooterBar
            isDisabled={!isValueChanged}
            isLoading={isBtnLoading}
            saveBtnLabel="Save Changes"
            onClickSave={submitForm}
          />
        </div>
      )}
    </div>
  );
};

export default PatientDemographicsPresentation;
