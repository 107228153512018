import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useAppDispatch } from "state/store";
import {
  getAllFacilitiesSendOrderAsync,
  getProviderOptionsAync,
  getProviderSendOrderDetailsAsync,
  getSendOrderDetailsAsync,
  saveSendOrderAsync,
} from "state/feature/send-order/send-order.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  getSendOrderSlice,
  resetSendOrderWhenChangingTab,
  setIsDirty,
} from "state/feature/send-order/send-order.slice";

import "./send-order.styles.scss";
import SendOrderPresentation from "./send-order-component";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { checkTaskIsCompletedOrClosed } from "shared/methods/taskSpecificMethods";
import { setIsOpenDelayModal, setIsOpenManagerTaskApprovalModal } from "state/feature/common/common.slice";
import moment from "moment";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { getPatientFullName } from "shared/methods/utilityFunctions";
import { ISaveSendOrderRequestPayload } from "state/types/send-order.type";
import { HandleSubmitDelayTask } from "components/Modal/delay-modal/delay-modal.types";
import {
  getDelayAttempts,
  getTaskDelayStatuses,
  setLockState,
} from "state/feature/task-management/task-management.action";
import {
  expandTaskBoard,
  getBrowserState,
  getCurrentPageTaskState,
  getTaskManagementState,
  removeTab,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
  setLoadTaskList,
  setShouldPerformPostSubmitAction,
  setShowUnsavedChangesModal,
} from "state/feature/task-management/task-management.slice";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { ITab } from "state/types/task-management-slice.type";
import { ACH_ID } from "../check-on-patient/check-on-patient.constant";

const SendOrder = () => {
  useAxiosAuthenticated();
  const appDispatch = useAppDispatch();
  const { currentUserProfile } = useSelector(getNavigatorState);
  const { currentTab, pages, currentPage } = useSelector(getBrowserState);
  const { intakeId, taskId } = currentTab!;
  const { patientDetails, taskPayload, delayTask } = useSelector(getCurrentPageTaskState(taskId));
  const { selectedSendOrder, sendOrderDetails, isValueChanged, providerOptions } = useSelector(getSendOrderSlice);
  const { redirectParams } = useSelector(getTaskManagementState);

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };

  const handleCancelButton = () => {
    if (isPageDirty(currentTab!)) {
      appDispatch(setShowUnsavedChangesModal(true));
    } else {
      appDispatch(setIsDirty(false));
      appDispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
      appDispatch(removeTab(currentTab!.taskId));
    }
  };

  const handleSubmitButton = async (isSaveDraft?: boolean) => {
    if (!isSaveDraft) {
      appDispatch(setIsDirty(true));
    }
    if (
      intakeId &&
      taskId &&
      currentUserProfile &&
      ((selectedSendOrder.providerDetails.id !== undefined &&
        selectedSendOrder.providerDetails.id !== -1 &&
        selectedSendOrder.sendDate !== null) ||
        isSaveDraft)
    ) {
      const provider = providerOptions.find(
        (item) => item.ID.toString() === selectedSendOrder.providerDetails.id?.toString()
      );
      const requestPayload: ISaveSendOrderRequestPayload = {
        intakeId: parseInt(intakeId),
        taskId: taskId,
        providerId: selectedSendOrder.providerDetails.id ?? -1,
        sendDate: selectedSendOrder.sendDate ? moment(selectedSendOrder.sendDate).format("MM/DD/YYYY") : "",
        notes: selectedSendOrder.notes,
        sendMethod: selectedSendOrder.sendMethodType.key,
        sendDestination: selectedSendOrder.sendMethodValue,
        sendDatePreference: selectedSendOrder.preferredSendDate.key,
        sendInstructions: selectedSendOrder.sendInstructions,
        updateUser: getPatientFullName({
          firstName: currentUserProfile?.firstName,
          lastName: currentUserProfile?.lastName,
        }),
        updateUserId: currentUserProfile?.id,
        updateDate: moment().utc().format(),
        isSaveDraft: isSaveDraft ?? false,
        provider: provider?.ProviderName ?? selectedSendOrder.providerName,
        providerType: provider?.ProviderTypeName ?? "",
      };
      const resp = await appDispatch(saveSendOrderAsync(requestPayload)).unwrap();
      if (resp.data && resp.status === 200) {
        appDispatch(setLoadTaskList(true));
        //write code to close the tab
        if (!isSaveDraft) {
          if (redirectParams) {
            setTimeout(() => {
              toast.success(TOAST_MESSAGES.DATA_SAVE_SUCCESSFULLY, { containerId: "main" });
            }, 1000);
          } else {
            appDispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true }));
          }
        }
      }
    }
  };

  const handleOpenDelayButton = () => {
    appDispatch(expandTaskBoard(false));
    appDispatch(setIsOpenDelayModal(true));
  };

  const handleSubmitDelayTask = async (handleDelay: HandleSubmitDelayTask) => {
    await handleSubmitButton(true);
    handleDelay(taskId);
  };

  const handleOpenManagerTaskApprovalButton = () => {
    appDispatch(setIsOpenManagerTaskApprovalModal(true));
  };

  useEffect(() => {
    if (taskId && intakeId && !taskPayload) {
      appDispatch(getTaskDelayStatuses(taskId));
      appDispatch(
        getDelayAttempts({
          intakeId: parseInt(intakeId),
          taskId: taskId,
        })
      );
      appDispatch(getSendOrderDetailsAsync({ taskId, intakeId }));
      appDispatch(getProviderOptionsAync(intakeId));
    }
  }, [appDispatch, taskId, intakeId, taskPayload]);

  useEffect(() => {
    if (!delayTask.delayStatuses.length) {
      appDispatch(getTaskDelayStatuses(currentTab!.taskId));
    }
  }, [appDispatch, currentTab, delayTask.delayStatuses]);

  useEffect(() => {
    appDispatch(setIsCurrentTaskTouched({ taskId, isTouched: true }));
    if (isValueChanged) {
      appDispatch(setIsCurrentTaskDirty({ taskId, isDirty: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueChanged]);

  useEffect(() => {
    if (
      selectedSendOrder.providerDetails.id !== undefined &&
      selectedSendOrder.providerDetails.id !== -1 &&
      sendOrderDetails?.taskStatusId !== TaskStatus.CLOSED &&
      sendOrderDetails?.taskStatusId !== TaskStatus.COMPLETED &&
      !taskPayload
    ) {
      appDispatch(
        getProviderSendOrderDetailsAsync({
          providerId: selectedSendOrder.providerDetails.id,
          isAchTask: sendOrderDetails?.tocTaskDetails.serviceTypeId !== ACH_ID,
        })
      );
    }
  }, [
    selectedSendOrder.providerDetails.id,
    sendOrderDetails?.taskStatusId,
    sendOrderDetails?.tocTaskDetails.serviceTypeId,
    appDispatch,
    taskPayload,
  ]);

  useEffect(() => {
    if (sendOrderDetails?.tocTaskDetails.serviceTypeId === ACH_ID && currentPage?.patientDetails?.clientId) {
      appDispatch(getAllFacilitiesSendOrderAsync(currentPage.patientDetails.clientId));
    }
  }, [appDispatch, currentPage?.patientDetails?.clientId, sendOrderDetails]);

  useEffect(() => {
    return () => {
      appDispatch(resetSendOrderWhenChangingTab());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <SendOrderPresentation
      isTaskCompletedOrClosed={checkTaskIsCompletedOrClosed(sendOrderDetails?.taskStatusId)}
      handleSubmitDelayTask={handleSubmitDelayTask}
      handleCancelButton={handleCancelButton}
      handleSubmitButton={handleSubmitButton}
      handleOpenDelayButton={handleOpenDelayButton}
      handleOpenManagerTaskApprovalButton={handleOpenManagerTaskApprovalButton}
      patientDetails={patientDetails}
    />
  );
};
export default SendOrder;
