import { NoteProps } from "./note.props";
import "./note.styles.scss";

const Note = (props: NoteProps) => {
  const { title, textArray, className } = props;
  return (
    <div id="note-container" className={className ? className : ""}>
      <div className="title">{title}</div>
      {textArray.length === 1 && <div className="description-text">{textArray[0]}</div>}
      {textArray.length > 1 && (
        <ul>
          {textArray.map((text) => (
            <li className="description-text">{text}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Note;
