import React, { useState, useEffect } from "react";
import "./tags-search-dropdown.styles.scss";
import ClickAwayListener from "react-click-away-listener";
import Icon from "components/Icon/icon.component";
import SearchBox from "components/SearchBox/search-box.component";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import { TagsSearchDropdownUseCase } from "./tags-search-dropdown.enum";
import AssignPhoneDropdown from "./CustomDropdowns/assigned-phone-numbers.dropdown";
import HighlightedText from "components/HighLightedText/high-lighted-text.component";
import TableLoader from "components/TableLoader/table-loader.component";

type TagsSearchDropdownProps = {
  placeholder?: string;
  idFieldName?: string;
  handleToastDismiss?: () => void;
  dropDownMenuItems: Array<ITagsSearchDropdownType>;
  handleValueChanges: (value: Array<ITagsSearchDropdownType>) => void;
  value: Array<{ key: string; name: string; value?: any }>;
  itemClassName?: string;
  optionClassName?: string;
  dropDownContainerClass?: string;
  dropDownBoxClass: string;
  selectionClass: string;
  isDisabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  message?: string;
  onBlur?: () => void;
  onSearchTextChange: (value: string) => void;
  dropdownIsOpen?: boolean;
  maximumRange?: number;
  useCaseType: TagsSearchDropdownUseCase;
  isOptionDisabled?: string | boolean;
  renderFrom: string;
};

const TagsSearchDropdown = ({
  handleToastDismiss,
  placeholder,
  dropDownMenuItems,
  handleValueChanges,
  value,
  optionClassName,
  dropDownContainerClass,
  dropDownBoxClass,
  selectionClass,
  isDisabled,
  isError,
  isLoading,
  message,
  onBlur,
  onSearchTextChange,
  dropdownIsOpen,
  maximumRange,
  useCaseType,
  isOptionDisabled,
  renderFrom,
}: TagsSearchDropdownProps) => {
  const [activeOptions, setActiveOptions] = useState<Array<{ key: string; name: string; value?: any }>>([]);
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  useEffect(() => {
    setActiveOptions(value);
  }, [value]);

  const handleDropdownChange = (state: any) => {
    if (handleToastDismiss) handleToastDismiss();
    setShowOptionDropdown((prev) => {
      const arrowEl = document.getElementById("select-box-bucket-down-arrow");
      if (prev) {
        arrowEl?.classList.remove("select-box-down-arrow-rotate");
        arrowEl?.classList.add("select-box-down-arrow-unrotate");
        return !prev;
      } else {
        if (state) {
          arrowEl?.classList.remove("select-box-down-arrow-unrotate");
          arrowEl?.classList.add("select-box-down-arrow-rotate");
          return !prev;
        } else return false;
      }
    });
  };

  const handleSearchInput = (searchValue: string) => {
    if (TagsSearchDropdownUseCase.ASSIGNED_PHONE_NUMBER === useCaseType) {
      setShowOptionDropdown(searchValue.length >= 3);
    } else if (TagsSearchDropdownUseCase.STANDARD_CAREPLAN === useCaseType) {
      setShowOptionDropdown(searchValue.length > 0 && value.length < 5);
    } else {
      setShowOptionDropdown(searchValue.length > 0);
    }
    setSearchText(searchValue);
    onSearchTextChange(searchValue);
  };

  const handleSelection = (option: any) => {
    if (maximumRange !== undefined && activeOptions.length === maximumRange) {
      return;
    }
    if (dropDownMenuItems.length === 1 || TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN === useCaseType) {
      setShowOptionDropdown(false);
      setSearchText("");
      onSearchTextChange("");
    }

    const newSelections = [...activeOptions, option];
    if (newSelections.length === maximumRange) {
      setShowOptionDropdown(false);
    }
    setActiveOptions(newSelections);
    handleValueChanges(newSelections);
  };

  const removeSelection = (optionIdx: any) => {
    const activeOptionsReplica = [...activeOptions];
    activeOptionsReplica.splice(optionIdx, 1);
    setActiveOptions(activeOptionsReplica);
    handleValueChanges(activeOptionsReplica);
  };

  const getTextForTag = (el: any) => {
    if (useCaseType === TagsSearchDropdownUseCase.ASSIGNED_PHONE_NUMBER) {
      return `${el.name} [${el.value}]`;
    }

    if (
      useCaseType === TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN ||
      useCaseType === TagsSearchDropdownUseCase.BACKUP_NAVIGATOR ||
      useCaseType === TagsSearchDropdownUseCase.STANDARD_CAREPLAN ||
      useCaseType === TagsSearchDropdownUseCase.PRIVELEGES_AT
    ) {
      return `${el.name}`;
    }
    return `${el.name}`;
  };

  const getOptionsDropdown = () => {
    if (useCaseType === TagsSearchDropdownUseCase.ASSIGNED_PHONE_NUMBER) {
      return (
        <AssignPhoneDropdown
          dropDownMenuItems={dropDownMenuItems}
          handleSelection={handleSelection}
          optionClassName={optionClassName}
          searchText={searchText}
        />
      );
    }

    if (
      useCaseType === TagsSearchDropdownUseCase.ASSOCIATED_PHYSICIAN ||
      useCaseType === TagsSearchDropdownUseCase.BACKUP_NAVIGATOR ||
      useCaseType === TagsSearchDropdownUseCase.STANDARD_CAREPLAN ||
      useCaseType === TagsSearchDropdownUseCase.PRIVELEGES_AT
    ) {
      return (
        <div className="options-box">
          {!isLoading ? (
            <ul>
              {dropDownMenuItems.length > 0 ? (
                dropDownMenuItems?.map((option: any, key) => (
                  <li key={key} onClick={() => handleSelection(option)}>
                    <p className={`option ${optionClassName ? optionClassName : ""}`}>
                      {HighlightedText(option.name, searchText)}
                    </p>
                  </li>
                ))
              ) : (
                <p className="no-result-found">No Result Found</p>
              )}
            </ul>
          ) : (
            <>
              <TableLoader />
            </>
          )}
        </div>
      );
    }
    if (useCaseType === TagsSearchDropdownUseCase.ALL_PHYSICIANS) {
      const getOptionClassName = (id?: string) => {
        if (
          (typeof isOptionDisabled === "string" && isOptionDisabled !== id) ||
          (typeof isOptionDisabled === "boolean" && isOptionDisabled)
        ) {
          return "flex disabled";
        } else {
          return "flex";
        }
      };

      return (
        <div className="options-box">
          {!isLoading ? (
            <ul>
              {dropDownMenuItems.length > 0 ? (
                dropDownMenuItems?.map((option, key) => {
                  return (
                    <li
                      className={getOptionClassName(option.practiceId?.toString())}
                      key={key}
                      onClick={() => {
                        if (
                          (typeof isOptionDisabled === "string" &&
                            isOptionDisabled === option.practiceId?.toString()) ||
                          (typeof isOptionDisabled === "boolean" && !isOptionDisabled)
                        ) {
                          handleSelection(option);
                        }
                      }}
                    >
                      <p className={`option option-left ${optionClassName ? optionClassName : ""}`}>
                        {HighlightedText(option.name, searchText)}
                      </p>
                      <p className={`option option-right ${optionClassName ? optionClassName : ""}`}>{option.value}</p>
                    </li>
                  );
                })
              ) : (
                <p className="no-result-found">No Result Found</p>
              )}
            </ul>
          ) : (
            <>
              <TableLoader />
            </>
          )}
        </div>
      );
    }

    return [];
  };

  const getSelectedList = (el: { key: string; name: string; value?: any }, index: number) => {
    if (renderFrom === "task-list") {
      if (isOptionDisabled !== el.value) {
        return (
          <span className="icon-content" onClick={() => removeSelection(index)}>
            <Icon icon="cross-in-circle" size={13} className="cross-in-circle-icon" />
          </span>
        );
      } else {
        return null;
      }
    } else {
      return (
        <span className="icon-content" onClick={() => removeSelection(index)}>
          <Icon icon="cross-in-circle" size={13} className="cross-in-circle-icon" />
        </span>
      );
    }
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleDropdownChange(false);
        setSearchText("");
        setShowOptionDropdown(false);
      }}
    >
      <div className={`tag-search-dropdown-container ${dropDownContainerClass ? dropDownContainerClass : ""}`}>
        <span
          onBlur={onBlur}
          tabIndex={isDisabled ? -1 : 0}
          className={`select-span ${isError ? "error" : "no-error"} ${selectionClass ? selectionClass : ""} ${
            isDisabled ? "no-pointer-events" : ""
          }`}
        >
          <div className="name-container">
            {activeOptions.map((el, index) => {
              return (
                <span key={`${el.key}-${index}`} className="selected-option-tag">
                  <span className="text">{getTextForTag(el)}</span>
                  {getSelectedList(el, index)}
                </span>
              );
            })}
            <div className="search-box-wrapper">
              <SearchBox
                icon="cross"
                className="search-box"
                iconClassName="search-icon"
                SearchBoxSize={22}
                onClick={() => {
                  handleSearchInput("");
                }}
                text={searchText}
                onTextChange={(text) => handleSearchInput(text)}
                placeholder={placeholder ? placeholder : "Search"}
                autoFocus={false}
              />
            </div>
          </div>
        </span>
        {isError && !showOptionDropdown && <div className="error-color">{message}</div>}
        {(dropdownIsOpen || showOptionDropdown) && (
          <div className={`option-box-wrapper ${dropDownBoxClass}`}>{getOptionsDropdown()}</div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default TagsSearchDropdown;
