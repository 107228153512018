import Icon from "components/Icon/icon.component";
import { PhoneInputWithDashesProps } from "./props";
import InputMask from "react-input-mask";
import "./style.scss";

const PhoneInputWithDash = (props: PhoneInputWithDashesProps) => {
  const {
    label,
    className,
    value,
    setValue,
    showError,
    disabled,
    errorMessage,
    placeholder,
    inputClassName,
    showErrorIcon = false,
    inputSizeClassName,
    infoMessaage,
    isDisplayInfoMessage,
    id,
    isRequired,
    onBlur,
    isNumberIncorrect,
  } = props;

  return (
    <div id={id} className={`phone-input-with-dash-field-container ${className ? className : ""}`}>
      <label className="field-title">
        {label}
        {isRequired && <span className="red-color-asterisk">*</span>}
      </label>
      <div className={`field-wrap ${inputSizeClassName ? inputSizeClassName : ""}`}>
        <input type="text" className="input-field phone-number" value="+1" disabled />
        <InputMask
          mask={"999-999-9999"}
          maskChar={null}
          maskPlaceholder={"XXX-XXX-XXXX"}
          className={`input-field ${showError || isNumberIncorrect ? "red-border" : "blue-border"} ${
            inputClassName ? inputClassName : ""
          }`}
          value={isNumberIncorrect ? "" : value}
          onChange={(event) => {
            setValue(label, event.currentTarget.value);
          }}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
        {isDisplayInfoMessage && (
          <div className="info-wrap">
            <Icon icon="info" size={15} className="info-icon" />
            {infoMessaage}
          </div>
        )}
      </div>
      {showError && errorMessage && (
        <div className="error-wrap">
          {showErrorIcon && <Icon icon="error-message-icon" size={15} className="error-message-icon" />}
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default PhoneInputWithDash;
