import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { IGetConversationsAsyncRequestPayload } from "../../../pages/dashboard/common/types";
import moment from "moment";
import { axiosInstance } from "shared/axios-instance";
import { ISendMessage } from "shared/dtos/messaging";
import { INavigator } from "state/types/navigator.type";
import { IGetContactsAsyncRequestPayload } from "state/types/contact-slice.type";

export const verifyOtpAsync = createAsyncThunk(
  "messaging/verifyOtpAsync",
  async (requestPayload: { verificationCode: string; phoneNumber: string }) => {
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/verify-otp`,
      requestPayload
    );
    return response.data;
  }
);

export const createConversationAsync = createAsyncThunk(
  "messaging/createConversationAsync",
  async (
    requestPayload: {
      participantsUserId: string[];
      ownerUserId?: string;
      patientId?: number | null;
      type?: string;
      tags?: string;
      subject?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      requestPayload.type = "GENERAL";
      requestPayload.tags = "";
      const response = await axiosInstance.post(
        `${getConfig().messagingBase}/api/v1/Messaging/CreateConversation`,
        requestPayload
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response);
    }
  }
);

export const fetchAllMessagesOfAConversationAsync = createAsyncThunk(
  "messaging/fetchAllMessagesOfAConversationAsync",
  async (requestPayload: { conversationID: string; offset: number; limit?: number }) => {
    if (!requestPayload.limit) {
      requestPayload.limit = 10;
    }
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/GetConversationMessage`,
      requestPayload
    );
    return response;
  }
);

export const sendMessageAsync = createAsyncThunk(
  "messaging/sendMessageAsync",
  async (requestPayload: ISendMessage, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("conversationID", requestPayload.conversationId.toString());
      formData.append("conversationTwilioID", requestPayload.conversationTwilioId.toString());
      formData.append("messageBody", requestPayload.message?.toString() || "");
      formData.append("messageType", requestPayload.messageType.toString());
      formData.append("messageOwnerEmailAddress", requestPayload.messageOwnerEmail.toString());
      formData.append("messageOwnerUserID", requestPayload.messageOwnerUserId.toString());
      formData.append("fileUpload", requestPayload.fileUpload);

      const config = {
        onUploadProgress(progress: any) {
          requestPayload.onUpload(progress);
        },
        signal: requestPayload.abortController?.signal,
        headers: {
          "Content-Type": "multipart/form-data",
          "Ocp-Apim-Subscription-Key": getConfig().subscriptionKey!,
        },
      };

      const response = await axiosInstance.put(
        `${getConfig().messagingBase}/api/v1/Messaging/sendMessage`,
        formData,
        config
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response);
    }
  }
);

export const getAllConversationsAsync = createAsyncThunk(
  "messaging/getAllConversationsAsync",
  async (requestPayload: IGetConversationsAsyncRequestPayload) => {
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/GetConversationList`,
      requestPayload
    );
    if (response.status === 200 && response.data && !response.data.conversationDetails) {
      return [];
    }

    if (response.data && response.data.conversationDetails && response.data.conversationDetails.length > 0) {
      response.data.conversationDetails = response.data.conversationDetails.map((message: any) => {
        message.lastMessageDateTime = `${moment(moment.utc(message.lastMessageDateTime).toDate()).format(
          "MMMM Do, YYYY"
        )} at ${moment(moment.utc(message.lastMessageDateTime).toDate()).format("hh:mm A")}`;
        return message;
      });
    }
    return response.data.conversationDetails;
  }
);

export const getNavigatorContactsAsync = createAsyncThunk(
  "messaging/getNavigatorContactsAsync",
  async (requestPayload: { userId: string }) => {
    const response = await axiosInstance.get(`${getConfig().messagingBase}/api/v1/Messaging/NavigatorContactList`, {
      params: { userId: requestPayload.userId },
    });
    return response.data;
  }
);

export const getConversationIfPresentAsync = createAsyncThunk(
  "messaging/getConversationIfPresentAsync",
  async (requestPayload: {
    ownerUserId?: string;
    participantsUserId: string[];
    patientId?: number | null;
    subject?: string;
    type?: string;
    tags?: string;
  }) => {
    requestPayload.type = "general";
    requestPayload.tags = "";
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/CheckExistingConversation`,
      requestPayload
    );
    return response;
  }
);

export const getConversationMessageCountAsync = createAsyncThunk(
  "messaging/getConversationMessageCountAsync",
  async (requestPayload: { conversationId: number }) => {
    const response = await axiosInstance.get(`${getConfig().messagingBase}/api/v1/Messaging/GetMessageCount`, {
      params: { conversationID: requestPayload.conversationId },
    });
    return response;
  }
);

export const getPhysicianContactAsync = createAsyncThunk(
  "messaging/getNavigatorPhysicianContactAsync",
  async (requestPayload: IGetContactsAsyncRequestPayload, thunkApi) => {
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/NavigatorPhysicianContactList`,
      requestPayload,
      { signal: thunkApi.signal }
    );
    return { data: response.data, offset: requestPayload.pageNumber, userId: requestPayload.id };
  }
);

export const getPatientContactAsync = createAsyncThunk(
  "messaging/getNavigatorPatientContactAsync",
  async (requestPayload: IGetContactsAsyncRequestPayload, thunkApi) => {
    const response = await axiosInstance.post(
      `${getConfig().messagingBase}/api/v1/Messaging/NavigatorPatientContactList`,
      requestPayload,
      { signal: thunkApi.signal }
    );
    return { data: response.data, offset: requestPayload.pageNumber, userId: requestPayload.id };
  }
);

export const getPatientNavigatorListAsync = createAsyncThunk(
  "messaging/getPatientNavigatorListAsync",
  async (requestPayload: { types: string[]; timeZone?: string }, { rejectWithValue }): Promise<INavigator[] | any> => {
    try {
      const response = await axiosInstance.post(`${getConfig().navigatorBase}/api/v1/navigators/list`, requestPayload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPatientDetailsForConversationAsync = createAsyncThunk(
  "messaging/getPatientDetailsForConversationAsync",
  async (requestPayload: { conversationId: number; detailedInfo?: boolean }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().messagingBase}/api/v1/Messaging/patient-detail?conversationId=${
          requestPayload.conversationId
        }&detailedInfo=${!!requestPayload.detailedInfo}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getVacationStatusAsync = createAsyncThunk(
  "messaging/getVacationStatusAsync",
  async ({ conversationId }: { conversationId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().messagingBase}/api/v1/Messaging/vacation-status/${conversationId}`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const resetProxyNav = createAsyncThunk("messaging/resetProxyNav", async ($, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${getConfig().messagingBase}/api/v1/Messaging/reset-proxy-nav`);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
});
