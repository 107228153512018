import Loading from "shared/assets/images/loader.gif";
import "./common-state.styles.scss";
import NoResults from "shared/assets/images/patients-no-search-results.svg";
import { CommonStateMessages, CommonStateType } from "./common-state.enum";
import AccessDeniedImage from "shared/assets/images/access-denied-illustration.svg";
import Button from "components/Button/button.component";
import { TwilioClient } from "shared/services/twilio/twilioClient";
import { clearUser } from "state/feature/auth/auth.slice";
import { clearTwilioToken } from "state/feature/call/call.slice";
import { resetCommon } from "state/feature/common/common.slice";
import { resetContacts } from "state/feature/contact/contact.slice";
import { useDispatch, useSelector } from "react-redux";
import NoSearchedTaskFound from "shared/assets/images/no-searched-task-found.svg";
import NoEpisodesFound from "shared/assets/images/no-episodes-found.svg";
import NoEpisodeTasksFound from "shared/assets/images/no-task-found.svg";
import TaskNotSelected from "pages/task-management/task-not-selected/task-not-selected.component";
import NoTaskFound from "components/no-task-found/no-task-found.component";
import EpisodeTaskComingSoon from "shared/assets/images/episode-task-coming-soon.svg";
import TaskListEmpty from "shared/assets/images/task-list-empty.svg";
import { EpisodesPageCommonMessages } from "shared/enums/episodes-details.enum";
import InvalidTaskImage from "shared/assets/images/invalid-task.svg";
import CommonStateProps from "./common-state.props";
import { getTaskManagementState } from "state/feature/task-management/task-management.slice";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { IntakeStatus } from "shared/enums/plan-of-care.enum";
import NoProviderFound from "shared/assets/images/no-admin-provider-found.svg";
import { logNavigatorAction } from "state/feature/navigator/navigator.action";
import { NavigatorActionsEnum } from "shared/enums/navigator-actions-enum";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";

const CommonState = (props: CommonStateProps) => {
  const {
    type,
    isNewIntakeCreation,
    heading,
    text,
    className,
    handleBackButton,
    handleAddTaskButton,
    handleAddService,
    showAddTaskButton,
  } = props;
  const dispatch = useDispatch();
  const { browser } = useSelector(getTaskManagementState);
  const navState = useSelector(getNavigatorState);
  const { isLoadingNewIntakeCreation } = useSelector(getEpisodesSlice);

  const handleBackToLogin = () => {
    dispatch(
      logNavigatorAction({
        createdBy: navState.currentUserProfile?.id ?? "",
        createdByUser: `${navState.currentUserProfile?.firstName} ${navState.currentUserProfile?.lastName}`,
        type: NavigatorActionsEnum.SESSION_OUT,
      })
    );
    TwilioClient.removeInstance();
    dispatch(clearTwilioToken());
    dispatch(clearUser());
    dispatch(resetCommon());
    dispatch(resetContacts());
    location.replace("/");
    sessionStorage.removeItem("riskAssessmentAnswers");
  };

  if (type === CommonStateType.LOADING) {
    return (
      <div className={`common-state-container loader ${className ? className : ""}`}>
        <img src={Loading} alt="loading" />
      </div>
    );
  } else if (type == CommonStateType.PAGE_NOT_FOUND) {
    return (
      <div className="not-found-container">
        <img src={NoResults} alt="No Patients found" />
        <div className="button-text">Page not available</div>
      </div>
    );
  } else if (type === CommonStateType.INTAKE_NOT_FOUND) {
    return (
      <div className="empty-state-container">
        <img src={NoResults} />
        <div className="button-text">No Intake found</div>
      </div>
    );
  } else if (type === CommonStateType.TASK_NOT_ADDED) {
    return (
      <div className="empty-state-container">
        <img src={NoResults} />
        <div className="button-text">Risk assessment task has not been added yet.</div>
      </div>
    );
  } else if (type === CommonStateType.ACCESS_DENIED) {
    return (
      <div className="access-denied-container">
        <img src={AccessDeniedImage} alt="Access Denied" />
        <div className="button-text">{CommonStateMessages.PERMISSION_DENIED}</div>
        <Button
          text="Back to Login"
          onClick={() => handleBackToLogin()}
          className="forgot-password no-border-button icon-button"
        />
      </div>
    );
  } else if (type === CommonStateType.PERMISSION_DENIED) {
    return (
      <div className="permission-denied-container">
        <img src={AccessDeniedImage} alt="Permission Denied" />
        <div className="button-text">{CommonStateMessages.PERMISSION_DENIED}</div>
      </div>
    );
  } else if (type === CommonStateType.NO_TASK_SELECTED) {
    return <TaskNotSelected />;
  } else if (type === CommonStateType.NO_TASKS_AVAILABLE) {
    return <NoTaskFound />;
  } else if (type === CommonStateType.SEARCH_TASK_NOT_FOUND) {
    return (
      <div className="search-task-not-found-container">
        <div className="search-task-not-found">
          <img src={NoSearchedTaskFound} />
          <div className="search-task-not-found-heading">{EpisodesPageCommonMessages.NO_EPISODE_TASKS_FOUND}</div>
          <div className="search-task-not-found-text">Please refine your search</div>
        </div>
      </div>
    );
  } else if (type === CommonStateType.NO_EPISODES_FOUND) {
    return (
      <div className="no-episodes-found-container">
        <div className="no-episodes-found">
          <img src={NoEpisodesFound} alt="No episodes found" />
          <div className="no-episodes-found-heading">{EpisodesPageCommonMessages.NO_EPISODES_FOUND}</div>
          <div className="no-episodes-found-text">There are no episodes in your records yet</div>
        </div>
      </div>
    );
  } else if (type === CommonStateType.EPISODE_NOT_FOUND_ON_SEARCH) {
    return (
      <div className="search-episodes-not-found-container">
        <div className="search-episodes-not-found">
          <img src={NoSearchedTaskFound} alt="Searched Episodes not found" />
          <div className="search-episodes-not-found-heading">{EpisodesPageCommonMessages.NO_EPISODES_FOUND}</div>
          <div className="search-episodes-not-found-text">Please refine your search and try again</div>
        </div>
      </div>
    );
  } else if (
    type === CommonStateType.NO_EPISODE_DETAILS_FOUND ||
    type === CommonStateType.NO_ACTIVITY_LOGS_FOUND ||
    type === CommonStateType.NO_ACTIVITY_HISTORY_FOUND
  ) {
    return (
      <div className={`no-data-found-container ${className ? className : ""}`}>
        <div className="no-data-found">
          <img src={NoEpisodesFound} alt="Episode details not found" />
          <div className="heading">{heading ? heading : EpisodesPageCommonMessages.NO_EPISODES_FOUND}</div>
          <div className="text">{text ? text : CommonStateMessages.INVALID_LINK}</div>
          {handleBackButton && (
            <div className="button">
              <Button text="Back" onClick={handleBackButton} className="back-button no-border-button icon-button" />
            </div>
          )}
        </div>
      </div>
    );
  } else if (type === CommonStateType.EPISODE_TASK_COMING_SOON) {
    return (
      <div className="common-state-container episode-task-coming-soon">
        <div className="episode-task-coming-soon-content">
          <img src={EpisodeTaskComingSoon} alt="Episode task coming soon" />
          <div className="episode-task-coming-soon-text">
            <div className="episode-task-coming-soon-text-large">{EpisodesPageCommonMessages.COMING_SOON}</div>
            <div className="episode-task-coming-soon-text-small">{EpisodesPageCommonMessages.COMING_SOON}</div>
          </div>
        </div>
      </div>
    );
  } else if (type === CommonStateType.NO_EPISODE_TASKS_FOUND) {
    return (
      <div className="episode-tasks-not-found">
        <div className="episode-tasks-not-found-content">
          <img src={NoEpisodeTasksFound} alt="Episode Task not found" />
          <div className="episode-tasks-not-found-heading">{EpisodesPageCommonMessages.NO_EPISODE_TASKS_FOUND}</div>
          {isNewIntakeCreation && (
            <div className="episode-tasks-not-found-text">Click on ‘Add task’ button to create a new task</div>
          )}
        </div>
      </div>
    );
  } else if (type === CommonStateType.NO_TASK_FOUND) {
    return (
      <div className="task-not-found">
        <img src={InvalidTaskImage} alt="invalid task" />
        <div className="no-task-found">No tasks found</div>
        <div className="error-sub-title">{CommonStateMessages.INVALID_LINK}</div>
      </div>
    );
  } else if (type === CommonStateType.NO_TASK_MANGEMENT_TASK_AVAILABLE) {
    return (
      <div className="loading-container no-task-container">
        <img src={TaskListEmpty} />
        <p className="no-task-message">{EpisodesPageCommonMessages.NO_UPCOMING_TASKS_FOUND}</p>
        {handleAddTaskButton && <Button text="Add Task" className="green-button" onClick={handleAddTaskButton} />}
      </div>
    );
  } else if (type === CommonStateType.NO_PLAN_OF_CARE_LIST_FOUND) {
    return (
      <div className={`plan-of-care-not-found ${className ?? ""}`}>
        <div className="plan-of-care-not-found-content">
          <img src={NoEpisodeTasksFound} alt="Care utilization not found" />
          <div className="plan-of-care-not-found-heading">{EpisodesPageCommonMessages.NO_CARE_UTILIZATION_FOUND}</div>
          {showAddTaskButton &&
            !isLoadingNewIntakeCreation &&
            isNewIntakeCreation &&
            browser.currentPage &&
            !(
              browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CLOSED ||
              browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.CANCELED ||
              browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DECEASED ||
              browser.currentPage.episode?.patientAndEpisodeDetails.intakeStatusId === IntakeStatus.DISCHARGED
            ) && (
              <Button
                text="Add Service"
                className="green-button add-service"
                onClick={handleAddService ? handleAddService : () => {}}
              />
            )}
        </div>
      </div>
    );
  } else if (type === CommonStateType.NO_PROVIDERS_FOUND) {
    return (
      <div className={`no-data-found-container no-admin-providers-provider-found-container`}>
        <div className="no-data-found">
          <img src={NoProviderFound} alt="No Provider not found" />
          <div className="heading">No Provider available</div>
          <div className="text">{"There are no providers in your records yet"}</div>
        </div>
      </div>
    );
  }
  return null;
};

export default CommonState;
