import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../components/Icon/icon.component";
import { NavigatorMessageType } from "./props";
import {
  acknowledgeNotification,
  getNotificationCountAsync,
  getNotificationInternalId,
} from "state/feature/notification/notification.action";

import moment from "moment";
import {
  getMessagingState,
  saveConversationId,
  saveConversationParticipants,
  saveConversationHeaderDetails,
  saveParticipantsDetails,
  saveTwilioConversationId,
  setLastMessageNotificationStatus,
  setOpenMessagingParticipantsPopup,
} from "state/feature/messaging/messaging.slice";
import {
  getCommonState,
  setIsOpenChatModal,
  setIsOpenChatModalForNotification,
} from "state/feature/common/common.slice";
import { Conversation, Participant } from "@twilio/conversations";
import { getAuth } from "state/feature/auth/auth.slice";
import SentIcon from "shared/assets/images/sentIcon.svg";
import ReadIcon from "shared/assets/images/readIcon.svg";
import receivedIcon from "shared/assets/images/received_msg.svg";
import { isMobile } from "react-device-detect";
import { useAppDispatch } from "state/store";
import { USER_ROLE } from "shared/constant/role-constant";
import { isNotificationStale } from "shared/methods/utilityFunctions";
import { OpenFrom } from "shared/enums/chat.enum";
import { setOpenParticipantsPopup } from "state/feature/call/call.slice";
import { AccountType } from "pages/user-management/common/user-management.enum";
import { GENERAL } from "components/ChatSidePanel/components/Compose/compose.constant";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import Tag from "components/Tag/tag.component";
import { toast } from "react-toastify";

const Row = ({
  conversation,
  activeToggleTitle,
  isReplyButtonDisabled,
}: {
  conversation: NavigatorMessageType;
  activeToggleTitle: string;
  searchText?: string;
  isReplyButtonDisabled?: boolean;
}) => {
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const [isRowHovered, setIsRowHovered] = useState(false);
  const authState = useSelector(getAuth);
  const commonState = useSelector(getCommonState);
  const { selectedConversation } = useSelector(getMessagingState);
  const { twilioClientRef } = commonState.globalValues;
  const CONVERSATIONREF = useRef<Conversation>();
  const unsubscribePartcipantUpdate = useRef<Conversation>();
  const [isRead, setisRead] = useState(false);
  const userID = (sessionStorage.getItem("externalId") as string) ?? authState.user.id;

  const conversationParticipants = conversation.participantDetails
    ?.filter((participant: any) => participant.userID !== authState.user.id)
    ?.filter((participant: any) => participant.isActive)
    .map((participant: any) => ` ${participant.fullName}`)
    .toString();

  const openConversation = async () => {
    if (conversation.isHighlight.isProxyToastShow) {
      toast.error(
        "Navigator you were covering for has returned from vacation. You are no longer able to view this conversation.",
        { toastId: "conversation-error", containerId: "main" }
      );
      return;
    }
    dispatch(saveConversationId(conversation.conversationId));
    dispatch(saveTwilioConversationId(conversation.twilioConversationId));
    dispatch(saveConversationParticipants(conversationParticipants));
    dispatch(saveConversationHeaderDetails({ patientDOB: conversation.patientDOB, subject: conversation.subject }));
    dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.EXISTING_CHAT }));

    if (
      !conversation?.isLastNotificationRead &&
      conversation.participantDetails != null &&
      authState.user.id === userID &&
      commonState.featureFlags.inAppNotifications
    ) {
      dispatch(setLastMessageNotificationStatus(conversation.conversationId));
      const res: any = await appDispatch(
        getNotificationInternalId(["NEW_MESSAGE", String(conversation.conversationId)])
      );
      if (res.meta.requestStatus === "fulfilled") {
        const result = await appDispatch(
          acknowledgeNotification({
            internalId: res.payload.data,
            ownerUserId: authState.user.email,
            createdBefore: moment().toISOString(),
          })
        );
        if (result.meta.requestStatus === "fulfilled") {
          dispatch(getNotificationCountAsync({ ownerUserId: authState.user.email }));
        }
      }
    }

    if (conversation?.participantDetails && conversation?.participantDetails.length > 0) {
      dispatch(saveParticipantsDetails(conversation.participantDetails));
    }
  };

  const checkReadStatus = async (conversationBySid: Conversation) => {
    CONVERSATIONREF.current = conversationBySid;
    const count = await conversationBySid.getMessagesCount();
    conversationBySid.getParticipants().then(async function (currentParticipants: Participant[]) {
      const filteredList = currentParticipants.filter((item: Participant) => item.identity !== authState.user.email);
      const maximumLastReadMessageIndex = Math.max(
        ...filteredList.map((item: any) => {
          if (item.lastReadMessageIndex === null) {
            return -1;
          }
          return item.lastReadMessageIndex;
        })
      );

      const messageIsRead = maximumLastReadMessageIndex > -1 && count === maximumLastReadMessageIndex + 1;
      setisRead(messageIsRead);
    });
  };

  const getUnreadMessages = async () => {
    if (conversation.twilioConversationId && twilioClientRef) {
      twilioClientRef
        .getConversationBySid(conversation.twilioConversationId)
        .then(async (conversationBySid: Conversation) => {
          checkReadStatus(conversationBySid);
          unsubscribePartcipantUpdate.current = conversationBySid.on("participantUpdated", async () => {
            checkReadStatus(conversationBySid);
          });
        })
        .catch((err: any) => {
          console.log("configuration error: ", err);
        });
    }
  };

  useEffect(() => {
    getUnreadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, unsubscribePartcipantUpdate]);

  const getMessageStatus = () => {
    if (conversation.lastMessageSenderId !== authState.user.id) {
      return <img className="messageSentIcon" src={receivedIcon} />;
    } else if (isRead) {
      return <img className="messageSentIcon" src={ReadIcon} />;
    } else {
      return <img className="messageSentIcon" src={SentIcon} />;
    }
  };

  const getUnreadMessageRowClass = () => {
    let className = "";

    if (isRowHovered) {
      className = "hovered";
    }

    if (
      conversation.isLastNotificationRead != null &&
      !conversation.isLastNotificationRead &&
      authState.user.id === userID &&
      commonState.featureFlags.inAppNotifications &&
      !isNotificationStale(conversation.conversationId, commonState.modal, selectedConversation.conversationId)
    ) {
      className += " unread-message";
    }
    return className;
  };

  const showParticipantDetails = function (conv: NavigatorMessageType) {
    dispatch(saveConversationId(conv.conversationId));
    dispatch(setOpenMessagingParticipantsPopup(true));
  };

  return !isMobile ? (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      onClick={openConversation}
      className={getUnreadMessageRowClass()}
    >
      {activeToggleTitle === "Patient" && (
        <td>
          <div className="proxy-tag-container">
            <div>{conversationParticipants}</div>
            {conversation.isHighlight.isProxyTagShow && (
              <div className="proxy-tag">
                <Tag text="Proxy Conversation" />
              </div>
            )}
          </div>
        </td>
      )}
      <td>
        <div className="subject-column">
          <div className="subject-text">{conversation.subject}</div>
          {isRowHovered && conversation.subject.toLowerCase() !== GENERAL.toLowerCase() && (
            <div
              className="subject-icon"
              data-tip
              data-for="Patient Details"
              onClick={(e) => {
                e.stopPropagation();
                showParticipantDetails(conversation);
              }}
            >
              <Icon icon="participant-info" size={20} className="participants-info" />
              <CustomToolTip text={"Patient Details"} place="bottom" />
            </div>
          )}
        </div>
      </td>
      <td>{conversation.dateTime ? conversation.dateTime : "-"}</td>
      <td>
        <div className="second-last-column">
          {conversation.message && <div>{getMessageStatus()}</div>}
          {/* {index === 0 && (
            <div>
              <img src={DeliveredIcon} alt="delivered" />
            </div>
          )}
          {index === 1 && (
            <div>
              <img src={DeliveredReadIcon} alt="delivered and read" />
            </div>
          )}
          {index === 2 && (
            <div>
              <Icon icon="read" size={17} />
            </div>
          )}
          {index > 2 && (
            <div>
              <Icon icon="reply-read" size={17} />
            </div>
          )} */}
          <div className="second-last-column-content">{conversation.message}</div>
        </div>
      </td>
      <td>
        {isReplyButtonDisabled && !conversation.isHighlight && (
          <div className={`last-column ${isRowHovered ? "visible" : "hidden"}`}>
            <div
              className={`table-icon ${isReplyButtonDisabled ? "table-icon-disabled" : ""}`}
              onClick={() => {
                dispatch(saveConversationId(conversation.conversationId));
                dispatch(saveTwilioConversationId(conversation.twilioConversationId));
                dispatch(saveConversationParticipants([conversation.participants]));
                dispatch(setIsOpenChatModal({ isOpen: true, openFrom: OpenFrom.EXISTING_CHAT }));
              }}
            >
              <Icon icon="message-reply" size={14} className="chat-icon" />
            </div>
          </div>
        )}
      </td>
    </tr>
  ) : (
    <tr onClick={openConversation} className={getUnreadMessageRowClass()}>
      <td>
        <span className="identity-box">
          <Icon icon="users-group" className="users-group-icon" size={24} />
        </span>
      </td>
      <td>
        <div className="participants-and-time">
          <strong>{activeToggleTitle === "Patient" && <span>{conversation.participants}</span>}</strong>
          <span className="timestamp">{conversation.dateTime ? conversation.dateTime : "-"}</span>
        </div>
        <div className="message-container">
          <div>
            {getMessageStatus()} {conversation.message}
          </div>
        </div>
        <div className="subject-container">
          <div className="subject-text">{conversation.subject}</div>
        </div>
      </td>
    </tr>
  );
};

export default Row;
