export enum EpisodeDetailsTab {
  TASKS = "Tasks",
  PROCEDURE_DETAILS = "Procedure Details",
  PATIENT_DEMOGRAPHICS = "Patient Demographics",
  ACTIVITY_HISTORY = "Activity History",
  CARE_UTILIZATION = "Care Utilization",
  MISC = "Misc",
}

export enum EpisodesPageCommonMessages {
  COMING_SOON = "Coming Soon...",
  NO_EPISODES_FOUND = "Oops! No episodes found",
  NO_EPISODE_TASKS_FOUND = "Oops! No tasks found",
  NO_UPCOMING_TASKS_FOUND = "Oops! No upcoming tasks for this patient",
  NO_ACTIVITY_FOUND = "Oops! No Activity found",
  NO_CARE_UTILIZATION_FOUND = "Oops! Care Utilization doesn’t exist",
}
