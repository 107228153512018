import { default as CommonHeader } from "components/Header/header.component";
import "./header.styles.scss";
import NoAnswerDelayNotes from "../components/no-answer-delay-note/no-answer-delay-note";

interface HeaderProps {
  className?: string;
  patientDetails: any;
  title: string | React.ReactNode;
  showDelayNote?: boolean;
}
const Header = (props: HeaderProps) => {
  const { className, patientDetails, title, showDelayNote } = props;

  return (
    <CommonHeader className={`task-management-header ${className ? className : ""}`}>
      <div className="patient-details">{patientDetails}</div>
      <div className="task-management-header-title">{title}</div>
      {showDelayNote && <NoAnswerDelayNotes />}
    </CommonHeader>
  );
};

export default Header;
