import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfig } from "config/config";
import { axiosInstance } from "shared/axios-instance";
import moment from "moment";
import {
  IAcknowledgeNotificationRequest,
  IGetNotificationsAsyncRequestPayload,
} from "state/types/notification-slice-type";

export const getNotificationCountAsync = createAsyncThunk(
  "notification/getNotificationCountAsync",
  async (requestPayload: { ownerUserId: string }) => {
    const response = await axiosInstance.get(`${getConfig().notificationBase}/api/v1/Notification/count`, {
      params: { OwnerUserId: requestPayload.ownerUserId },
    });
    return response.data;
  }
);

export const getNotificationsAsync = createAsyncThunk(
  "notification/getNotificationsAsync",
  async (requestPayload: IGetNotificationsAsyncRequestPayload) => {
    const response = await axiosInstance.post(
      `${getConfig().notificationBase}/api/v1/Notification/list`,
      requestPayload
    );
    if (response.status === 204 && !response.data) {
      return { data: [] };
    } else {
      return response.data;
    }
  }
);

export const acknowledgeAllUnreadNotifications = createAsyncThunk(
  "notification/acknowledgeAllUnreadNotifications",
  async (requestPayload: { ownerUserId: string }) => {
    const response = await axiosInstance.post(`${getConfig().notificationBase}/api/v1/Notification/mark-as-read`, {
      ownerUserId: requestPayload.ownerUserId,
      createdBefore: moment().toISOString(),
    });
    return response;
  }
);

export const acknowledgeNotification = createAsyncThunk(
  "notification/acknowledgeNotification",
  async (requestPayload: IAcknowledgeNotificationRequest) => {
    const response = await axiosInstance.post(`${getConfig().notificationBase}/api/v1/Notification/mark-as-read`, {
      internalId: requestPayload.internalId,
      ownerUserId: requestPayload.ownerUserId,
      createdBefore: requestPayload.createdBefore,
    });
    return response;
  }
);

export const getNotificationInternalId = createAsyncThunk(
  "notification/getNotificationInternalId",
  async (requestPayload: Array<string>) => {
    const response = await axiosInstance.post(
      `${getConfig().notificationBase}/api/v1/Notification/generate-internalId`,
      requestPayload
    );
    return response;
  }
);

export const getParticipantsInConversation = createAsyncThunk(
  "notification/getParticipantsInConversation",
  async (conversationId: string) => {
    const response = await axiosInstance.get(
      `${getConfig().messagingBase}/api/v1/Messaging/conversation-participants?conversationID=${conversationId}`
    );
    return response.data;
  }
);
