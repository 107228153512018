import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { TOAST_MESSAGES } from "pages/login/common/constants";
import { toast } from "react-toastify";
import { IAssignedPhoneNumbers, ICallLogsType, INavigatorState } from "state/types/navigator-slice.type";
import {
  getBackUpNavigatorListAsync,
  getNavigatorCallsAsync,
  getNavigatorDetailsAsync,
  getNavigatorsEpisodesAsync,
  getNavigatorsForManagerAsync,
  getNavigatorStatsForManagerAsync,
  getNavigatorForManagerAsync,
  getParticipantsDetailsAsync,
  updateNavigatorAsync,
  createNavigatorAsync,
  getOnCallNavigatorsListAsync,
  postOnCallNavigatorListAsync,
  getHolidayListAsync,
  getAssignPhoneNumberListAsync,
  postNavigatorStatus,
  getWhiteLabelPhoneNumberAsync,
  getCurrentUserProfile,
  acknowledgeMissedCall,
} from "./navigator.action";
import { TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED } from "shared/constant/commonConstants";
import { Participant } from "shared/types/table.type";
import { NavigatorDataType } from "pages/manager-dashboard/components/navigator-table/props";
import React from "react";
import { checkPhoneIsCorrectFormat } from "shared/methods/utilityFunctions";

const initialState: INavigatorState = {
  isLoading: false,
  isLoadingDownloadFile: false,
  isLoadingAssignedClients: false,
  isLoadingNavigatorDetails: true,
  isLoadingBackupNavigatorList: true,
  isLoadingWhiteLabelPhoneNumber: false,
  isLoadingHolidayList: false,
  isLoadingOnCallNavigators: false,
  isLoadingCurrentUserProfile: false,
  isSaveOnCallNavigatorList: false,
  isError: false,
  isErrorDownloadFile: false,
  errorMessageDownload: "",
  backupNavigators: [],
  assignedPhoneNumbers: [],
  calls: [],
  participants: {},
  profile: null,
  navigatorType: null,
  episodes: [],
  idForNavigatorEpisode: "",
  navigatorsForManager: [],
  onCallLNavigators: [],
  navigatorScheduleOnCallNavigators: [],
  extraOnCallNavigators: [],
  managerStats: {
    navigatorsList: [],
    selectedNavigator: null,
  },
  holidayList: [],
  isOpenStatusModal: false,
  isOnlineOfflineButtonLoading: false,
  isOnline: false,
  isOnCallNavigator: false,
  currentUserProfile: null,
  isCalenderValueChanged: false,
  isProfileValueChanged: false,
};

const navigatorSlice = createSlice({
  name: "navigator",
  initialState,
  reducers: {
    setIsProfileValueChanged: (state, action) => {
      state.isProfileValueChanged = action.payload;
    },
    setOnlineStatusBasedOnNavigatorType: (state, action) => {
      if (action.payload?.isOnCallNavigator) {
        state.isOnCallNavigator = action.payload.isOnCallNavigator;
      }
      state.isOnline = action.payload.isOnline;
    },
    toggleStatusModal(state: INavigatorState, action) {
      state.isOpenStatusModal = action.payload;
    },
    clearIdForNavigatorEpisode(state) {
      state.idForNavigatorEpisode = "";
    },
    setNavigatorEpisodeId(state, action) {
      sessionStorage.setItem("navigatorCareManagerId", action.payload);
      state.idForNavigatorEpisode = action.payload;
    },
    resetCalls: (state: any) => {
      state.calls = [];
    },
    resetManagerStats: (state: any) => {
      state.managerStats = {
        navigatorsList: [],
        selectedNavigator: null,
      };
    },
    resetToInitialState: (state) => {
      return {
        ...initialState,
        onCallLNavigators: state.onCallLNavigators,
        holidayList: [...state.holidayList],
        isOnline: state.isOnline,
        currentUserProfile: state.currentUserProfile,
      };
    },
    updateOnCallNavigator: (state: any, action: { payload: any[] }) => {},
    saveOnCallNavigators: (state: INavigatorState, action) => {
      const index = state.extraOnCallNavigators.findIndex((item) => item.date === action.payload.date);
      const navigator = state.navigatorScheduleOnCallNavigators.find(
        (item: any) =>
          moment(item.onCallDate).format("YYYY-MM-DD") === moment(action.payload.date).format("YYYY-MM-DD") &&
          item?.navigatorId === action.payload?.id
      );
      if (index > -1) {
        if (navigator) {
          state.extraOnCallNavigators.splice(index, 1);
        } else {
          state.extraOnCallNavigators[index] = action.payload;
        }
      } else {
        state.extraOnCallNavigators.push(action.payload);
      }
      state.isCalenderValueChanged = true;
    },
    deleteOnCallNavigator: (state: INavigatorState, action) => {
      const index = state.extraOnCallNavigators.findIndex((item) => item.date === action.payload);
      const navigator = state.navigatorScheduleOnCallNavigators.find(
        (item: any) => item.date === action.payload || moment(item.onCallDate).format("YYYY-MM-DD") === action.payload
      );
      const deleteObj = {
        id: null,
        dateId: navigator?.id,
        navigatorId: null,
        previousNavigatorId: navigator?.navigatorId ?? "",
        navigatorManagerId: null,
        date: action.payload,
        createdDate: navigator?.createdDate ?? null,
        createdUser: navigator?.createdUser ?? null,
      };
      if (index > -1) {
        if (navigator) {
          state.extraOnCallNavigators[index] = deleteObj;
        } else {
          state.extraOnCallNavigators.splice(index, 1);
        }
      } else {
        state.extraOnCallNavigators.push(deleteObj);
      }
      state.isCalenderValueChanged = true;
    },
    setLoading: (state: INavigatorState, action) => {
      state.isLoading = action.payload;
    },
    setNavigatorScheduleOnCallNavigators: (state: INavigatorState, action) => {
      state.navigatorScheduleOnCallNavigators = action.payload;
    },
    setCallReadStatus: (state, action) => {
      const index = state.calls[action.payload.index].participants.findIndex((item: Participant) => {
        return item.id === action.payload.participantDetail.id;
      });
      state.calls[action.payload.index].participants[index] = {
        ...state.calls[action.payload.index].participants[index],
        isMissedCallRead: true,
      };
    },
    setSelectedNavigator: (state, action) => {
      state.managerStats.selectedNavigator = action.payload;
    },
    resetToInitialNavigatorState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getNavigatorsEpisodesAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getNavigatorsEpisodesAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.episodes = action.payload;
      }),
      builder.addCase(getNavigatorsEpisodesAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getNavigatorStatsForManagerAsync.pending, (state: any, action) => {
        state.isLoading = true;
      }),
      builder.addCase(getNavigatorStatsForManagerAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.managerStats.navigatorsList = action.payload;
      }),
      builder.addCase(getNavigatorStatsForManagerAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getNavigatorCallsAsync.pending, (state: any, action) => {
        if (action.meta.arg.isDownloadCallsClicked) {
          state.isLoadingDownloadFile = true;
          state.errorMessageDownload = "";
          state.isErrorDownloadFile = false;
        } else {
          state.isLoading = true;
        }
      }),
      builder.addCase(getNavigatorCallsAsync.fulfilled, (state: any, action) => {
        if (action.meta.arg.isDownloadCallsClicked) {
          state.isLoadingDownloadFile = false;
          state.errorMessageDownload = "";
          state.isErrorDownloadFile = false;
        } else {
          state.isLoading = false;
          const calls = action.payload?.map((call: ICallLogsType) => {
            if (call?.participants) {
              call.participants = call.participants.map((val: Participant) => {
                const { value: phoneNumber } = checkPhoneIsCorrectFormat(val?.phoneNumber ?? "", true);
                const { value: name } = checkPhoneIsCorrectFormat(val?.name ?? "", true);
                val.phoneNumber = phoneNumber;
                val.name = name;
                return val;
              });
            }
            return call;
          });
          if (state.calls && state.calls.length > 0 && action.meta.arg.offset > 0) {
            state.calls = [...state.calls, ...calls];
          } else {
            state.calls = calls;
          }
        }
      }),
      builder.addCase(getNavigatorCallsAsync.rejected, (state: any, action) => {
        if (action.meta.arg.isDownloadCallsClicked) {
          state.errorMessageDownload =
            action.payload && typeof action.payload === "string" ? action.payload : "Something went wrong";
          state.isLoadingDownloadFile = false;
          state.isErrorDownloadFile = true;
        } else {
          state.isLoading = false;
          state.isError = true;
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "error",
          });
        }
      }),
      builder.addCase(getNavigatorForManagerAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getNavigatorForManagerAsync.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        state.managerStats.selectedNavigator = action.payload;
      }),
      builder.addCase(getNavigatorForManagerAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getNavigatorsForManagerAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getNavigatorsForManagerAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.navigatorsForManager = action.payload;
      }),
      builder.addCase(getNavigatorsForManagerAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getNavigatorDetailsAsync.pending, (state: any) => {
        state.isLoadingNavigatorDetails = true;
      }),
      builder.addCase(getNavigatorDetailsAsync.fulfilled, (state: any, action: any) => {
        state.isLoadingNavigatorDetails = false;
        state.profile = action.payload;
        state.navigatorType = action.payload.navigatorType;
      }),
      builder.addCase(getNavigatorDetailsAsync.rejected, (state: any) => {
        state.isLoadingNavigatorDetails = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getBackUpNavigatorListAsync.pending, (state: any) => {
        state.isLoadingBackupNavigatorList = true;
        state.isError = false;
        state.backupNavigators = [];
      }),
      builder.addCase(getBackUpNavigatorListAsync.fulfilled, (state: any, action) => {
        state.isLoadingBackupNavigatorList = false;
        state.isError = false;
        if (action.payload.status === 204 || action.payload == "") {
          state.backupNavigators = [];
        } else {
          state.backupNavigators = action.payload;
        }
      }),
      builder.addCase(updateNavigatorAsync.pending, (state: any) => {
        state.isLoading = true;
        state.navigatorType = null;
      }),
      builder.addCase(updateNavigatorAsync.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        if (state.currentUserProfile && action.payload.id === state.currentUserProfile.id) {
          state.currentUserProfile = action.payload;
        }
        state.navigatorType = action.payload.navigatorType;
        toast.success("Navigator updated successfully", {
          containerId: "main",
        });
        state.profile = action.payload;
      }),
      builder.addCase(updateNavigatorAsync.rejected, (state: any, action) => {
        state.isLoading = false;
        state.isError = true;
        const errorMessage = action.payload ? action.payload : TOAST_MESSAGES.ERROR;
        toast.error(errorMessage, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getParticipantsDetailsAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getParticipantsDetailsAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.participants = action.payload;
      }),
      builder.addCase(getParticipantsDetailsAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getOnCallNavigatorsListAsync.pending, (state: any, action) => {
        state.isLoadingOnCallNavigators = true;
      }),
      builder.addCase(getOnCallNavigatorsListAsync.fulfilled, (state: any, action) => {
        state.isLoadingOnCallNavigators = false;
        if (action.meta.arg.isInvokedFromSidebar) {
          if (action.payload.status === 204) {
            state.onCallLNavigators = [];
          } else {
            state.onCallLNavigators = action.payload.data;
          }
        } else {
          if (action.payload.status === 204) {
            state.navigatorScheduleOnCallNavigators = [];
          } else {
            state.navigatorScheduleOnCallNavigators = action.payload.data;
          }
        }
      }),
      builder.addCase(getOnCallNavigatorsListAsync.rejected, (state: any, action: any) => {
        state.isLoadingOnCallNavigators = false;
        state.isError = true;
        state.onCallLNavigators = [];
      }),
      builder.addCase(createNavigatorAsync.pending, (state: any, action: any) => {
        state.isLoading = true;
      }),
      builder.addCase(createNavigatorAsync.fulfilled, (state: any, action: any) => {
        state.isLoading = false;
        toast.success("Navigator added successfully", {
          containerId: "main",
        });
      }),
      builder.addCase(createNavigatorAsync.rejected, (state: any, action: any) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(postOnCallNavigatorListAsync.pending, (state: any, action: any) => {
        state.isSaveOnCallNavigatorList = true;
      }),
      builder.addCase(postOnCallNavigatorListAsync.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload.status == 200 && payload.data) {
          if (payload.data.onCallNavigatorResults.length > 0 && payload.data.outdatedOnCallNavigatorId.length > 0) {
            const dates = payload.data.outdatedOnCallNavigatorId
              .sort((date1: any, date2: any) => {
                return moment(date1.onCallDate).diff(date2.onCallDate);
              })

              .map((date: any) => {
                const convertedDate = moment(date.onCallDate).format("DD MMM");
                return convertedDate;
              });
            const dateText =
              dates.length === 1
                ? dates[0]
                : dates.length === 2
                ? `${dates[0]} and ${dates[1]}`
                : `${dates.slice(0, dates.length - 1).join(", ")} and ${dates[dates.length - 1]}`;
            toast.error(
              React.createElement(
                "div",
                { className: "on-call-navigator-toast-div" },
                "",
                React.createElement("span", null, "Unable to save your changes for"),
                React.createElement("span", { className: "bold" }, ` ${dateText} `),
                React.createElement(
                  "span",
                  null,
                  "because another user has changed the schedule since you last refreshed the page."
                )
              ),
              {
                containerId: "main",
                toastId: "call",
                className: "on-call-navigator-toast",
              }
            );
          } else if (
            payload.data.onCallNavigatorResults.length === 0 &&
            payload.data.outdatedOnCallNavigatorId.length > 0
          ) {
            toast.error(
              "Unable to save your changes because another user has changed the schedule since you last refreshed the page.",
              {
                containerId: "main",
                toastId: "call",
                className: "on-call-navigator-toast",
              }
            );
          } else if (
            payload.data.onCallNavigatorResults.length > 0 &&
            payload.data.outdatedOnCallNavigatorId.length === 0
          ) {
            toast.success("Changes have been saved successfully", {
              containerId: "main",
              className: "on-call-navigator-toast",
              toastId: "on-call",
            });
          }
        }

        const copyOfNavigatorScheduleOnCallNavigators = state.navigatorScheduleOnCallNavigators;
        for (const navData of action.meta.arg) {
          const index = copyOfNavigatorScheduleOnCallNavigators.findIndex(
            (el: any) => moment(el.onCallDate).format("YYYY-MM-DD") === navData.onCallDate && el.id === navData.id
          );
          if (index > -1) {
            copyOfNavigatorScheduleOnCallNavigators.splice(index, 1);
          } else {
            copyOfNavigatorScheduleOnCallNavigators.push(navData);
          }
        }
        state.onCallLNavigators = [...copyOfNavigatorScheduleOnCallNavigators];
        state.navigatorScheduleOnCallNavigators = [...copyOfNavigatorScheduleOnCallNavigators];
        state.isLoading = false;
        state.isCalenderValueChanged = false;
        state.isSaveOnCallNavigatorList = false;
        state.extraOnCallNavigators = [];
      }),
      builder.addCase(postOnCallNavigatorListAsync.rejected, (state: any, action: any) => {
        state.isSaveOnCallNavigatorList = false;
        state.isError = true;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "error",
        });
      }),
      builder.addCase(getHolidayListAsync.fulfilled, (state: any, action: any) => {
        state.isLoadingHolidayList = false;
        if (action.payload.status === 200) {
          state.holidayList = action.payload.data.holidays;
        }
      }),
      builder.addCase(getHolidayListAsync.pending, (state: any, action: any) => {
        state.isLoadingHolidayList = true;
      }),
      builder.addCase(getHolidayListAsync.rejected, (state: any, action) => {
        state.isLoadingHolidayList = false;
        toast.error(TOAST_MESSAGES.ERROR, {
          containerId: "main",
          toastId: "holiday-error",
        });
      }),
      builder.addCase(getAssignPhoneNumberListAsync.pending, (state: any) => {
        state.isLoadingAssignedClients = true;
      }),
      builder.addCase(
        getAssignPhoneNumberListAsync.fulfilled,
        (state, action: PayloadAction<Array<IAssignedPhoneNumbers>>) => {
          state.isLoadingAssignedClients = false;
          state.assignedPhoneNumbers = action.payload.map((assignNumber) => {
            if (assignNumber.whiteLabelPhoneNumber) {
              const { value: number } = checkPhoneIsCorrectFormat(assignNumber.whiteLabelPhoneNumber ?? "", true);
              assignNumber.whiteLabelPhoneNumber = number;
            }
            return assignNumber;
          });
          if (action.payload.length === 0) {
            toast.error(TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED, {
              containerId: "main",
              toastId: "call",
            });
          }
        }
      ),
      builder.addCase(getAssignPhoneNumberListAsync.rejected, (state: any) => {
        state.isLoadingAssignedClients = false;
        state.isError = true;
      }),
      builder.addCase(postNavigatorStatus.rejected, (state: any) => {
        state.isOnlineOfflineButtonLoading = false;
        state.isError = true;
      }),
      builder.addCase(postNavigatorStatus.pending, (state: any) => {
        state.isOnlineOfflineButtonLoading = true;
        state.isError = false;
      }),
      builder.addCase(postNavigatorStatus.fulfilled, (state: INavigatorState, action) => {
        state.isError = false;
        if (state.currentUserProfile) {
          state.currentUserProfile.statusOverrride = action.payload?.data?.statusOverrride;
        }
        if (state.managerStats.navigatorsList && state.managerStats.navigatorsList.length > 0) {
          const index = state.managerStats.navigatorsList.findIndex(
            (navigator: NavigatorDataType) => navigator.id === action.payload.data.id
          );
          if (index !== -1) {
            state.managerStats.navigatorsList[index].statusOverrride = action.payload?.data?.statusOverrride;
          }
        }
        if (state.navigatorsForManager && state.navigatorsForManager.length > 0) {
          const index2 = state.navigatorsForManager.findIndex(
            (navigator: NavigatorDataType) => navigator.id === action.payload.data.id
          );
          if (index2 !== -1) {
            state.navigatorsForManager[index2].statusOverrride = action.payload?.data?.statusOverrride;
          }
        }
        state.isOnlineOfflineButtonLoading = false;
      }),
      builder.addCase(getCurrentUserProfile.pending, (state) => {
        state.isLoading = true;
        state.isLoadingCurrentUserProfile = true;
        state.isError = false;
      }),
      builder.addCase(getCurrentUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoadingCurrentUserProfile = false;
        state.isError = false;
        state.currentUserProfile = action.payload;
      }),
      builder.addCase(getWhiteLabelPhoneNumberAsync.pending, (state) => {
        state.isLoadingWhiteLabelPhoneNumber = true;
      }),
      builder.addCase(getWhiteLabelPhoneNumberAsync.fulfilled, (state) => {
        state.isLoadingWhiteLabelPhoneNumber = false;
      }),
      builder.addCase(getWhiteLabelPhoneNumberAsync.rejected, (state) => {
        state.isLoadingWhiteLabelPhoneNumber = false;
      }),
      builder.addCase(acknowledgeMissedCall.pending, (state) => {}),
      builder.addCase(acknowledgeMissedCall.fulfilled, (state, action) => {}),
      builder.addCase(acknowledgeMissedCall.rejected, (state) => {})
    );
  },
});

export const {
  clearIdForNavigatorEpisode,
  setNavigatorEpisodeId,
  resetCalls,
  resetManagerStats,
  saveOnCallNavigators,
  deleteOnCallNavigator,
  setLoading,
  toggleStatusModal,
  setOnlineStatusBasedOnNavigatorType,
  resetToInitialState,
  setIsProfileValueChanged,
  setNavigatorScheduleOnCallNavigators,
  setCallReadStatus,
  resetToInitialNavigatorState,
  setSelectedNavigator,
} = navigatorSlice.actions;
export const getNavigatorState = (state: any): INavigatorState => state.navigator;
export default navigatorSlice;
