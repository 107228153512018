import { createAsyncThunk } from "@reduxjs/toolkit";
import { PatientEpisodeStatus } from "components/Modal/patient-status-modal/patient-status-modal-constants";
import { getConfig } from "config/config";
import moment from "moment";
import { IPlanOfCareListType } from "pages/episodes/episode-details/tabs/plan-of-care/type";
import { axiosInstance } from "shared/axios-instance";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import {
  getDateInMMDDYYYYFormat,
  getTaskStatusNameByTaskStatusId,
  isEmptyString,
} from "shared/methods/utilityFunctions";
import {
  EpisodesRequestPayloadProps,
  IGetActivityLogRequestPayload,
  IEpisodeDetailsTasksApiType,
  IManualTasksApiType,
  IManualTasksFieldsApiType,
  IUpdateEpisodeRequestPayload,
  IAllEpisodePayload,
  GetPrimaryCarePhysician,
  GetGeneralCategory,
  GetPCPPractice,
  DemoGraphicDetailsType,
  ReasonStatus,
  PatientEpisodeStatusPayload,
  GetProvidersReferrerListResponse,
  IUpdatePatientDeclinedNavigationPayload,
} from "state/types/episodes-slice.type";
import { IGetPatientEpisodeDetailsResponse } from "state/types/patient-episode.type";

export const getEpisodesAsync = createAsyncThunk(
  "episodes/getEpisodesAsync",
  async (requestPayload: EpisodesRequestPayloadProps) => {
    const response = await axiosInstance.post(
      `${getConfig().navigatorBase}/api/v1/episodes/episode-list`,
      requestPayload
    );
    if (response.status === 200) {
      response.data = response.data.map((res: any) => {
        const {
          status,
          admitDate,
          careManagerId,
          dateOfBirth,
          episodeId,
          episodeName,
          firstName,
          intakeId,
          lastName,
          middleName,
          pnavFirstName,
          pnavLastName,
          physicianFirstName,
          physicianLastName,
          surgeonId,
          surgeryDate,
          tNav,
        } = res;
        return {
          id: episodeId,
          name:
            firstName && !isEmptyString(firstName)
              ? `${firstName}${middleName && !isEmptyString(middleName) ? ` ${middleName}` : ""}${
                  lastName && !isEmptyString(lastName) ? ` ${lastName}` : ""
                }`
              : "-",
          dob: dateOfBirth ? moment(dateOfBirth).format("MM/DD/YYYY") : "-",
          episodeName: episodeName && !isEmptyString(episodeName) ? episodeName : "-",
          admitDate: admitDate ? moment(admitDate).format("MM/DD/YYYY") : "-",
          surgeryDate: surgeryDate ? moment(surgeryDate).format("MM/DD/YYYY") : "-",
          status: status && !isEmptyString(status) ? status : "-",
          physician:
            physicianFirstName && !isEmptyString(physicianFirstName)
              ? `${physicianFirstName}${
                  physicianLastName && !isEmptyString(physicianLastName) ? ` ${physicianLastName}` : ""
                }`
              : "-",
          pnav:
            pnavFirstName && !isEmptyString(pnavFirstName)
              ? `${pnavFirstName}${pnavLastName && !isEmptyString(pnavLastName) ? ` ${pnavLastName}` : ""}`
              : "-",
          tnav: tNav && !isEmptyString(tNav) ? tNav : "-",
          intakeId,
          surgeonId,
          careManagerId,
        };
      });
    } else {
      response.data = [];
    }

    return response.data;
  }
);

export const getEpisodesFilterStatusMenuAsync = createAsyncThunk(
  "episodes/getEpisodesFilterStatusMenuAsync",
  async () => {
    const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/intake-statuses`);
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const getEpisodeDetailsById = createAsyncThunk(
  "episodes/getEpisodeDetailsById",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/episode-detail`, {
      params: requestPayload,
    });

    if (response.data && !isEmptyString(response.data)) {
      return response.data;
    }
    return null;
  }
);

export const getEpisodeTasksAsync = createAsyncThunk(
  "episodes/getEpisodeTasksAsync",
  async (requestPayload: { intakeId: number; sortColumn: string; sortOrder: SortingOrderType }) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Episode/episode-task-list`,
      requestPayload
    );
    if (response.status === 200) {
      const getStatusType = (task: IEpisodeDetailsTasksApiType) => {
        if (task?.taskStatusId === TaskStatus.COMPLETED) {
          if (task.isEditable) {
            return "Editable";
          } else if (task.isRevisable) {
            return "Revisable";
          }
          return null;
        } else if (task.taskStatusId && task.taskStatusId === TaskStatus.PROCESSING && task.attemptCount) {
          return `${task.attemptCount} Attempt${task.attemptCount > 1 ? "s" : ""}`;
        }
        return null;
      };
      response.data = response.data.map((task: IEpisodeDetailsTasksApiType, index: number) => {
        return {
          id: task.id,
          startDate: task.startDate ? getDateInMMDDYYYYFormat(moment(task.startDate, "YYYY-MM-DD")) : "-",
          dueDate: task.dueDate ? getDateInMMDDYYYYFormat(moment(task.dueDate, "YYYY-MM-DD")) : "-",
          taskName: task.taskName && !isEmptyString(task.taskName) ? task.taskName : "-",
          taskStatus: task.taskTypeId !== 0 ? getTaskStatusNameByTaskStatusId(task.taskStatusId) : "-",
          taskStatusId: task.taskStatusId,
          statusType: getStatusType(task),
          completedOn: task.completedOn ? getDateInMMDDYYYYFormat(moment(task.completedOn, "YYYY-MM-DD")) : "-",
          doneBy: task.doneBy ? task.doneBy : "-",
          providerType: task.providerType,
          provider: task.provider,
          isOutdated: task.isOutdated,
          taskTypeId: task.taskTypeId,
          taskTitle: task?.taskTitle,
          isActive: task.isActive,
          reason: task.reason && !isEmptyString(task.reason) ? task.reason : "-",
          dateAndTime: task.performedOn ? moment(task.performedOn).format("MM/DD/YYYY hh:mm A") : "-",
          performedBy: task.performedBy && !isEmptyString(task.performedBy) ? task.performedBy : "-",
          secondaryTaskStatusId: task.secondaryTaskStatusId,
          ownerId: task.ownerId,
          ownerName: task.ownerName,
        };
      });
      return response.data;
    } else {
      return [];
    }
  }
);

export const getManualTaskTypeAsync = createAsyncThunk("task-management/getManualTaskAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/Tasks/manual-tasks`);
  if (response.status === 200 && response.data?.data) {
    return response.data.data.map((question: IManualTasksApiType) => {
      return {
        key: question.id,
        name: question.name,
        taskTypeId: question.taskTypeId,
        manualTaskFields: question.manualTaskFields.map((field: any) => field.id),
      };
    });
  }
  return [];
});

export const getManualFieldTaskAsync = createAsyncThunk("task-management/getManualFieldTaskAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/Tasks/manual-task-fields`);
  if (response.status === 200) {
    return response.data?.data?.map((question: IManualTasksFieldsApiType) => {
      return {
        questionId: question.id,
        fieldId: question.fieldId,
        ...question.fields,
      };
    });
  }
  return [];
});

export const addManualTaskAsync = createAsyncThunk(
  "task-management/addManualTaskAsync",
  async (requestPayload: {
    intakeId: number;
    createUser: string;
    createUserId: string;
    noteReminderManualTask: {
      title: string;
      taskOwners: string;
      notes: string | null;
      taskOwnerUserId: string;
      startDate: string;
      dueDate: string;
    };

    taskTypeId: number;
  }) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/add-manual-task`,
      requestPayload
    );
    return response;
  }
);

export const getEpisodeTasksExistAsync = createAsyncThunk(
  "task-management/getEpisodeTasksExist",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().taskManagementBase}/api/v1/Tasks/intake?intakeId=${requestPayload.intakeId}`
    );
    if (response.status === 200 && response.data) {
      return {
        value: true,
        clientId: response.data.data.clientId,
      };
    }
    return { value: false, clientId: -1 };
  }
);

export const fetchManualTaskAsync = createAsyncThunk(
  "task-management/fetchManualTaskAsync",
  async (requestPayload: { intakeId: string; taskId: string }) => {
    const response = await axiosInstance.post(
      `${getConfig().taskManagementBase}/api/v1/Tasks/fetch-manual-task`,
      requestPayload
    );
    if (response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  }
);

export const saveReminderTaskAsync = createAsyncThunk(
  "task-management/saveReminderTaskAsync",
  async (requestPayload: {
    intakeId: string;
    taskId: string;
    notes: string;
    updateUser: string;
    updateUserId: string;
    isSaveDraft: boolean;
  }) => {
    const response = await axiosInstance.put(
      `${getConfig().taskManagementBase}/api/v1/Tasks/save-reminder`,
      requestPayload
    );
    if (response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  }
);

export const updateEpisodeAsync = createAsyncThunk(
  "episodes/updateEpisodeAsync",
  async (requestPayload: IUpdateEpisodeRequestPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${getConfig().efusionBase}/api/v2/ExtIntake/IntakeWithPatient`,
        requestPayload
      );
      return response;
    } catch (error: any) {
      const errorResponse = error.response.data;
      return rejectWithValue(errorResponse.ErrorMessage);
    }
  }
);

export const getPreferredPhoneNumberIdAsync = createAsyncThunk("episodes/getPreferredPhoneNumberIdAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtGenericListItem`, {
    params: { ItemGroup: "PreferredPhone" },
  });
  return response.data;
});

export const getSurgerySiteSideAsync = createAsyncThunk(
  "episodes/getSurgerySiteSideAsync",
  async (requestPayload: string) => {
    const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtSurgeryCategory`, {
      params: { SurgeryCategoryID: requestPayload },
    });
    return response.data;
  }
);

export const getAllEpisodesAsync = createAsyncThunk(
  "episodes/getAllEpisodesAsync",
  async ({ programId, clientId }: IAllEpisodePayload) => {
    const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtEpisode`, {
      params: {
        ProgramID: programId,
        IsActive: true,
        clientId,
      },
    });
    return response.data;
  }
);

export const getAllSurgeonsAsync = createAsyncThunk("episodes/getAllSurgeonsAsync", async (clientId: number) => {
  const response = await axiosInstance.get(
    `${getConfig().efusionBase}/api/v2/ExtProvider/getAll/false/false/true/false/true/0/${clientId}`,
    { params: {} }
  );
  return response.data;
});

export const getAllFacilitiesAsync = createAsyncThunk("episodes/getAllFacilitiesAsync", async (clientId: number) => {
  const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtProvider/acuteCareFacility`, {
    params: { clientId },
  });
  return response.data;
});

export const getAllPracticesAsync = createAsyncThunk("episodes/getAllPracticesAsync", async (clientId: number) => {
  const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtPractice`, { params: { clientId } });
  return response.data;
});

export const getAllProgramsAsync = createAsyncThunk("episodes/getAllProgramsAsync", async (clientId: number) => {
  const response = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtProgram`, {
    params: {
      clientId,
    },
  });
  return response.data;
});

export const getEpisodeDetailsByIntakeIdAsync = createAsyncThunk<IGetPatientEpisodeDetailsResponse, string>(
  "episodes/getEpisodeDetailsByIntakeIdAsync",
  async (intakeId: string) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().efusionBase}/api/v2/ExtIntake/getIntakeWithPatientDetails/${intakeId}`
      );
      if (response.status === 204) {
        return null;
      }
      if (response.status === 200) {
        const {
          id,
          surgeonId,
          npi,
          practiceId,
          programId,
          episodeId,
          cpt,
          procedureDescription,
          episodeSettingId,
          episodeTypeId,
          episodeCareId,
          surgerySiteSide,
          facilityId,
          ccn,
          admitDate,
          surgeryDate,
          intakeStatusId,
          intakeId: intake,
          firstName,
          middleName,
          lastName,
          suffix,
          email,
          genderId,
          dob,
          emergencyContactName,
          emergencyContactNumber,
          cellPhone,
          homePhone,
          preferredPhone,
          mbi,
          mbiEffectiveDate,
          mrn,
          address1,
          zipcode,
          city,
          state,
          primaryCareManagerId,
          dischargeDate,
          plannedUnplannedId,
          medSurg,
          dischargePhysicianId,
          admittingPhysicianId,
          surgerySiteId,
          surgerySideId,
          pnavFirstName,
          pnavLastName,
          transitionNavigatorId,
        } = response.data;
        return {
          id,
          surgeonId,
          npi: npi ?? "",
          practiceId,
          programId,
          episodeId,
          cpt: cpt ?? "",
          procedureDescription: procedureDescription ?? "",
          episodeSettingId,
          episodeTypeId,
          episodeCareId,
          surgerySiteSide,
          facilityId,
          ccn: ccn ?? "",
          admitDate,
          surgeryDate,
          intakeStatusId,
          firstName,
          middleName: middleName ?? "",
          lastName,
          suffix: suffix ?? "",
          email: email ?? "",
          genderId,
          dob,
          emegencyContactName: emergencyContactName ?? "",
          emegencyContactNumber: emergencyContactNumber?.toString().slice(2),
          cell: cellPhone?.toString().slice(2),
          home: homePhone?.toString().slice(2),
          preferredPhone,
          mbi: mbi ?? "",
          mbiEffectiveDate: mbiEffectiveDate ?? "",
          mrn: mrn ?? "",
          address: address1 ?? "",
          zipcode: zipcode ?? "",
          city: city ?? "",
          state: state ?? "",
          intakeId: intake,
          primaryCareManagerId,
          dischargeDate,
          plannedUnplannedId,
          medSurg,
          dischargePhysicianId,
          admittingPhysicianId,
          surgerySiteId,
          surgerySideId,
          pnavFirstName,
          pnavLastName,
          transitionNavigatorId,
        } as IGetPatientEpisodeDetailsResponse;
      }
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const getActvityLogAsync = createAsyncThunk(
  "task-management/getActvityLogAsync",
  async (requestPayload: IGetActivityLogRequestPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${getConfig().taskManagementBase}/api/v1/ActivityLog`, requestPayload);
      if (response.status === 204) {
        return [];
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAcuteFacilityCareByIdAsync = createAsyncThunk(
  "episodes/getAcuteFacilityCareByIdAsync",
  async (requestPayload: { facilityId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtProvider/${requestPayload.facilityId}`
    );
    return response.data;
  }
);

export const getProcedureDetails = createAsyncThunk(
  "episodes/getProcedureDetails",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/episode-detail`, {
      params: requestPayload,
    });

    return response.data;
  }
);

export const getIntakeStatuses = createAsyncThunk("episodes/getIntakeStatuses", async () => {
  const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/intake-statuses`);
  if (response.status === 200) {
    return response.data.map((item: { id: number; statusLongName: string; statusShortName: string }) => {
      return {
        key: item.id.toString(),
        name: item.statusLongName,
        id: item.id ? item.id.toString() : "",
      };
    });
  } else {
    return [];
  }
});
export const getTaskTypeCategoriesAsync = createAsyncThunk("task-management/getTaskTypeCategoriesAsync", async () => {
  const response = await axiosInstance.get(`${getConfig().taskManagementBase}/api/v1/Tasks/get-tasktype-categories`);
  return response;
});

export const getDemographicDataAsync = createAsyncThunk(
  "episode/getDemographicDataAsync",
  async (requestPayload: { patientId: string }) => {
    const result = await axiosInstance.get(
      `${getConfig().userManagementBase}/api/v1/Patient/patient-demographics/${requestPayload.patientId}`
    );
    return result.data;
  }
);

export const getPrimaryCarePhysiciansync = createAsyncThunk(
  "episode/getPrimaryCarePhysiciansync",
  async (requestPayload: { clientId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtProvider/getPCPs/false?clientId=${requestPayload.clientId}`
    );
    const result = response.data;
    let primaryCarePhysicianlist = [];
    if (result) {
      primaryCarePhysicianlist = result.map((item: GetPrimaryCarePhysician) => ({
        key: item.ID,
        name: item.ProviderName,
      }));
    }
    return primaryCarePhysicianlist;
  }
);

export const getPracticeAsync = createAsyncThunk(
  "episode/getPracticeAsync",
  async (requestPayload: { clientId: string }) => {
    const response = await axiosInstance.get(
      `${getConfig().efusionBase}/api/v2/ExtPractice?clientId=${requestPayload.clientId}`
    );
    const result = response.data;
    let pcpPracticeList = [];
    if (result) {
      pcpPracticeList = result.map((item: GetPCPPractice) => ({ key: item.ID, name: item.LongName }));
    }
    return pcpPracticeList;
  }
);

export const getGeneralCategoryAsync = createAsyncThunk("episode/getGeneralCategoryAsync", async () => {
  const generalCategory = await axiosInstance.get(`${getConfig().efusionBase}/api/v2/ExtGenericListItem`);
  const result = generalCategory.data;
  let coachRelationshipList = [];
  let maritalStatusList = [];
  if (result) {
    const coachFilter = result.filter((item: GetGeneralCategory) => item.ItemGroup === "CoachRelationship");
    const maritalFilter = result.filter((item: GetGeneralCategory) => item.ItemGroup === "MaritalStatus");
    coachRelationshipList = coachFilter.map((item: GetGeneralCategory) => ({ key: item.ID, name: item.ItemLongName }));
    maritalStatusList = maritalFilter.map((item: GetGeneralCategory) => ({ key: item.ID, name: item.ItemLongName }));
  }
  return {
    coachRelationshipList,
    maritalStatusList,
  };
});

export const postDemographicsDataAsync = createAsyncThunk(
  "episode/postDemographicsDataAsync",
  async (requestPayload: DemoGraphicDetailsType, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(
        `${getConfig().userManagementBase}/api/v1/Patient/patient-demographics`,
        requestPayload
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStatusChangeReason = createAsyncThunk(
  "episode/getStatusChangeReason",
  async (id: string, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/Episode/status-change-reason/${id}`
      );
      return result.data.data.map((item: ReasonStatus) => {
        return {
          id: item.id,
          name: item.reasonLongName,
        };
      });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postPatientEpisodeStatus = createAsyncThunk(
  "episode/postPatientEpisodeStatus",
  async (requestPayload: PatientEpisodeStatusPayload, { rejectWithValue }) => {
    try {
      const result = await axiosInstance.post(
        `${getConfig().taskManagementBase}/api/v1/Episode/intake-status`,
        requestPayload
      );
      return result.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPlanOfCareListAsync = createAsyncThunk(
  "episodes/getPlanOfCareListAsync",
  async (requestPayload: { intakeId: string; sortOrder?: string; sortBy?: string }) => {
    try {
      const { intakeId, sortBy, sortOrder } = requestPayload;
      const response = await axiosInstance.get(
        `${getConfig().taskManagementBase}/api/v1/PlanOfCare/intake/${intakeId}`,
        { params: sortBy && sortOrder ? { sortBy, sortOrder } : {} }
      );
      if (response?.data?.data) {
        return response.data.data.map((res: IPlanOfCareListType) => {
          return {
            ...res,
            actualDischargeDate: res.actualDischargeDate
              ? getDateInMMDDYYYYFormat(moment(res.actualDischargeDate, "YYYY-MM-DD"))
              : "-",
            actualAdmitDate: res.actualAdmitDate
              ? getDateInMMDDYYYYFormat(moment(res.actualAdmitDate, "YYYY-MM-DD"))
              : "-",
          };
        });
      }
      return [];
    } catch (error) {
      return [];
    }
  }
);

export const getProvidersReferrerListAsync = createAsyncThunk(
  "episode/getProvidersReferrerListAsync",
  async (
    requestPayload: {
      clientId: number;
      showAll: true;
    },
    { rejectWithValue }
  ) => {
    try {
      const result = await axiosInstance.post(
        `${getConfig().navigatorBase}/api/v1/providers/referrer-list`,
        requestPayload
      );
      if (result.status === 200 && result.data) {
        return result.data as Array<GetProvidersReferrerListResponse>;
      }
      return [] as Array<GetProvidersReferrerListResponse>;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPatientStatusById = createAsyncThunk(
  "episodes/getPatientStatusById",
  async (requestPayload: { intakeId: string }) => {
    try {
      const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/episode-detail`, {
        params: requestPayload,
      });

      if (response.data && !isEmptyString(response.data)) {
        return { status: response.data.status, statusChangeReasonId: response.data.statusChangeReasonId };
      }
      return null;
    } catch (err) {
      return null;
    }
  }
);

export const getEpisodePatientLastLoginAsync = createAsyncThunk(
  "episodes/getEpisodePatientLastLoginAsync",
  async (requestPayload: { userId: string }) => {
    try {
      const response = await axiosInstance.get(
        `${getConfig().authenticationBase}/api/v1/Account/get-registration-detail`,
        {
          params: requestPayload,
        }
      );
      if (response.status === 200 && response.data?.data) {
        return response.data.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
);

export const getUpdatedEpisodeDetailsById = createAsyncThunk(
  "episodes/getUpdatedEpisodeDetailsById",
  async (requestPayload: { intakeId: string }) => {
    const response = await axiosInstance.get(`${getConfig().navigatorBase}/api/v1/episodes/episode-detail`, {
      params: requestPayload,
    });

    if (response.data && !isEmptyString(response.data)) {
      return response.data;
    }
    return null;
  }
);

export const updateChangeTnavTaskOwnerAsync = createAsyncThunk(
  "episodes/updateChangeTnavTaskOwnerAsync",
  async (
    requestPayload: {
      intakeId: number;
      taskId: string;
      updateUser: string;
      updateUserId: string;
      ownerName: string;
      ownerId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(`${getConfig().taskManagementBase}/api/v1/Tasks/owner`, requestPayload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePatientDeclinedNavigationAsync = createAsyncThunk(
  "episodes/updatePatientDeclinedNavigationAsync",
  async (requestPayload: IUpdatePatientDeclinedNavigationPayload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${getConfig().navigatorBase}/api/v1/episodes`, requestPayload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
