import { useState, useEffect, useCallback } from "react";
import Logo from "shared/assets/images/logo.png";
import "./login.styles.scss";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Button from "components/Button/button.component";
import TextField from "components/TextFieldV2/text-field-v2.component";
import Icon from "components/Icon/icon.component";
import { loginUserAsync } from "state/feature/auth/auth.action";
import { clearUser, getAuth, setIsSessionExpired } from "state/feature/auth/auth.slice";
import ButtonLoader from "components/ButtonLoader/button-loader.component";
import { isMobile } from "react-device-detect";
import { VALID_PASSWORD_SIZE, LOGIN_FORM } from "./common/constants";
import {
  setIsLogoutButtonClicked,
  setIsSessionTimeoutModalVisible,
  setShowTwilioPopup,
} from "state/feature/common/common.slice";
import { synchronizeCallsAsync } from "state/feature/call/call.action";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useAppDispatch } from "state/store";
import jwtDecode from "jwt-decode";
import { useIdleTimerContext } from "react-idle-timer";
import { logNavigatorAction } from "state/feature/navigator/navigator.action";
import { NavigatorActionsEnum } from "shared/enums/navigator-actions-enum";
import { resetProxyNav } from "state/feature/messaging/messaging.action";

export const Login = () => {
  useAxiosAuthenticated();
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonActive, setButtonActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const authState = useSelector(getAuth);
  const { reset } = useIdleTimerContext();

  const handleLoginClick = async (event: Event) => {
    reset();
    dispatch(setIsSessionTimeoutModalVisible(false));
    setButtonActive(false);
    event.preventDefault();
    dispatch(
      loginUserAsync({
        username,
        password,
      })
    )
      .then((response) => {
        const jwtPayload: any = jwtDecode(response.payload?.jwtToken);
        if (jwtPayload && jwtPayload.navigatorid) {
          dispatch(synchronizeCallsAsync({ ids: [jwtPayload.navigatorid], callSid: null }));
        }
        dispatch(
          logNavigatorAction({
            createdBy: jwtPayload.navigatorid,
            createdByUser: `${response.payload.firstName} ${response.payload.lastName}`,
            type: NavigatorActionsEnum.LOGIN,
          })
        );
        dispatch(resetProxyNav());
      })
      .catch(() => {
        setButtonActive(true);
      });
    dispatch(setIsLogoutButtonClicked(false));
    dispatch(setShowTwilioPopup(true));
  };

  const togglePassword = () => {
    setShowPassword((prev) => {
      const element = document.querySelector(".password-input") as HTMLInputElement;
      if (!prev) {
        element.type = "text";
      } else {
        element.type = "password";
      }
      return !prev;
    });
  };

  const checkButtonForActive = useCallback(() => {
    const activeStatus = username.length > 0 && password.length >= VALID_PASSWORD_SIZE;
    setButtonActive(activeStatus);
  }, [username, password, setButtonActive]);

  const redirectToForgotPasswordPage = () => {
    history.push(`/forgot-password`);
  };

  useEffect(() => {
    checkButtonForActive();
  }, [checkButtonForActive]);

  useEffect(() => {
    dispatch(clearUser());
    dispatch(setIsSessionExpired(false));
  }, [dispatch]);

  return (
    <>
      <div className={`loginScreenWrapper ${isMobile ? "mobileView" : ""}`}>
        <div className="leftLoginBlock">
          <div className="loginFormWrapper">
            <div className="logoHeader">
              <img src={Logo} alt="logo" className="logo-image" />
            </div>
            <form className="login-form">
              <div className="login-field-wrapper">
                <TextField
                  label="User Name"
                  className="input-field"
                  inputClassName="input"
                  inputContainerClassName="login-input-container"
                  type="text"
                  value={username}
                  setValue={(label, value2) => setUsername(value2)}
                  icon={<Icon icon="username" size={16} className="username-icon" />}
                  placeholder={LOGIN_FORM.placeholder}
                />
                <TextField
                  label="Password"
                  className="input-field"
                  inputContainerClassName="login-input-container"
                  inputClassName="password-input"
                  type="password"
                  value={password}
                  setValue={(label, value2) => setPassword(value2)}
                  icon={<Icon icon="password" size={19} className="username-icon" />}
                  togglePassword={togglePassword}
                  isVisiblePassword={showPassword}
                  placeholder={LOGIN_FORM.passwordPlaceholder}
                />
              </div>
              <div className="login-button-wrapper">
                <Button
                  text="Forgot Password?"
                  onClick={redirectToForgotPasswordPage}
                  className="forgot-password no-border-button icon-button"
                />
                <div className="login-button-container">
                  <button
                    className={`login-button ${isButtonActive ? "" : "disabled"}`}
                    onClick={(event: any) => handleLoginClick(event)}
                  >
                    {authState.isLoading ? (
                      <ButtonLoader />
                    ) : (
                      <span className="login-button-screen">{LOGIN_FORM.buttonLabel}</span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="rightImageBlock">
          <div className="big-text">
            <div className="line1">Manage Your Patients</div>
            <div className="line2">Effortlessly</div>
          </div>
        </div>
      </div>
    </>
  );
};
