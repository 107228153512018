import { EpisodeDetailsTab } from "shared/enums/episodes-details.enum";
import { TaskInfoTab } from "shared/enums/task-management-tabs.enum";

export const getTaskInfoTabs = () => [
  {
    name: TaskInfoTab.EPISODE,
  },
  {
    name: TaskInfoTab.TASKS,
  },
  {
    name: TaskInfoTab.ACTIVITY_HISTORY,
    className: "activity-log-task-info-tab",
  },
  {
    name: TaskInfoTab.CARE_UTILIZATION,
    className: "care-utilization-task-info-tab",
  },
];

export const getEpisodeDetailsTabs = () => [
  {
    name: EpisodeDetailsTab.TASKS,
  },
  { name: EpisodeDetailsTab.PATIENT_DEMOGRAPHICS },
  { name: EpisodeDetailsTab.PROCEDURE_DETAILS },
  { name: EpisodeDetailsTab.ACTIVITY_HISTORY },
  { name: EpisodeDetailsTab.CARE_UTILIZATION },
  { name: EpisodeDetailsTab.MISC },
];
