import React from "react";
import "./patient-phone-numbers-dropdown.style.scss";
import { checkPhoneIsCorrectFormat, isEmptyString } from "shared/methods/utilityFunctions";
const PatientPhoneNumbersDropdown = ({
  contactDetails,
  id,
  patient,
  className,
  handleClick,
}: {
  contactDetails: Array<any>;
  id: string;
  patient: string;
  className?: string;
  handleClick: (phoneNumber: string) => void;
}) => {
  return (
    <div id={id} className={`phone-numbers-container ${className ? className : ""}`}>
      {contactDetails.map(({ isPreferred, type, phoneNumber }, index) => {
        const { isNumberNan, value } = checkPhoneIsCorrectFormat(phoneNumber, true);

        return (
          <div
            className={`phone-number-details ${isPreferred ? "highlighted" : ""}`}
            onClick={() => handleClick(value)}
            key={`${value}-${index}`}
          >
            <div className="name">
              <div className="name-text">{patient}</div>
              {phoneNumber && value && !isEmptyString(value) && <div className="phone-number">{value}</div>}
            </div>
            <div className="label-right">
              <div className="label">
                {type}
                {isPreferred && <span>(preferred)</span>}
              </div>
              {isNumberNan && <div className="invalid-number-label">Invalid Number</div>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PatientPhoneNumbersDropdown;
