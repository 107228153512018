import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  getActvityLogAsync,
  getEpisodeDetailsById,
  getEpisodeTasksAsync,
  getEpisodeTasksExistAsync,
  getEpisodesAsync,
  getEpisodesFilterStatusMenuAsync,
  getManualFieldTaskAsync,
  getManualTaskTypeAsync,
  fetchManualTaskAsync,
  getPreferredPhoneNumberIdAsync,
  getSurgerySiteSideAsync,
  getAllEpisodesAsync,
  getAllSurgeonsAsync,
  getAllFacilitiesAsync,
  getAllPracticesAsync,
  getAllProgramsAsync,
  updateEpisodeAsync,
  getEpisodeDetailsByIntakeIdAsync,
  getIntakeStatuses,
  getStatusChangeReason,
  postPatientEpisodeStatus,
  getPlanOfCareListAsync,
  getProvidersReferrerListAsync,
  getPatientStatusById,
  getEpisodePatientLastLoginAsync,
} from "./episodes.action";
import {
  IEpisodeState,
  IProcedureDetailType,
  DemoGraphicDetailsType,
  ErrorListType,
  IntakeStatus,
  GetProvidersReferrerListResponse,
  IActivityHistoryLog,
  ICallLog,
  ITaskClosureAction,
  IDelayedLog,
} from "state/types/episodes-slice.type";
import { toast } from "react-toastify";
import { SURGEON_EPISODE_DETAILS_FORM, keys } from "shared/constant/commonConstants";
import {
  createPatientEpisodeDetailsData,
  getTaskNameByTaskStatusId,
  isEmptyString,
} from "shared/methods/utilityFunctions";
import {
  getDemographicDataAsync,
  getPrimaryCarePhysiciansync,
  getPracticeAsync,
  getGeneralCategoryAsync,
  postDemographicsDataAsync,
} from "./episodes.action";
import { SavedSuccessfully } from "pages/episodes/episode-details/tabs/patient-demographics/constants";
import {
  PatientEpisodeStatus,
  PatientEpisodeStatusSuccessMessage,
} from "components/Modal/patient-status-modal/patient-status-modal-constants";
import { TaskType } from "shared/enums/task-type.enum";
import { TaskStatus } from "shared/enums/tasks-status.enum";

const initialState: IEpisodeState = {
  isLoading: true,
  isLoadingEpisodeFilterStatus: true,
  isError: false,
  episodes: [],
  isNewIntakeCreation: false,
  isLoadingNewIntakeCreation: false,
  episodeDetails: null,
  intakeStatuses: [],
  patientEpisodeDetails: null,
  isLoadingProcedureDetailsSubmit: false,
  isActivityFilterApplied: false,
  isLoadingEpisodeDetails: true,
  isEpisodeReminderTaskExist: false,
  isLoadingEpisodeDetailTabs: {
    tasks: false,
    activityHistory: false,
    planOfCare: true,
  },
  episodeDetailTabs: {
    tasks: [],
    activityHistory: [],
    planOfCare: [],
    procedureDetails: {
      details: SURGEON_EPISODE_DETAILS_FORM,
      isDirty: false,
      isValidForm: false,
      metadata: {
        preferredPhones: [],
        surgerySiteSides: [],
        episodes: [],
        surgeons: [],
        facilities: [],
        practices: [],
        programs: [],
        intakeStatuses: [],
        referrals: [],
      },
    },
    patientDemographicsDetails: {
      demographicError: "",
      demographicDetails: {} as DemoGraphicDetailsType,
      duplicateDataErrors: [],
      dropdownsData: {
        dropDownerror: "",
        coachRelationshipList: [],
        primaryCarePhysicianList: [],
        pcpPracticeList: [],
        maritalStatusList: [],
      },
    },
  },
  addEditTask: { allTaskType: [], questions: [] },
  clientId: -1,
  patientEpisodeStatus: {
    intakeStatuses: [],
    intakeStatus: { name: "", key: -1 },
    statusChangeReasons: [],
    statusChangeReason: { name: "", key: -1 },
    statusChangeNote: "",
  },
  isPatientStatusPopupLoading: false,
};

const episodesSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetEpisodesState: (state: any) => initialState,
    resetEpisodesList: (state: any) => {
      state.episodes = [];
    },
    resetEpisodeDetailsTasksList: (state) => {
      state.episodeDetailTabs.tasks = [];
    },
    setProcedureDetails: (state, action) => {
      const key: keyof IProcedureDetailType = action.payload.key;
      if (state.episodeDetailTabs.procedureDetails.details) {
        state.episodeDetailTabs.procedureDetails.details[key] = {
          ...state.episodeDetailTabs.procedureDetails.details[key],
          ...action.payload.data,
        };
      }
      state.episodeDetailTabs.procedureDetails.isDirty = action.payload?.isDirty ? action.payload.isDirty : false;
    },
    setIsDirty: (state, action) => {
      state.episodeDetailTabs.procedureDetails.isDirty = action.payload;
    },
    updateProcedureDetailsValues: (state, action) => {
      state.episodeDetailTabs.procedureDetails.details = action.payload;
      state.episodeDetailTabs.procedureDetails.isDirty = true;
    },
    setPatientEpisodeDetails: (state, action) => {
      state.patientEpisodeDetails = { ...action.payload };
    },
    resetProcedureDetails: (state) => {
      state.episodeDetailTabs.procedureDetails.details = SURGEON_EPISODE_DETAILS_FORM;
    },
    setEpisodeDetails(state, action) {
      state.episodeDetails = action.payload;
    },
    resetEpisodeDetailsActvityList: (state) => {
      state.episodeDetailTabs.activityHistory = [];
    },
    setIsActivityFilterApplied: (state, action) => {
      state.isActivityFilterApplied = action.payload;
    },
    setDemographicDetails: (state, action: PayloadAction<DemoGraphicDetailsType>) => {
      state.episodeDetailTabs.patientDemographicsDetails.demographicDetails = action.payload;
    },
    clearDuplicateErrors: (state, action) => {
      state.episodeDetailTabs.patientDemographicsDetails.duplicateDataErrors = action.payload;
    },
    setPatientEpisodeStatus(state, action) {
      state.patientEpisodeStatus.intakeStatus = action.payload;
    },
    setStatusChangeReason(state, action) {
      state.patientEpisodeStatus.statusChangeReason = action.payload;
    },
    resetPatientStatus(state) {
      state.patientEpisodeStatus.intakeStatus = { name: "", key: -1 };
      state.patientEpisodeStatus.statusChangeReason = { name: "", key: -1 };
    },
    resetPlanofCareList: (state) => {
      state.isLoadingEpisodeDetailTabs.planOfCare = true;
      state.episodeDetailTabs.planOfCare = [];
    },
    resetEpisodeAndPatientEpisodeDetails: (state) => {
      state.episodeDetails = null;
      state.patientEpisodeDetails = null;
    },
    setPatientDeclinedNavigationEpisode: (
      state,
      action: PayloadAction<{ patientNavigationDeclined: boolean; patientNavigationDeclinedNotes: string | null }>
    ) => {
      if (state.episodeDetails) {
        state.episodeDetails = { ...state.episodeDetails, ...action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(getEpisodesAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(getEpisodesAsync.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(getEpisodesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.episodes = action.meta.arg.offset === 0 ? action.payload : [...state.episodes, ...action.payload];
      }),
      builder.addCase(getEpisodesFilterStatusMenuAsync.pending, (state, action) => {
        state.isLoadingEpisodeFilterStatus = true;
        state.isError = false;
      }),
      builder.addCase(getEpisodesFilterStatusMenuAsync.fulfilled, (state, action) => {
        state.isLoadingEpisodeFilterStatus = false;
        state.isError = false;
      }),
      builder.addCase(getEpisodesFilterStatusMenuAsync.rejected, (state, action) => {
        state.isLoadingEpisodeFilterStatus = false;
        state.isError = false;
      }),
      builder.addCase(getEpisodeDetailsById.pending, (state) => {
        state.isLoadingEpisodeDetails = true;
        state.isError = false;
      }),
      builder.addCase(getEpisodeDetailsById.rejected, (state) => {
        state.isLoadingEpisodeDetails = false;
        state.isError = true;
      }),
      builder.addCase(getEpisodeDetailsById.fulfilled, (state, action) => {
        state.isLoadingEpisodeDetails = false;
        state.isError = false;
        if (action.payload) {
          state.episodeDetails = { ...action.payload, patientLastLoginAt: null };
          state.patientEpisodeDetails = createPatientEpisodeDetailsData(action.payload);
          state.patientEpisodeStatus.intakeStatus = { name: action.payload.status, key: action.payload.intakeStatusId };
          state.patientEpisodeStatus.statusChangeNote = action.payload.intakeNotes ? action.payload.intakeNotes : "";
        }
      }),
      builder.addCase(getEpisodeTasksAsync.pending, (state, action) => {
        state.isLoadingEpisodeDetailTabs.tasks = true;
      }),
      builder.addCase(getEpisodeTasksAsync.fulfilled, (state, action) => {
        state.isLoadingEpisodeDetailTabs.tasks = false;
        state.isError = false;
        state.episodeDetailTabs.tasks = action.payload;
      }),
      builder.addCase(getEpisodeTasksAsync.rejected, (state, action) => {
        state.isLoadingEpisodeDetailTabs.tasks = false;
        state.isError = true;
      }),
      builder.addCase(getManualTaskTypeAsync.pending, (state, action: any) => {}),
      builder.addCase(getManualTaskTypeAsync.fulfilled, (state, action: any) => {
        state.addEditTask.allTaskType = action.payload;
      }),
      builder.addCase(getManualTaskTypeAsync.rejected, (state, action: any) => {
        state.isError = false;
      }),
      builder.addCase(getManualFieldTaskAsync.pending, (state, action: any) => {}),
      builder.addCase(getManualFieldTaskAsync.fulfilled, (state, action) => {
        state.addEditTask.questions = [...action.payload];
      }),
      builder.addCase(getManualFieldTaskAsync.rejected, (state, action: any) => {}),
      builder.addCase(getEpisodeTasksExistAsync.pending, (state, action: any) => {
        state.isLoadingNewIntakeCreation = true;
      }),
      builder.addCase(getEpisodeTasksExistAsync.fulfilled, (state, action: any) => {
        state.isLoadingNewIntakeCreation = false;
        state.isNewIntakeCreation = action.payload?.value;
        state.clientId = action.payload.clientId;
      }),
      builder.addCase(getEpisodeTasksExistAsync.rejected, (state, action: any) => {
        state.isLoadingNewIntakeCreation = false;
      }),
      builder.addCase(fetchManualTaskAsync.pending, (state, action) => {
        state.isEpisodeReminderTaskExist = false;
      }),
      builder.addCase(fetchManualTaskAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.isEpisodeReminderTaskExist = true;
        } else {
          state.isEpisodeReminderTaskExist = true;
        }
      }),
      builder.addCase(fetchManualTaskAsync.rejected, (state, action) => {
        state.isEpisodeReminderTaskExist = false;
      }),
      builder.addCase(getPreferredPhoneNumberIdAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.episodeDetailTabs.procedureDetails.metadata.preferredPhones = action.payload;
      }),
      builder.addCase(getSurgerySiteSideAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.episodeDetailTabs.procedureDetails.metadata.surgerySiteSides = action.payload.map((site: any) => {
          return {
            key: site.ID,
            name: `${site.SurgerySite} - ${site.SurgerySide}`,
          };
        });
      }),
      builder.addCase(getAllEpisodesAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.episodeDetailTabs.procedureDetails.metadata.episodes = action.payload.map((episode: any) => {
          return {
            key: `${episode.ID}:${episode.SurgeryCategoryID}`,
            name: episode.LongName,
            value: episode.EpisodeTypeID,
          };
        });
      }),
      builder.addCase(getAllSurgeonsAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.episodeDetailTabs.procedureDetails.metadata.surgeons = action.payload
          .filter((surgeon: any) => {
            return surgeon.FirstName !== null;
          })
          .map((surgeon: any) => {
            const { ID, FirstName, LastName, NPI, PracticeId } = surgeon;
            return {
              key: ID,
              name: `${FirstName ?? ""} ${LastName ?? ""}`,
              PracticeId: PracticeId ?? "",
              NPI,
            };
          });
      }),
      builder.addCase(getAllFacilitiesAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.episodeDetailTabs.procedureDetails.metadata.facilities = action.payload.map((facility: any) => {
          return {
            key: facility.ID,
            name: facility.ProviderName,
          };
        });
      }),
      builder.addCase(getAllPracticesAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.episodeDetailTabs.procedureDetails.metadata.practices = action.payload.map((practice: any) => {
          return {
            key: practice.ID,
            name: practice.LongName,
          };
        });
      }),
      builder.addCase(getAllProgramsAsync.fulfilled, (state: any, action) => {
        state.isLoading = true;
        state.isError = false;
        state.episodeDetailTabs.procedureDetails.metadata.programs = action.payload.map((program: any) => {
          return {
            key: program.ID,
            name: program.Name,
          };
        });
      }),
      builder.addCase(updateEpisodeAsync.pending, (state) => {
        state.isLoadingProcedureDetailsSubmit = true;
      }),
      builder.addCase(updateEpisodeAsync.fulfilled, (state) => {
        state.isLoadingProcedureDetailsSubmit = false;
        state.isError = false;
        toast.success("Episode updated successfully.", {
          toastId: "update-episode",
        });
      }),
      builder.addCase(updateEpisodeAsync.rejected, (state, action: any) => {
        state.isLoadingProcedureDetailsSubmit = false;
        state.isError = true;
        toast.error(action.payload, {
          toastId: "create-patient-error",
        });
      }),
      builder.addCase(getEpisodeDetailsByIntakeIdAsync.pending, (state: any) => {
        state.isLoading = true;
      }),
      builder.addCase(getEpisodeDetailsByIntakeIdAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(getEpisodeDetailsByIntakeIdAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.isError = false;
        if (action.payload) {
          state.existingEpisodeDetails = action.payload;
        }
      }),
      builder.addCase(getIntakeStatuses.fulfilled, (state, action) => {
        state.intakeStatuses = action.payload;
        const filteredIntakeStatus = action.payload.filter((item: { key: string; name: string; id: string }) => {
          return ![PatientEpisodeStatus.PARTIAL_INTAKE, PatientEpisodeStatus.CLOSED].includes(item.name);
        });
        const status = action.payload.find(
          (item: IntakeStatus) => item.name.toLowerCase() === state.patientEpisodeDetails?.status.toLowerCase()
        );
        if (state.patientEpisodeDetails?.status !== PatientEpisodeStatus.NEW) {
          const newIntakeStatuses = filteredIntakeStatus.filter((item: IntakeStatus) => {
            return item.name !== "New";
          });
          state.patientEpisodeStatus.intakeStatuses = newIntakeStatuses;
          if (state.patientEpisodeDetails?.status) {
            state.patientEpisodeStatus.intakeStatus = { name: status.name, key: status.key };
          }
        } else {
          state.patientEpisodeStatus.intakeStatuses = filteredIntakeStatus;
        }
      }),
      builder.addCase(getActvityLogAsync.pending, (state, action) => {
        state.isLoadingEpisodeDetailTabs.activityHistory = true;
      }),
      builder.addCase(getActvityLogAsync.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoadingEpisodeDetailTabs.activityHistory = false;
        const activityLog = action.payload.map((activity: IActivityHistoryLog) => {
          const log = { ...activity, status: "-" };
          if (activity?.taskStatusId) {
            if (activity.activityLogType === TaskType.EPISODE_STATUS_CHANGE) {
              const status = state.intakeStatuses.find(
                (intakeStatus) => intakeStatus.key === activity.taskStatusId?.toString()
              );
              log.status = status && status?.name ? status.name : "-";
            } else if (activity.activityLogType === TaskType.TASK_DELAY) {
              const details = activity.details as IDelayedLog;
              if (details?.status && !isEmptyString(details?.status)) {
                log.status = details.status;
              } else {
                log.status = activity.taskStatusId === TaskStatus.CLOSED ? "Closed" : "Delayed";
              }
            } else if (
              activity?.details &&
              (activity.activityLogType === TaskType.TASK_CLOSURE_APPROVED ||
                activity.activityLogType === TaskType.TASK_CLOSURE_REJECTED)
            ) {
              const details = activity?.details as ITaskClosureAction;
              log.status = details?.taskStatus ?? "";
            } else {
              log.status = getTaskNameByTaskStatusId(activity.taskStatusId, activity.activityLogType === TaskType.TOC);
            }
          } else if (activity.activityLogType === TaskType.CALL) {
            const details = activity.details as ICallLog;
            log.status = details.callStatus && !isEmptyString(details.callStatus) ? details.callStatus : "-";
          }
          return log;
        });
        state.episodeDetailTabs.activityHistory = [...state.episodeDetailTabs.activityHistory, ...activityLog];
      }),
      builder.addCase(getActvityLogAsync.rejected, (state, action) => {
        state.isLoadingEpisodeDetailTabs.activityHistory = false;
        state.isError = false;
      }),
      builder.addCase(getDemographicDataAsync.fulfilled, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.demographicDetails = action.payload;
      }),
      builder.addCase(getDemographicDataAsync.rejected, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.demographicError = action.error.message;
      }),
      builder.addCase(getPrimaryCarePhysiciansync.fulfilled, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.dropdownsData.primaryCarePhysicianList = action.payload;
      }),
      builder.addCase(getPrimaryCarePhysiciansync.rejected, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.dropdownsData.dropDownerror = action.error.message;
      }),
      builder.addCase(getPracticeAsync.fulfilled, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.dropdownsData.pcpPracticeList = action.payload;
      }),
      builder.addCase(getPracticeAsync.rejected, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.dropdownsData.dropDownerror = action.error.message;
      }),
      builder.addCase(getGeneralCategoryAsync.fulfilled, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.dropdownsData.coachRelationshipList =
          action.payload.coachRelationshipList;
        state.episodeDetailTabs.patientDemographicsDetails.dropdownsData.maritalStatusList =
          action.payload.maritalStatusList;
      }),
      builder.addCase(getGeneralCategoryAsync.rejected, (state, action) => {
        state.episodeDetailTabs.patientDemographicsDetails.dropdownsData.dropDownerror = action.error.message;
      }),
      builder.addCase(postDemographicsDataAsync.fulfilled, (state, action: PayloadAction<DemoGraphicDetailsType>) => {
        state.episodeDetailTabs.patientDemographicsDetails.demographicDetails = action.payload;
        toast.success(SavedSuccessfully, {
          containerId: "main",
        });
      }),
      builder.addCase(postDemographicsDataAsync.rejected, (state, action: PayloadAction<any>) => {
        const errorList = action.payload.response.data;
        state.episodeDetailTabs.patientDemographicsDetails.duplicateDataErrors = errorList;
        const arr: ErrorListType[] = errorList;
        for (const item of arr) {
          toast.error(item.message, {
            containerId: "main",
          });
        }
      }),
      builder.addCase(getStatusChangeReason.fulfilled, (state, action) => {
        state.patientEpisodeStatus.statusChangeReasons = action.payload;
      }),
      builder.addCase(postPatientEpisodeStatus.rejected, () => {
        toast.error("Something went wrong", {
          toastId: "patient-episode-error",
          containerId: "main",
        });
      }),
      builder.addCase(postPatientEpisodeStatus.fulfilled, (state) => {
        if (state.patientEpisodeStatus.intakeStatus.name !== "New") {
          const newIntakeStatuses = state.patientEpisodeStatus.intakeStatuses.filter((item) => {
            return item.name !== "New";
          });
          state.patientEpisodeStatus.intakeStatuses = newIntakeStatuses;
        }
        toast.success(PatientEpisodeStatusSuccessMessage, {
          toastId: "patient-episode-status-modal",
          containerId: "main",
        });
      }),
      builder.addCase(getPlanOfCareListAsync.pending, (state, action) => {
        state.isLoadingEpisodeDetailTabs.planOfCare = true;
      }),
      builder.addCase(getPlanOfCareListAsync.fulfilled, (state, action) => {
        state.isLoadingEpisodeDetailTabs.planOfCare = false;
        state.episodeDetailTabs.planOfCare = action.payload;
      }),
      builder.addCase(getPlanOfCareListAsync.rejected, (state, action) => {
        state.isLoadingEpisodeDetailTabs.planOfCare = false;
      }),
      builder.addCase(
        getProvidersReferrerListAsync.fulfilled,
        (state, action: PayloadAction<Array<GetProvidersReferrerListResponse>>) => {
          state.episodeDetailTabs.procedureDetails.metadata.referrals = action.payload;
        }
      ),
      builder.addCase(getPatientStatusById.pending, (state, action) => {
        state.isPatientStatusPopupLoading = true;
      }),
      builder.addCase(getPatientStatusById.fulfilled, (state, action) => {
        if (state.patientEpisodeDetails && action.payload) {
          state.patientEpisodeDetails = {
            ...state.patientEpisodeDetails,
            statusChangeReasonId: action.payload.statusChangeReasonId,
            status: action.payload.status,
          };
        }
        state.isPatientStatusPopupLoading = false;
      }),
      builder.addCase(getEpisodePatientLastLoginAsync.fulfilled, (state, action) => {
        if (state.episodeDetails) {
          state.episodeDetails.patientLastLoginAt = action.payload;
        }
      })
    );
  },
});

export default episodesSlice;
export const {
  resetEpisodesState,
  resetEpisodesList,
  resetEpisodeDetailsTasksList,
  setProcedureDetails,
  setIsDirty,
  updateProcedureDetailsValues,
  setPatientEpisodeDetails,
  resetProcedureDetails,
  setEpisodeDetails,
  resetEpisodeDetailsActvityList,
  setIsActivityFilterApplied,
  setDemographicDetails,
  clearDuplicateErrors,
  setPatientEpisodeStatus,
  setStatusChangeReason,
  resetPatientStatus,
  resetPlanofCareList,
  resetEpisodeAndPatientEpisodeDetails,
  setPatientDeclinedNavigationEpisode,
} = episodesSlice.actions;
export const getEpisodesSlice = (state: any): IEpisodeState => state.episodes;
