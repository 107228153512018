import { ContactDetail } from "state/types/task-management-slice.type";
import "./call-participants-dropdown.styles.scss";
import { checkPhoneIsCorrectFormat, isEmptyString } from "shared/methods/utilityFunctions";

interface ICallParticipantsDropdown {
  contactDetails: Array<ContactDetail>;
  id: string;
  patient: string;
  className?: string;
  onClick: (el: ContactDetail) => void;
}
const CallParticipantsDropdown = (participant: ICallParticipantsDropdown) => {
  const { contactDetails, id, className, onClick } = participant;
  return (
    <div id={id} className={`phone-numbers-container ${className ?? ""}`}>
      {contactDetails.length ? (
        contactDetails.map((contact) => {
          const { name, isPreferred, type, phoneNumber } = contact;
          const { isNumberNan, value } = checkPhoneIsCorrectFormat(phoneNumber, true);
          return (
            <div
              className={`phone-number-details ${isPreferred ? "highlighted" : ""}`}
              onClick={() => onClick(contact)}
              key={phoneNumber}
            >
              <div className="name">
                <div className="name-text">{name ?? "No Name"}</div>
                {phoneNumber && value && !isEmptyString(value) ? <div className="phone-number">{value}</div> : null}
              </div>
              <div className="label-right">
                <label className="label">
                  {type}
                  {isPreferred ? <span> (preferred)</span> : null}
                </label>
                {isNumberNan && <div className="invalid-number-label">Invalid Number</div>}
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-results">No Contacts Found</div>
      )}
    </div>
  );
};

export default CallParticipantsDropdown;
