import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getEpisodesSlice,
  setDemographicDetails,
  setPatientEpisodeDetails,
} from "state/feature/episodes/episodes.slice";
import PatientDemographicsPresentation from "./patient-demographics.component";
import {
  getDemographicDataAsync,
  getGeneralCategoryAsync,
  getPracticeAsync,
  getPrimaryCarePhysiciansync,
  postDemographicsDataAsync,
} from "state/feature/episodes/episodes.action";
import { useAppDispatch } from "state/store";
import { DemoGraphicDetailsType, IPatientEpisodeDetails } from "state/types/episodes-slice.type";
import { genderDropdownItems } from "shared/constant/commonConstants";

const PatientDemographics = () => {
  const appDispatch = useAppDispatch();
  const { episodeDetails, episodeDetailTabs, patientEpisodeDetails } = useSelector(getEpisodesSlice);
  const { patientDemographicsDetails } = episodeDetailTabs;
  const { dropdownsData, demographicDetails, duplicateDataErrors } = patientDemographicsDetails;
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const saveChanges = async (form: DemoGraphicDetailsType | undefined) => {
    try {
      setIsBtnLoading(true);
      await appDispatch(postDemographicsDataAsync(form as DemoGraphicDetailsType));
      const updatedDetails: IPatientEpisodeDetails = { ...patientEpisodeDetails! };
      updatedDetails.patientFirstName = form!.firstName;
      updatedDetails.patientMiddleName = form!.middleName;
      updatedDetails.patientLastName = form!.lastName;
      updatedDetails.gender = genderDropdownItems.find((i) => i.key === form!.genderId)!.name!;
      updatedDetails.dob = form!.dob;
      updatedDetails.patientName = `${form?.firstName} ${form?.lastName}`;
      appDispatch(setPatientEpisodeDetails({ ...updatedDetails }));
    } finally {
      setIsBtnLoading(false);
    }
  };

  const getDemographicDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      await appDispatch(getPrimaryCarePhysiciansync({ clientId: episodeDetails?.clientId.toString() as string }));
      await appDispatch(getPracticeAsync({ clientId: episodeDetails?.clientId.toString() as string }));
      await appDispatch(getGeneralCategoryAsync());
      await appDispatch(getDemographicDataAsync({ patientId: episodeDetails?.patientId.toString() as string }));
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, episodeDetails]);

  useEffect(() => {
    getDemographicDetails();
  }, [getDemographicDetails]);

  useEffect(() => {
    return () => {
      appDispatch(setDemographicDetails({} as DemoGraphicDetailsType));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PatientDemographicsPresentation
      isBtnLoading={isBtnLoading}
      dropdownData={dropdownsData}
      demographicDetails={demographicDetails}
      saveChanges={saveChanges}
      isLoading={isLoading}
      duplicateDataErrors={duplicateDataErrors}
    />
  );
};

export default PatientDemographics;
