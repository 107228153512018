import React, { useCallback, useEffect, useMemo, useState } from "react";

import Header from "components/Header/header.component";
import Button from "components/Button/button.component";
import ProfilePicker from "components/ProfilePicker/profile-picker.component";
import TextField from "components/TextField/text-field.component";
import "./profile.styles.scss";
import { useSelector } from "react-redux";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import { DROP_DOWN_ITEMS, TIME_ZONE_DROPDOWN_ITEMS, WORK_HOUR_DROPDOWN_ITEMS } from "shared/constant/dropDown";
import Toggle from "components/Toggle/toggle.component";
import DatePicker from "components/DatePicker/date-picker.component";
import { useLocation, useHistory } from "react-router";
import Breadcrumb from "components/Breadcrumb/breadcrumb.component";
import {
  validateEmail,
  isEmptyString,
  getUTCDateAndAdd12hrs,
  checkPhoneIsCorrectFormat,
  getNumberFromString,
} from "shared/methods/utilityFunctions";
import { Range } from "react-date-range";
import moment from "moment";
import { getAuth } from "state/feature/auth/auth.slice";
import {
  getNavigatorState,
  resetToInitialState,
  setIsProfileValueChanged,
} from "state/feature/navigator/navigator.slice";
import {
  createNavigatorAsync,
  getAssignPhoneNumberListAsync,
  getBackUpNavigatorListAsync,
  getNavigatorDetailsAsync,
  updateNavigatorAsync,
} from "state/feature/navigator/navigator.action";
import { useAppDispatch } from "state/store";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { IAssignedPhoneNumbers } from "state/types/navigator-slice.type";
import TagsSearchDropdown from "components/TagsSearchDropdown/tags-search-dropdown.component";
import { ITagsSearchDropdownType } from "shared/types/dropdown.type";
import { BrowserView, MobileView } from "react-device-detect";
import { Link } from "react-router-dom";
import Icon from "components/Icon/icon.component";
import { TagsSearchDropdownUseCase } from "components/TagsSearchDropdown/tags-search-dropdown.enum";
import ButtonLoader from "components/ButtonLoader/button-loader.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { BACKUP_NAVIGATOR_LIMIT } from "shared/constant/commonConstants";
import useUrlBlocking from "shared/hooks/use-blocking.hook";
import SingleSelectDropdownWithClearOption from "components/single-select-dropdown-with-clear-option/single-select-dropdown-with-clear-option.component";
import { DropdownType } from "pages/user-management/user-management-details/physician-form-details/physician-form-details.types";
import { NavigatorTypeKey, ToggleState } from "./profile.enum";
import PhoneInputWithDash from "components/phone-input-with-dashes/phone-input-with-dashes";
import { isPhoneNumberValid } from "shared/methods/taskSpecificMethods";

const Profile = () => {
  useAxiosAuthenticated();
  const { isLoading, user } = useSelector(getAuth);
  const {
    isLoading: isNavigatorLoading,
    isLoadingAssignedClients,
    isLoadingBackupNavigatorList,
    isLoadingNavigatorDetails,
    profile,
    navigatorType,
    backupNavigators,
    assignedPhoneNumbers,
    currentUserProfile,
  } = useSelector(getNavigatorState);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  useUrlBlocking();
  const state = location?.state as any;
  const pageName = state?.pageName;
  const isNewNavigator = pageName !== null && pageName === "New Navigator";
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [initDateState, setInitDateState] = useState<any>(null);
  const [isNavigatorManager, setIsNavigatorManager] = useState<boolean>(false);

  const [inputFieldValues, setInputFieldValues] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    phone: "",
  });
  const [validationValues, setValidationValues] = useState({
    firstName: true,
    lastName: true,
    userName: true,
    email: true,
    phone: true,
    navigatorType: true,
    timeZone: true,
  });
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [navigatorTypeOptionDetails, setNavigatorTypeOptionDetails] = useState({
    key: "navigator-type",
    name: "Navigator Type",
  });
  const [activeNavigator, setActiveNavigator] = useState("");
  const [onOffToogleTitle, setOnOffToogleTitle] = useState("OFF");
  const [timeZoneOptionDetails, setTimeZoneOptionDetails] = useState({
    key: "Central",
    name: "Central Time",
  });
  const [backUpNavigatorList, setBackUpNavigatorList] = useState<{ key: string; name: string }[]>([]);
  const [assignedPhoneNumberOptions, setAssignedPhoneNumberOptions] = useState<any[]>([]);
  const [selectedBackupNavigatorList, setSelectedBackupNavigatorList] = useState<any[]>([]);
  const [selectedAssignedPhoneList, setSelectedAssignPhoneList] = useState<any[]>([]);
  const [selectedProxyUser, setSelectedProxyUser] = useState<DropdownType>({ key: "", name: "" });
  const [isDirty, setIsDirty] = useState(false);
  const [isPhoneValueChanged, setisPhoneValueChanged] = useState(false);

  const [dateRangeState, setDateRangeState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [workHourState, setWorkHourState] = useState({
    from: { key: "6:30", name: "6:30", amPm: "AM" },
    to: { key: "9:30", name: "9:30", amPm: "PM" },
  });

  const convertNameForWork = (val: number, type: string) => {
    if (type === "hour") {
      const newVal = `${val % 12}`.length === 1 ? `0${val % 12}` : val % 12;
      return val === 0 || val === 12 || val == 24 ? "12" : newVal;
    } else {
      return val === 0 ? "00" : val;
    }
  };

  const isPhoneInvalid = useMemo(() => {
    const { isNumberNan } = checkPhoneIsCorrectFormat(profile?.forwardingPhoneNumber ?? "");
    return isNumberNan;
  }, [profile?.forwardingPhoneNumber]);

  const getNavigatorProfileDetails = useCallback(async () => {
    if (user?.id) {
      const navigatorManagerCareId = sessionStorage.getItem("navigatorCareManagerId");
      const navigatorId = isNewNavigator
        ? user?.id
        : pageName === undefined
        ? undefined
        : navigatorManagerCareId
        ? navigatorManagerCareId
        : undefined;
      dispatch(getNavigatorDetailsAsync({ navigatorId: navigatorId }));
    }
  }, [isNewNavigator, user?.id, dispatch, pageName]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("navigatorProfileId");
    };
  }, []);

  useEffect(() => {
    if (!isNewNavigator) {
      getNavigatorProfileDetails();
    }
  }, [isNewNavigator, getNavigatorProfileDetails]);

  useEffect(() => {
    if (navigatorType !== null && !isNewNavigator) {
      dispatch(getBackUpNavigatorListAsync({ types: [navigatorType === 1 ? "TNAV" : "PNAV"] }));
      dispatch(getAssignPhoneNumberListAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigatorType, dispatch]);

  useEffect(() => {
    if (profile !== null) {
      const { value: number } = checkPhoneIsCorrectFormat(profile?.forwardingPhoneNumber ?? "");
      setInputFieldValues((prev) => {
        return {
          ...prev,
          firstName: profile?.firstName ? profile.firstName : "",
          lastName: profile?.lastName ? profile.lastName : "",
          userName: profile?.userName ? profile.userName : "",
          email: profile?.email ? profile.email : "",
          phone: number,
        };
      });
      const newAssignPhoneList = profile?.assignedClients
        ? profile.assignedClients.map((numbers: IAssignedPhoneNumbers) => {
            const { value: whiteLabelPhoneNumber } = checkPhoneIsCorrectFormat(
              numbers?.whiteLabelPhoneNumber ?? "",
              true
            );
            return {
              key: numbers.id,
              name: numbers.name,
              value: whiteLabelPhoneNumber,
              isDisabled: false,
            };
          })
        : [];
      setSelectedAssignPhoneList(newAssignPhoneList);
      setNavigatorTypeOptionDetails({
        key: profile.navigatorType === 1 ? NavigatorTypeKey.TRANSITION : NavigatorTypeKey.PATIENT,
        name: profile.navigatorType === 1 ? "Transition" : "Patient",
      });
      setActiveNavigator(profile.isActive ? "Active" : "Inactive");
      setTimeZoneOptionDetails({
        key: profile.timeZone,
        name: profile.timeZone ? profile.timeZone + " Time" : "",
      });
      setOnOffToogleTitle(profile.isOnVacation ? "ON" : "OFF");
      setInitDateState([
        {
          startDate: new Date(profile?.vacationBeginDate ?? moment().add(1, "day").toDate()),
          endDate: new Date(profile?.vacationEndDate ?? moment().add(7, "day").toDate()),
          key: "selection",
        },
      ]);
      const workHourStartValue =
        profile?.workStartHour === null
          ? { name: "9:30", key: "9:30", amPm: "AM" }
          : {
              name: `${convertNameForWork(profile.workStartHour, "hour")}:${convertNameForWork(
                profile.workStartMinute,
                "minute"
              )}`,
              key: `${profile.workStartHour % 12}:${profile.workStartMinute}`,
              amPm: "",
            };
      const workHourEndValue =
        profile?.workEndHour === null
          ? { name: "6:30", key: "18:30", amPm: "PM" }
          : {
              name: `${convertNameForWork(profile.workEndHour, "hour")}:${convertNameForWork(
                profile.workEndMinute,
                "minute"
              )}`,
              key: `${profile.workEndHour === 24 ? 0 : profile.workEndHour % 12}:${profile.workEndMinute}`,
              amPm: "",
            };
      workHourStartValue.amPm = profile?.workStartHour >= 12 ? "PM" : "AM";
      workHourEndValue.amPm = profile?.workEndHour !== 24 && profile?.workEndHour >= 12 ? "PM" : "AM";
      setWorkHourState({
        from: workHourStartValue,
        to: workHourEndValue,
      });
      setDateRangeState([
        {
          startDate: new Date(profile?.vacationBeginDate ?? moment().add(1, "day").toDate()),
          endDate: new Date(profile?.vacationEndDate ?? moment().add(7, "day").toDate()),
          key: "selection",
        },
      ]);
    }
  }, [profile]);

  useEffect(() => {
    if (
      currentUserProfile !== null &&
      backupNavigators !== null &&
      backupNavigators.length &&
      profile !== null &&
      !isNewNavigator
    ) {
      const dataForBackUpNavigatorList = backupNavigators
        .filter((ele: any) => ele.id !== profile.id)
        .map((elem: any) => ({
          key: elem?.id,
          name: `${elem?.firstName} ${elem?.lastName}`,
        }));

      const newBackupList = dataForBackUpNavigatorList.filter((obj) => profile?.backupNavigatorIds?.includes(obj.key));
      setSelectedBackupNavigatorList(newBackupList);
      setBackUpNavigatorList(dataForBackUpNavigatorList);
      if (profile.navigatorType == 1 && profile.proxyNavigatorId) {
        const newSelectedProxyTnav = dataForBackUpNavigatorList.find((nav) => nav.key === profile.proxyNavigatorId);
        if (newSelectedProxyTnav) {
          setSelectedProxyUser(newSelectedProxyTnav);
        }
      } else {
        setSelectedProxyUser({ key: "", name: "" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backupNavigators, profile, currentUserProfile]);

  useEffect(() => {
    if (user.roles && user.roles.length) {
      const isManager = user?.roles?.includes("Navigator Manager");
      setIsNavigatorManager(isManager);
    }
  }, [user.roles]);

  useEffect(() => {
    if (assignedPhoneNumbers != null) {
      const dataforAssignedNavigatorOption = assignedPhoneNumbers.map((obj) => {
        return {
          key: obj.id,
          name: obj.name,
          value: obj.whiteLabelPhoneNumber,
          isDisabled: selectedAssignedPhoneList.some((val) => val?.key === obj.id) ? false : obj?.assignedTo !== null,
        };
      });

      setAssignedPhoneNumberOptions(dataforAssignedNavigatorOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedPhoneNumbers]);

  useEffect(() => {
    return () => {
      dispatch(setIsProfileValueChanged(false));
    };
  }, [dispatch]);

  const handleApplyClick = (item: any) => {
    const updatedItem = item;
    updatedItem[0].key = "updated";
    setDateRangeState(updatedItem);
    setDatePickerOpen(false);
  };

  const onToggleOnOff = () => {
    setIsSaveButtonDisabled(false);
    setOnOffToogleTitle(onOffToogleTitle === ToggleState.OFF ? ToggleState.ON : ToggleState.OFF);
    dispatch(setIsProfileValueChanged(true));
  };
  const validateEmailInput = (email: string) => {
    const isValidEmail = validateEmail(email);
    setValidationValues({ ...validationValues, email: isValidEmail });
  };
  const validatePhoneInput = (phone: string) => {
    const isValidPhone = isPhoneNumberValid(phone);
    setValidationValues({ ...validationValues, phone: isValidPhone });
  };
  const validateInputFields = (type: keyof typeof inputFieldValues): void => {
    const fieldValue = inputFieldValues[type];
    setValidationValues({
      ...validationValues,
      [type]: !isEmptyString(fieldValue),
    });
  };
  const validateNavigator = (option: string): void => {
    const checkNavigator = option != "navigator-type";
    setValidationValues({ ...validationValues, navigatorType: checkNavigator });
  };

  const validateTimeZone = (option: string): void => {
    const checkTimeZone = option != null;
    setValidationValues({ ...validationValues, timeZone: checkTimeZone });
  };

  const onChangeInputField = (type: string, value: string) => {
    setIsSaveButtonDisabled(false);
    switch (type) {
      case "First Name":
        setValidationValues({ ...validationValues, firstName: true });
        setInputFieldValues({ ...inputFieldValues, firstName: value });
        break;
      case "Last Name":
        setValidationValues({ ...validationValues, lastName: true });
        setInputFieldValues({ ...inputFieldValues, lastName: value });
        break;
      case "Email Address":
        validateEmailInput(value);
        setValidationValues({ ...validationValues, email: true });
        setInputFieldValues({ ...inputFieldValues, email: value });
        break;
      case "Phone":
        setisPhoneValueChanged(true);
        setInputFieldValues({ ...inputFieldValues, phone: value });
        break;
      default:
        break;
    }
    dispatch(setIsProfileValueChanged(true));
  };

  const handleOptionChange = (option: any) => {
    validateNavigator(option);
    setIsSaveButtonDisabled(false);
    setNavigatorTypeOptionDetails(option);
    if (option.key !== NavigatorTypeKey.TRANSITION) {
      setSelectedProxyUser({ key: "", name: "" });
    }
    dispatch(setIsProfileValueChanged(true));
  };

  const handleTimeZoneChange = (option: any) => {
    validateTimeZone(option);
    setIsSaveButtonDisabled(false);
    setTimeZoneOptionDetails(option);
    dispatch(setIsProfileValueChanged(true));
  };

  const getNavigatorTypeId = () => {
    if (navigatorTypeOptionDetails.key === NavigatorTypeKey.TRANSITION) {
      return 1;
    } else if (navigatorTypeOptionDetails.key === NavigatorTypeKey.PATIENT) {
      return 0;
    }

    return 0;
  };

  const checkAllFieldsAreValid = () => {
    const isPhoneValid = isPhoneNumberValid(inputFieldValues.phone);
    setValidationValues({
      ...validationValues,
      firstName: !isEmptyString(inputFieldValues.firstName),
      lastName: !isEmptyString(inputFieldValues.lastName),
      userName: !isEmptyString(inputFieldValues.userName),
      email: !isEmptyString(inputFieldValues.email) && validateEmail(inputFieldValues.email),
      phone: isPhoneValid,
      navigatorType: navigatorTypeOptionDetails.key !== "navigator-type",
      timeZone: timeZoneOptionDetails.key !== null,
    });
    return (
      !isEmptyString(inputFieldValues.firstName) &&
      !isEmptyString(inputFieldValues.lastName) &&
      !isEmptyString(inputFieldValues.userName) &&
      !isEmptyString(inputFieldValues.email) &&
      validateEmail(inputFieldValues.email) &&
      !isEmptyString(inputFieldValues.phone) &&
      isPhoneValid &&
      navigatorTypeOptionDetails.key !== "navigator-type" &&
      timeZoneOptionDetails.key !== null
    );
  };

  const clearAllInputFields = () => {
    setInputFieldValues({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      phone: "",
    });
  };
  const getSaveCreateDisabledValue = () => {
    if (isNewNavigator) {
      return !(
        !isEmptyString(inputFieldValues.firstName) &&
        !isEmptyString(inputFieldValues.lastName) &&
        !isEmptyString(inputFieldValues.email) &&
        validateEmail(inputFieldValues.email) &&
        isPhoneNumberValid(inputFieldValues.phone) &&
        navigatorTypeOptionDetails.key !== "navigator-type" &&
        timeZoneOptionDetails.key !== null
      );
    } else {
      return isSaveButtonDisabled;
    }
  };

  const onSaveChanges = async () => {
    setIsDirty(true);
    const isValid = !getSaveCreateDisabledValue();
    if (isValid) {
      const startWorkArr = workHourState.from?.key?.split(":");
      const endWorkArr = workHourState.to?.key?.split(":");
      const startWorkHour =
        workHourState.from?.amPm === "PM" ? (parseInt(startWorkArr[0]) + 12) % 24 : parseInt(startWorkArr[0]);
      const endWorkHour =
        workHourState.to?.amPm === "PM" ? (parseInt(endWorkArr[0]) + 12) % 24 : parseInt(endWorkArr[0]);
      const backupNavigatorIds = selectedBackupNavigatorList.map((obj: ITagsSearchDropdownType) => obj.key);
      const assignPhoneArr = assignedPhoneNumbers
        .filter((obj: any) => selectedAssignedPhoneList.some((arr: ITagsSearchDropdownType) => arr.key === obj.id))
        .map((phone) => {
          return {
            id: phone.id,
            systemId: phone.systemId,
            name: phone.name,
            whiteLabelPhoneNumber: phone.whiteLabelPhoneNumber,
          };
        });
      const data = {
        assignedClients: assignPhoneArr,
        navigatorType: getNavigatorTypeId(),
        firstName: inputFieldValues.firstName,
        lastName: inputFieldValues.lastName,
        email: inputFieldValues.email,
        timeZone: timeZoneOptionDetails.key.replace(" Time", ""),
        forwardingPhoneNumber: "+1" + inputFieldValues.phone,
        backupNavigatorIds: backupNavigatorIds,
        isActive: true,
        isOnVacation: false,
        vacationBeginDate: dateRangeState[0].startDate
          ? getUTCDateAndAdd12hrs(dateRangeState[0].startDate as Date)
          : null,
        vacationEndDate: dateRangeState[0].endDate ? getUTCDateAndAdd12hrs(dateRangeState[0].endDate as Date) : null,
        managerId: `${user?.id}`,
        careManagerId: profile?.careManagerId,
        profilePhotoUrl: "",
        userName: inputFieldValues.email,
        workStartHour: startWorkHour,
        workStartMinute: parseInt(startWorkArr[1]),
        workEndHour: endWorkHour,
        workEndMinute: parseInt(endWorkArr[1]),
      };
      dispatch(createNavigatorAsync(data)).then((res) => {
        setIsDirty(false);
        setTimeout(() => {
          history.push("/navigator-management");
        }, 2000);
      });
      dispatch(setIsProfileValueChanged(false));
      clearAllInputFields();
    }
  };

  const handleEditChanges = async () => {
    const isValid = checkAllFieldsAreValid();
    setIsDirty(true);
    if (isValid) {
      setIsSaveButtonDisabled(true);
      const startWorkArr = workHourState.from?.key?.split(":");
      const endWorkArr = workHourState.to?.key?.split(":");

      let startWorkHour = parseInt(startWorkArr[0]);
      if (workHourState.from?.amPm === "PM") {
        startWorkHour = parseInt(startWorkArr[0]) === 12 ? 12 : (parseInt(startWorkArr[0]) + 12) % 24;
      } else {
        if (parseInt(startWorkArr[0]) === 12) {
          startWorkHour = 0;
        }
      }

      let endWorkHour = parseInt(endWorkArr[0]);
      if (workHourState.to?.amPm === "AM") {
        if (parseInt(endWorkArr[0]) == 0 || parseInt(endWorkArr[0]) === 12) {
          endWorkHour = 24;
        }
      } else {
        endWorkHour = parseInt(endWorkArr[0]) === 12 ? 12 : (parseInt(endWorkArr[0]) + 12) % 24;
      }

      const backupNavigatorIds = selectedBackupNavigatorList.map((obj: ITagsSearchDropdownType) => obj.key);
      const assignPhoneArr = assignedPhoneNumbers
        .filter((obj: any) => selectedAssignedPhoneList.some((arr: ITagsSearchDropdownType) => arr.key === obj.id))
        .map((phone) => {
          const numberFromString = phone.whiteLabelPhoneNumber
            ? getNumberFromString(phone.whiteLabelPhoneNumber)
            : undefined;
          let phoneNumber = numberFromString;
          if (numberFromString && numberFromString.length === 11) {
            phoneNumber = `+${numberFromString}`;
          }
          if (numberFromString && numberFromString.length === 10) {
            phoneNumber = `+1${numberFromString}`;
          }
          return {
            id: phone.id,
            systemId: phone.systemId,
            name: phone.name,
            whiteLabelPhoneNumber: phoneNumber,
          };
        });
      const navigatorManagerCareId = sessionStorage.getItem("navigatorCareManagerId");
      const navigatorId = isNewNavigator
        ? user?.id
        : pageName === undefined
        ? undefined
        : navigatorManagerCareId
        ? navigatorManagerCareId
        : undefined;
      const { payload } = await dispatch(getNavigatorDetailsAsync({ navigatorId: navigatorId }));

      const newSelectedProxyTnav =
        navigatorTypeOptionDetails.key === NavigatorTypeKey.TRANSITION &&
        onOffToogleTitle === ToggleState.ON &&
        !isEmptyString(selectedProxyUser.key)
          ? selectedProxyUser.key
          : undefined;
      if (!newSelectedProxyTnav) {
        setSelectedProxyUser({ key: "", name: "" });
      }
      const forwardingPhoneNumber = getNumberFromString(inputFieldValues.phone);
      const updateData = {
        assignedClients: assignPhoneArr,
        id: payload.id,
        navigatorType: navigatorTypeOptionDetails.key === NavigatorTypeKey.TRANSITION ? 1 : 0, // send value using enum
        firstName: inputFieldValues.firstName,
        lastName: inputFieldValues.lastName,
        email: inputFieldValues.email,
        timeZone: timeZoneOptionDetails.key?.replace(" Time", ""),
        forwardingPhoneNumber: `+1${forwardingPhoneNumber}`,
        backupNavigatorIds: backupNavigatorIds,
        profilePhotoUrl: payload.profilePhotoUrl ? payload.profilePhotoUrl : "string",
        isActive: activeNavigator !== "Inactive",
        isOnVacation: onOffToogleTitle === "ON",
        statusOverrride: payload.statusOverrride,
        vacationBeginDate:
          onOffToogleTitle === "ON" ? getUTCDateAndAdd12hrs(dateRangeState[0].startDate as Date) : null,
        vacationEndDate: onOffToogleTitle === "ON" ? getUTCDateAndAdd12hrs(dateRangeState[0].endDate as Date) : null,
        managerId: payload?.managerId,
        careManagerId: payload?.careManagerId,
        userName: inputFieldValues?.email,
        workStartHour: startWorkHour,
        workStartMinute: parseInt(startWorkArr[1]),
        workEndHour: endWorkHour,
        workEndMinute: parseInt(endWorkArr[1]),
        ...(newSelectedProxyTnav && { proxyNavigatorId: newSelectedProxyTnav }),
      };
      dispatch(setIsProfileValueChanged(false));
      dispatch(updateNavigatorAsync(updateData)).then((res) => {
        setIsDirty(false);
      });
    }
  };

  const onDateRangeSelect = (item: any) => {
    setIsSaveButtonDisabled(false);
    setDateRangeState(item);
    dispatch(setIsProfileValueChanged(true));
  };

  const handleWorkHourFromChange = (option: any) => {
    setIsSaveButtonDisabled(false);
    option.amPm = workHourState.from.amPm === "AM" ? "AM" : "PM";
    const newValue = { ...workHourState, from: { ...option } };
    setWorkHourState(newValue);
    dispatch(setIsProfileValueChanged(true));
  };
  const handleWorkHourToChange = (option: any) => {
    setIsSaveButtonDisabled(false);
    option.amPm = workHourState.to.amPm === "AM" ? "AM" : "PM";
    const newValue = { ...workHourState, to: { ...option } };
    setWorkHourState(newValue);
    dispatch(setIsProfileValueChanged(true));
  };
  const [searchText, setSearchText] = useState<{ backupNavigators: string; assignPhoneNumbers: string }>({
    backupNavigators: "",
    assignPhoneNumbers: "",
  });

  const checkSearchText = (value1: string, value2: string): boolean => {
    return value1.toLowerCase().includes(value2.toLowerCase());
  };

  const filterBackupNavigatorDropdown = (obj: ITagsSearchDropdownType): boolean => {
    return selectedBackupNavigatorList.length > 0
      ? !selectedBackupNavigatorList.some((val: ITagsSearchDropdownType) => val.key === obj.key) &&
          checkSearchText(obj.name, searchText.backupNavigators)
      : checkSearchText(obj.name, searchText.backupNavigators);
  };
  const getBackupNavigatorDropdownMenu = (): ITagsSearchDropdownType[] => {
    return backUpNavigatorList.filter((obj: ITagsSearchDropdownType) => filterBackupNavigatorDropdown(obj));
  };

  const filterAssignPhoneDropdown = (obj: ITagsSearchDropdownType): boolean => {
    if (selectedAssignedPhoneList.length > 0) {
      const isPresent: boolean =
        !selectedAssignedPhoneList.some((item: ITagsSearchDropdownType) => item.key === obj.key) &&
        (checkSearchText(obj.name, searchText.assignPhoneNumbers) ||
          checkSearchText(obj.value, searchText.assignPhoneNumbers));
      return isPresent;
    } else {
      return (
        checkSearchText(obj.name, searchText.assignPhoneNumbers) ||
        checkSearchText(obj.value, searchText.assignPhoneNumbers)
      );
    }
  };

  const getAssignedPhoneDropdownMenu = (): ITagsSearchDropdownType[] => {
    return assignedPhoneNumberOptions.filter((obj: ITagsSearchDropdownType) => filterAssignPhoneDropdown(obj));
  };

  const handleClearDateButton = (val: boolean) => {
    const clearDateValue = {
      startDate: new Date(moment().add(1, "day").toDate()),
      endDate: new Date(moment().add(7, "day").toDate()),
      key: "selection",
    };
    onDateRangeSelect([clearDateValue]);
    setInitDateState([clearDateValue]);
  };

  const handleProxyNavigatorDropdown = (option: any) => {
    setSelectedProxyUser(option);
    setIsSaveButtonDisabled(false);

    dispatch(setIsProfileValueChanged(true));
  };

  useEffect(() => {
    return () => {
      dispatch(resetToInitialState());
    };
  }, [dispatch]);

  return (
    <div id="profile-container">
      {isNewNavigator && (
        <div className="breadcrumb-container">
          <Breadcrumb pathname={location.pathname?.replace("/profile", "")} />
        </div>
      )}
      <Header className="profile" hideMobileMenuTrigger={true}>
        <BrowserView className="desktop-profile-title">
          <div className="header-title">
            {pageName
              ? `${profile?.firstName ? profile.firstName : ""} ${profile?.lastName ? profile.lastName : ""}`
              : `Hi, ${user?.firstName} ${user?.lastName}`}
          </div>
          <button
            className={`green-button ${isNewNavigator ? "new-navigator-button" : "save-changes"}`}
            onClick={isNewNavigator ? onSaveChanges : handleEditChanges}
            disabled={getSaveCreateDisabledValue()}
          >
            {isNavigatorLoading ||
            isLoadingAssignedClients ||
            isLoadingBackupNavigatorList ||
            isLoadingNavigatorDetails ||
            isLoading ? (
              <ButtonLoader />
            ) : (
              <span className="login-button-screen">{isNewNavigator ? "Create Navigator" : "Save Changes"}</span>
            )}
          </button>
        </BrowserView>
        <MobileView className="mobile-profile-title">
          <Link
            to={{
              pathname: `/dashboard`,
            }}
          >
            <Icon icon="right-arrow" size={14} className="back-to-home-icon" />
          </Link>
          <span className="mobile-profile-text">Profile</span>
        </MobileView>
      </Header>
      {isLoading ||
      isLoadingAssignedClients ||
      isLoadingBackupNavigatorList ||
      isLoadingNavigatorDetails ||
      isNavigatorLoading ? (
        <div className={`content ${isNewNavigator ? "new-navigator-content" : "profile-content"}`}>
          <div className="loading-container">
            <CommonState type={CommonStateType.LOADING} />
          </div>
        </div>
      ) : (
        <div className={`content profile-content`}>
          <ProfilePicker onChangeProfilePic={() => {}} isOpenEditor={false} />
          <div className="input-section">
            <div className={`input-section-groups ${!isNewNavigator && !isNavigatorManager ? "disabled-class" : ""}`}>
              <TextField
                label="First Name"
                type="text"
                value={inputFieldValues.firstName}
                setValue={onChangeInputField}
                inputClassName="first-name-input"
                placeholder="First Name"
                errorMessage="Please enter a valid first name."
                showError={!validationValues.firstName}
                disabled={!isNewNavigator && !isNavigatorManager}
                onBlur={() => validateInputFields("firstName")}
                maxLength={30}
              />
              <TextField
                label="Last Name"
                type="text"
                value={inputFieldValues.lastName}
                setValue={onChangeInputField}
                inputClassName="last-name-input"
                placeholder="Last Name"
                errorMessage="Please enter a valid last name."
                onBlur={() => validateInputFields("lastName")}
                showError={!validationValues.lastName}
                disabled={!isNewNavigator && !isNavigatorManager}
                maxLength={30}
              />
              <div className="width30">
                <div className="dropdown-label">Navigator Type</div>
                <CustomDropDown
                  placeholder={navigatorTypeOptionDetails.name}
                  dropDownMenuItems={DROP_DOWN_ITEMS}
                  handleValueChanges={handleOptionChange}
                  value={navigatorTypeOptionDetails}
                  onBlur={() => validateNavigator(navigatorTypeOptionDetails.key)}
                  idFieldName="key"
                  dropDownBoxClass="navigator-dropdown"
                  selectionClass="navigator-selection"
                  dropDownContainerClass="navigator-dropdown-container"
                  isError={!validationValues.navigatorType}
                  message="Please select the navigator type"
                  isDisabled={!isNewNavigator && !isNavigatorManager}
                />
              </div>
            </div>
            <div className="input-section-groups">
              <TextField
                label="Email Address"
                type="email"
                value={inputFieldValues.email}
                setValue={onChangeInputField}
                inputClassName="email-input"
                onBlur={() => validateEmailInput(inputFieldValues.email)}
                placeholder="Email Address"
                showError={!validationValues.email}
                disabled={!isNewNavigator}
                errorMessage="Please enter a valid email."
                className={!isNewNavigator ? "disabled-class" : ""}
                inputSizeClassName="email-input-size"
              />
              <div className={`width65 ${!isNewNavigator && !isNavigatorManager ? "disabled-class" : ""}`}>
                <div className={`dropdown-label `}>Work Hours</div>
                <div className="work-hours-container">
                  <div className={`work-hours-container-group `}>
                    <label>From</label>
                    <CustomDropDown
                      placeholder={"from"}
                      dropDownMenuItems={WORK_HOUR_DROPDOWN_ITEMS}
                      handleValueChanges={handleWorkHourFromChange}
                      value={workHourState.from}
                      idFieldName="key"
                      dropDownBoxClass="workhour-dropdown"
                      selectionClass="workhour-selection"
                      dropDownContainerClass="workhour-dropdown-container"
                      isDisabled={!isNewNavigator && !isNavigatorManager}
                    />
                    <input
                      type="button"
                      value={workHourState.from?.amPm}
                      onClick={() => {
                        const workHour = {
                          ...workHourState.from,
                          amPm: workHourState.from.amPm === "AM" ? "PM" : "AM",
                        };
                        setWorkHourState({ ...workHourState, from: workHour });
                        setIsSaveButtonDisabled(false);
                      }}
                      disabled={!isNewNavigator && !isNavigatorManager}
                    />
                  </div>
                  <div className={`work-hours-container-group`}>
                    <label>To</label>
                    <CustomDropDown
                      placeholder={"to"}
                      dropDownMenuItems={WORK_HOUR_DROPDOWN_ITEMS}
                      handleValueChanges={handleWorkHourToChange}
                      value={workHourState.to}
                      idFieldName="name"
                      dropDownBoxClass="workhour-dropdown"
                      selectionClass="workhour-selection"
                      dropDownContainerClass="workhour-dropdown-container"
                      isDisabled={!isNewNavigator && !isNavigatorManager}
                    />
                    <input
                      type="button"
                      onClick={() => {
                        const workHour = {
                          ...workHourState.to,
                          amPm: workHourState.to.amPm === "AM" ? "PM" : "AM",
                        };
                        setWorkHourState({ ...workHourState, to: workHour });
                        setIsSaveButtonDisabled(false);
                      }}
                      value={workHourState.to.amPm}
                      disabled={!isNewNavigator && !isNavigatorManager}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input-section-groups">
              <div className="phone-container">
                <PhoneInputWithDash
                  label="Phone"
                  onBlur={() => validatePhoneInput(inputFieldValues.phone)}
                  value={inputFieldValues.phone}
                  setValue={onChangeInputField}
                  inputClassName="phone-input"
                  className="phone-container-content"
                  showError={!validationValues.phone || (!isDirty && isPhoneInvalid)}
                  errorMessage={
                    !isDirty && isPhoneInvalid
                      ? `Invalid number in field: ${profile?.forwardingPhoneNumber ?? ""}`
                      : "Please enter a valid phone number"
                  }
                  isNumberIncorrect={!isPhoneValueChanged && isPhoneInvalid}
                />
              </div>
              <div className="width65">
                <div className="dropdown-label">Time Zone</div>
                <CustomDropDown
                  placeholder={timeZoneOptionDetails.name}
                  dropDownMenuItems={TIME_ZONE_DROPDOWN_ITEMS}
                  handleValueChanges={handleTimeZoneChange}
                  value={timeZoneOptionDetails}
                  idFieldName="key"
                  dropDownBoxClass="time-zone-dropdown"
                  selectionClass="time-zone-selection"
                  dropDownContainerClass="time-zone-dropdown-container"
                  onBlur={() => validateTimeZone(timeZoneOptionDetails.key)}
                  isError={!validationValues.timeZone}
                  message="Please select the Time Zone"
                />
              </div>
            </div>
            <div>
              <div className="dropdown-label">
                Backup Navigator{" "}
                <span className="optional-text">{`(${selectedBackupNavigatorList.length} of 10 selected)`}</span>
              </div>
              <TagsSearchDropdown
                idFieldName=""
                value={selectedBackupNavigatorList}
                dropDownContainerClass="backup-navigator-dropdown-container-profile"
                dropDownBoxClass=""
                selectionClass="backup-navigator-dropdown"
                onSearchTextChange={(val: string) => {
                  setSearchText({ ...searchText, backupNavigators: val });
                }}
                handleValueChanges={(val: any) => {
                  if (selectedBackupNavigatorList.length <= BACKUP_NAVIGATOR_LIMIT) {
                    setSelectedBackupNavigatorList(val);
                    getBackupNavigatorDropdownMenu();
                    setIsSaveButtonDisabled(false);
                    dispatch(setIsProfileValueChanged(true));
                  }
                }}
                useCaseType={TagsSearchDropdownUseCase.BACKUP_NAVIGATOR}
                dropDownMenuItems={getBackupNavigatorDropdownMenu()}
                placeholder="Assign a Navigator"
                maximumRange={BACKUP_NAVIGATOR_LIMIT}
                isDisabled={(!isNewNavigator && !isNavigatorManager) || !backUpNavigatorList.length}
                renderFrom="profile"
              />
            </div>

            <div className="input-section-groups vacation-proxy-navigator-input-container">
              <div className="vacation-date-container">
                <div className="on-off-toggle">
                  <div className="dropdown-label">Vacation Mode</div>
                  <Toggle
                    toggleLeftTitle="OFF"
                    toggleRightTitle="ON"
                    showNoTitle={true}
                    activeToggleTitle={onOffToogleTitle}
                    changeActiveToggle={onToggleOnOff}
                  />
                </div>
                <div>
                  <div className="dropdown-label">Vacation Date Range</div>
                  <DatePicker
                    dateFormat="mm/dd/yyyy"
                    minDate={new Date()}
                    onTap={(val) => {
                      setDatePickerOpen(val);
                    }}
                    isOpen={isDatePickerOpen}
                    isDisabled={onOffToogleTitle === "OFF"}
                    onDateRangeSelect={onDateRangeSelect}
                    handleApplyClick={handleApplyClick}
                    initialSelectedDates={initDateState}
                    staticRangeEmpty
                    handleClearButtonClick={handleClearDateButton}
                  />
                </div>
              </div>
              {navigatorTypeOptionDetails?.key === NavigatorTypeKey.TRANSITION && (
                <div className="proxy-user-dropdown-container">
                  <div className="dropdown-label">Proxy Navigator</div>
                  <SingleSelectDropdownWithClearOption
                    placeholder="Select Navigator"
                    searchPlaceholder="Search by Navigator "
                    onItemSelected={handleProxyNavigatorDropdown}
                    isDisabled={onOffToogleTitle === ToggleState.OFF}
                    dropDownMenuItems={backUpNavigatorList}
                    value={selectedProxyUser}
                    clearSelection={() => {
                      setIsSaveButtonDisabled(false);
                      dispatch(setIsProfileValueChanged(true));
                      setSelectedProxyUser({ key: "", name: "" });
                    }}
                    dropDownBoxClass="navigator-dropdown-container"
                  />
                </div>
              )}
            </div>
            <div className={`${!isNewNavigator && !isNavigatorManager ? "disabled-class" : ""}`}>
              <div className="dropdown-label">
                Assigned Phone Numbers
                <span className="optional-text">{`(${selectedAssignedPhoneList.length} of 20 selected)`}</span>
              </div>

              <TagsSearchDropdown
                idFieldName=""
                value={selectedAssignedPhoneList}
                dropDownContainerClass="assigned-phone-number-dropdown-container"
                dropDownBoxClass="assigned-phone-number-dropdown"
                selectionClass="assigned-phone-number-selection "
                onSearchTextChange={(val: string) => {
                  setSearchText({ ...searchText, assignPhoneNumbers: val });
                }}
                handleValueChanges={(val: any) => {
                  if (selectedAssignedPhoneList.length <= 20) {
                    setSelectedAssignPhoneList(val);
                    getAssignedPhoneDropdownMenu();
                    setIsSaveButtonDisabled(false);
                    dispatch(setIsProfileValueChanged(true));
                  }
                }}
                dropDownMenuItems={getAssignedPhoneDropdownMenu()}
                placeholder="search by name or number"
                useCaseType={TagsSearchDropdownUseCase.ASSIGNED_PHONE_NUMBER}
                isDisabled={!isNewNavigator && !isNavigatorManager}
                maximumRange={20}
                renderFrom="profile"
              />
            </div>
          </div>
          <MobileView className="full-width-savebtn">
            <Button
              className={`green-button ${isNewNavigator ? "new-navigator-button" : "save-changes"}`}
              text={isNewNavigator ? "Create Navigator" : "Save Changes"}
              onClick={isNewNavigator ? onSaveChanges : handleEditChanges}
              disabled={getSaveCreateDisabledValue()}
            />
          </MobileView>
        </div>
      )}
    </div>
  );
};

export default Profile;
