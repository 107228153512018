import "./patient-table.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getContactState } from "state/feature/contact/contact.slice";
import { useState } from "react";
import Icon from "components/Icon/icon.component";
import { isMobile } from "react-device-detect";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortingOrderType } from "shared/enums/sorting-types.enums";
import { INavigatorContacts, PatientContactType } from "state/types/contact-slice.type";
import TableLoader from "components/TableLoader/table-loader.component";
import CommonState from "components/common-state/common-state.component";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import Sort from "components/Sort/sort.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import Row from "./row/row.component";
import { ColumnType } from "shared/types/table.type";

const PatientTable = ({
  tableClassName,
  columns,
  handleMessageSidePopup,
  columnsData,
  handleColumnSort,
  incrementPage,
}: {
  tableClassName: string;
  columns: ColumnType[];
  handleMessageSidePopup: () => void;
  searchText?: string;
  columnsData: INavigatorContacts;
  handleColumnSort: (column: ColumnType) => void;
  sortingType: SortingOrderType;
  incrementPage: () => void;
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(getContactState);
  const { isLoading: isNavigatorsLoading } = useSelector(getNavigatorState);
  const [currentPhoneNumberModal, setCurrentPhoneNumberModal] = useState("");
  const [isIncrementPage, setIsIncrementPage] = useState(false);

  const displaySortIcons = (column: ColumnType) => {
    return column.sortOrder === SortingOrderType.DEFAULT ? (
      <Sort />
    ) : (
      <Icon icon={column.sortOrder === SortingOrderType.ASC ? "sort-top" : "sort-bottom"} size={8} />
    );
  };
  const columnName = (column: ColumnType) => {
    const shouldDisplaySortIcons = !isLoading;
    if (column.name !== "") {
      return (
        <div
          className={`contact-table-header-content ${isLoading && "contact-table-header-content-disable-cursor"}`}
          onClick={() => {
            if (shouldDisplaySortIcons) {
              handleColumnSort?.(column);
            }
          }}
        >
          <div className="contact-table-header-content-name">{column.name}</div>
          {shouldDisplaySortIcons && displaySortIcons(column)}
        </div>
      );
    }
    return column.name;
  };

  const openPhoneNumberModal = (id: string) => {
    setCurrentPhoneNumberModal(id);
  };

  return (
    <>
      <table className={tableClassName}>
        <thead>
          <tr className={`contact-table-header ${isMobile ? "hidden" : ""}`}>
            <td>
              <table>
                <tbody>
                  <tr>
                    {columns.map((column) => {
                      return <th key={column.name}>{columnName(column)}</th>;
                    })}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="contact-no-border-in-mobile">
            <td>
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                next={() => {
                  setIsIncrementPage(true);
                  incrementPage();
                }}
                hasMore={true}
                loader={false}
                dataLength={columnsData.patientContact.length}
              >
                <table>
                  <tbody
                    id="scrollableDiv"
                    className={`contact-table-body ${isMobile ? "contact-table-body-mobile" : ""}`}
                  >
                    {(isLoading || isNavigatorsLoading) && columnsData.patientContact.length === 0 && (
                      <tr className="contact-no-border-row">
                        <td>
                          <div className="loading-container">
                            <CommonState type={CommonStateType.LOADING} />
                          </div>
                        </td>
                      </tr>
                    )}
                    {!isLoading && !isNavigatorsLoading && columnsData.patientContact.length === 0 && (
                      <tr className="no-message-container">
                        <td className="no-message-td" colSpan={isMobile ? 2 : 4}>
                          <div className="no-messages">No Contacts Found</div>
                        </td>
                      </tr>
                    )}
                    {columnsData &&
                      columnsData?.patientContact &&
                      columnsData?.patientContact?.length > 0 &&
                      columnsData?.patientContact?.map((item: PatientContactType, index: number) => {
                        const patientContactDetails = item.patientContactDetails.map((contact) => {
                          return { ...contact, name: `${item.patientFirstName} ${item.patientLastName}` };
                        });
                        return (
                          <Row
                            patientContactDetails={patientContactDetails}
                            key={`${index} ${item.patientID}`}
                            id={`${item.patientID}`}
                            patient={`${item.patientFirstName} ${item.patientLastName}`}
                            physician={`${item.physicianFirstName} ${item.physicianLastName}`}
                            location={item.currentLocation ? item.currentLocation : ""}
                            patientPhoneNumber={item.patientPhoneNumber ? item.patientPhoneNumber : ""}
                            patientUserID={item.patientUserID}
                            clientID={item.clientID}
                            handleMessageSidePopup={handleMessageSidePopup}
                            openPhoneNumberModal={openPhoneNumberModal}
                            currentPhoneNumberModal={currentPhoneNumberModal}
                            totalLength={columnsData?.patientContact?.length}
                            currentLength={index + 1}
                            procedure={item.procedure}
                            patientDetails={item}
                          />
                        );
                      })}
                    {columnsData.patientContact.length > 0 && isLoading && isIncrementPage ? (
                      <tr className="contact-no-border-row">
                        <td>
                          <TableLoader />
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PatientTable;
